import React, { useRef, useState } from "react";
const TextEditor = (props) => {
  const textBoxRef = useRef(null);
  const [content, setContent] = useState(props?.defaultValue);

  const handleInput = () => {
    props?.handleChangePageContent(
      props?.currentPageId,
      "textsList",
      null,
      props?.currentIndex,
      "content",
      textBoxRef.current.innerHTML
    );
    setContent(textBoxRef.current.innerHTML);
  };

  return (
    <div
      ref={textBoxRef}
      onBlur={handleInput}
      // onInput={handleInput}
      contentEditable="true"
      onKeyUp={props?.saveSelection}
      onMouseUp={props?.saveSelection}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};
export default TextEditor;
// // ContentEditor.js
// import React, { useState, useRef } from "react";

// const TextEditor = ({ defaultValue }) => {
//   const editorRef = useRef(null);
//   const [content, setContent] = useState(defaultValue);

//   const applyCommand = (command) => {
//     document.execCommand(command, false, null);
//     editorRef.current.focus(); // Keep focus on editor after applying command
//   };

//   const handleContentChange = () => {
//     setContent(editorRef.current.innerHTML);
//     moveCursorToEnd();
//   };

//   // Function to move the cursor to the end
//   const moveCursorToEnd = () => {
//     const editor = editorRef.current;
//     if (!editor) return;

//     const range = document.createRange();
//     const selection = window.getSelection();
//     range.selectNodeContents(editor);
//     range.collapse(false); // Collapse range to the end
//     selection.removeAllRanges();
//     selection.addRange(range);
//   };

//   return (
//     <div className="content-editor">
//       <div className="toolbar">
//         <button onClick={() => applyCommand("bold")}>
//           <b>B</b>
//         </button>
//         <button onClick={() => applyCommand("italic")}>
//           <i>I</i>
//         </button>
//         <button onClick={() => applyCommand("underline")}>
//           <u>U</u>
//         </button>
//         <button onClick={() => applyCommand("strikeThrough")}>
//           <s>S</s>
//         </button>
//         <button onClick={() => applyCommand("insertUnorderedList")}>
//           • List
//         </button>
//         <button onClick={() => applyCommand("insertOrderedList")}>
//           1. List
//         </button>
//       </div>

//       <div
//         ref={editorRef}
//         className="editor"
//         contentEditable
//         dangerouslySetInnerHTML={{ __html: content }}
//         onInput={handleContentChange}
//       />
//     </div>
//   );
// };

// export default TextEditor;
