import Draggable from "react-draggable";
import RotatableDiv from "./RotatableDiv";
import { ResizableBox } from "react-resizable";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsUpDownLeftRight } from "@fortawesome/free-solid-svg-icons";
import { calculateCatalogSize } from "../../config/_global";

const ResizableAndDraggableBox = ({
  x,
  y,
  id,
  box,
  width,
  onDrag,
  height,
  content,
  onResize,
  handleBackCoverData,
}) => {
  const [axis, setAxis] = useState("both");
  // Handle drag event
  const handleDrag = (e) => {
    if (e.shiftKey) {
      const deltaX = Math.abs(e.movementX);
      const deltaY = Math.abs(e.movementY);
      if (deltaX > deltaY) {
        setAxis("x");
      } else {
        setAxis("y");
      }
    } else {
      setAxis("both");
    }
  };
  return (
    <Draggable
      axis={axis}
      bounds="parent"
      position={{ x, y }}
      onDrag={handleDrag}
      handle=".handle-button"
      onStop={(e, data) => onDrag(id, data.x, data.y)}
    >
      <div style={{ position: "absolute" }}>
        <div
          style={{
            opacity: box.opacity,
            transform: `rotate(${box.rotate}deg)`,
          }}
        >
          <div className="handle-button">
            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
          </div>
          <ResizableBox
            resizeHandles={["s", "w", "e", "n", "nw", "ne", "se", "sw"]}
            width={width}
            height={height}
            onResize={(e, { size }) => onResize(id, size?.width, size?.height)}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <RotatableDiv
                handleRotate={handleBackCoverData}
                rotate={box.rotate}
                id={id}
              />
              {content}
            </div>
          </ResizableBox>
        </div>
      </div>
    </Draggable>
  );
};

const BackCover = ({
  mode,
  source,
  backCoverData,
  productsCatalog,
  setBackCoverData,
  handleBackCoverData,
}) => {
  const [boxes, setBoxes] = useState([]);
  const [histories, setHistories] = useState([]);
  // const [height, setHeight] = useState(productsCatalog?.catalogSize?.height);
  const [catalogSize, setCatalogSize] = useState(null);

  useEffect(() => {
    if (backCoverData) {
      const initialBackCoverData = [
        {
          id: "backCoverLogo",
          size: backCoverData?.backCoverLogo?.size,
          color: backCoverData?.backCoverLogo?.color,
          rotate: backCoverData?.backCoverLogo?.rotate,
          position: backCoverData?.backCoverLogo?.position,
          content: (
            <div className="logo">
              {productsCatalog?.categoryId ? (
                <img
                  src={
                    "https://sinaha.ae/image/" + productsCatalog?.categoryIcon
                  }
                  className="w-100"
                  title="customerLogo"
                  alt="customerLogo"
                />
              ) : (
                <img
                  src={
                    "https://sinaha.ae/image/" + productsCatalog?.customerLogo
                  }
                  className="w-100"
                  title="customerLogo"
                  alt="customerLogo"
                />
              )}
            </div>
          ),
        },
      ];
      setBoxes(initialBackCoverData);
    }
  }, [backCoverData]);

  useEffect(() => {
    const props = {
      mode,
      source,
      productsCatalog,
    };
    setCatalogSize(calculateCatalogSize(props));
  }, [productsCatalog]);

  
  // useEffect(() => {
  //   if (productsCatalog?.orientation === "Portrait") {
  //     setHeight(
  //       parseInt(
  //         mode === "smallView"
  //           ? productsCatalog?.catalogSize?.height / 5.12
  //           : productsCatalog?.catalogSize?.height
  //       )
  //     );
  //   } else {
  //     setHeight(
  //       parseInt(
  //         mode === "smallView"
  //           ? productsCatalog?.catalogSize?.height / 8.3
  //           : productsCatalog?.catalogSize?.height
  //       )
  //     );
  //   }
  // }, [productsCatalog]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === "z") {
        handleUndo();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [histories]);

  const handleUndo = () => {
    if (histories && histories.length > 0) {
      const lastHistory = histories[histories.length - 1];
      setBackCoverData(lastHistory);
      setHistories(histories.slice(0, -1));
    }
  };

  const saveToHistory = (newHistory) => {
    setHistories((prevHistories) => [...prevHistories, newHistory]);
  };

  const handleResize = (id, width, height) => {
    saveToHistory(backCoverData);
    setBackCoverData((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        size: { width: parseInt(width), height: parseInt(height) },
      },
    }));
  };

  const handleDrag = (id, x, y) => {
    saveToHistory(backCoverData);
    setBackCoverData((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        position: { x: parseInt(x), y: parseInt(y) },
      },
    }));
  };

  const localHandleBackCoverData = (id, e, key) => {
    saveToHistory(backCoverData);
    handleBackCoverData(id, e, key);
  };
  return (
    <div
      className="page-style back-cover"
      style={{
        margin: "0 auto",
        width: catalogSize ? catalogSize?.width : "100%",
        height: catalogSize ? catalogSize?.height : "100%",
        // height: `${height}px`,
        backgroundColor: productsCatalog?.mainColor,
        backgroundImage: `url('${productsCatalog?.backCoverImage}')`,
        // width: mode === "smallView" ? `100%` : `${productsCatalog?.catalogSize?.width}px`,
      }}
    >
      {mode === "editable" && (
        <>
          <div className="editing-line-horizontal"></div>
          <div className="editing-line-vertical"></div>
        </>
      )}
      {productsCatalog?.backCoverDesignTemplate === "customize" ? (
        boxes &&
        boxes.map((box) =>
          mode === "editable" ? (
            <ResizableAndDraggableBox
              box={box}
              id={box.id}
              key={box.id}
              onDrag={handleDrag}
              content={box.content}
              onResize={handleResize}
              x={backCoverData[box.id]?.position?.x}
              y={backCoverData[box.id]?.position?.y}
              width={backCoverData[box.id]?.size?.width}
              height={backCoverData[box.id]?.size?.height}
              handleBackCoverData={localHandleBackCoverData}
            />
          ) : (
            <div
              key={box.id}
              style={{
                position: "absolute",
                transform: `rotate(${backCoverData[box.id]?.rotate}deg)`,
                height: `${
                  (backCoverData[box.id]?.size?.height / productsCatalog?.catalogSize?.height) * 100
                }%`,
                width: `${
                  (backCoverData[box.id]?.size?.width / productsCatalog?.catalogSize?.width) * 100
                }%`,
                top: `${
                  (backCoverData[box.id]?.position?.y / productsCatalog?.catalogSize?.height) * 100
                }%`,
                left: `${
                  (backCoverData[box.id]?.position?.x / productsCatalog?.catalogSize?.width) * 100
                }%`,
              }}
            >
              {box.content}
            </div>
          )
        )
      ) : (
        <div className="catelog-back-cover">
          <div className="back-cover-logo">
            {productsCatalog?.categoryId ? (
              <img
                src={"https://sinaha.ae/image/" + productsCatalog?.categoryIcon}
                title="customerLogo"
                alt="customerLogo"
              />
            ) : (
              <img
                src={"https://sinaha.ae/image/" + productsCatalog?.customerLogo}
                title="customerLogo"
                alt="customerLogo"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BackCover;
