import axios from "axios";
import {API_BASE_URL_WITH_PORT} from "./url";

const api = axios.create({
  baseURL: `${API_BASE_URL_WITH_PORT}/api`,
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
