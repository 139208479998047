import React from "react";
import "./templates.css";
import { useTranslation } from "react-i18next";
const Template1 = ({ productsCatalog }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="catelog-cover">
      <div
        className="catalog-cover-border"
        style={{ border: `1px solid ${productsCatalog?.mainColor}` }}
      ></div>
      <div className="coverImage">
        <div
          className="overlay"
          style={{
            background:
              "linear-gradient(0deg, " +
              productsCatalog?.mainColor +
              ", " +
              productsCatalog?.mainColor +
              "80)",
          }}
        ></div>
        {productsCatalog?.orientation === "Portrait" && (
          <img
            src={productsCatalog?.coverImage}
            alt="Cover Image"
            title="Cover Image"
          />
        )}
      </div>
      <img
        src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://sinaha.ae/en/seller-profile/${
          productsCatalog?.screenname
        }&color=${productsCatalog?.mainColor.substring(1)}`}
        className="cover-qr-code"
        alt="QR Code"
        title="QR Code"
      />
      <div
        className="rectangle-left"
        style={{ backgroundColor: productsCatalog?.mainColor }}
      ></div>
      <div className="rectangle-center">
        <div
          className="rectangle-center-inside"
          style={{ backgroundColor: productsCatalog?.mainColor }}
        >
          <div className="rectangle-center-company-logo">
            {productsCatalog?.categoryId ? (
              <img
                src={"https://sinaha.ae/image/" + productsCatalog?.categoryIcon}
                className="customerLogo"
                title="customerLogo"
                alt="customerLogo"
              />
            ) : (
              <img
                src={"https://sinaha.ae/image/" + productsCatalog?.customerLogo}
                className="customerLogo"
                title="customerLogo"
                alt="customerLogo"
              />
            )}
          </div>
        </div>
      </div>
      <div className="company-name">
        {productsCatalog?.categoryId ? (
          i18n.language === "ar" && productsCatalog?.arCategoryName ? (
            <h4
              dangerouslySetInnerHTML={{
                __html: productsCatalog?.arCategoryName,
              }}
            />
          ) : (
            <h4
              dangerouslySetInnerHTML={{
                __html: productsCatalog?.enCategoryName,
              }}
            />
          )
        ) : i18n.language === "ar" && productsCatalog?.arCustomerName ? (
          <h4
            dangerouslySetInnerHTML={{
              __html: productsCatalog?.arCustomerName,
            }}
          />
        ) : (
          <h4
            dangerouslySetInnerHTML={{
              __html: productsCatalog?.enCustomerName,
            }}
          />
        )}
      </div>
      <div className="title">
        <h2>{t("products")}</h2>
        <h4>{t("smartCatalog")}</h4>
      </div>

      {productsCatalog?.orientation === "Portrait" ? (
        <div
          className="rectangle-right"
          style={{
            backgroundColor: productsCatalog?.mainColor,
          }}
        ></div>
      ) : (
        <>
          <div
            className="rectangle-right rectangle-right-bg "
            style={{
              backgroundColor: productsCatalog?.mainColor,
              opacity: 0.7,
              zIndex: 10,
            }}
          ></div>

          <img
            src={
              productsCatalog?.categoryId
                ? productsCatalog?.coverImage
                : productsCatalog?.coverImage
            }
            alt="Cover Image"
            title="Cover Image"
            className="rectangle-right"
          />
        </>
      )}

      <div className="copyright-text">@2024 {t("copyright")}</div>
      <div className="year">
        <p>2024</p>
      </div>
    </div>
  );
};

export default Template1;
