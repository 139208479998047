import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ProfileConfig(props) {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-center align-items-center h-100 w-100 selected">
      <div className="item">
        <Form.Label>{t("shapes list")}</Form.Label>
        <div className="d-flex">
          <div
            className="line"
            onClick={() => props.addNewShape("line", "profile")}
          ></div>
          <div
            className="circle"
            onClick={() =>
              props.addNewShape("circle", "profile")
            }
          ></div>
          <div
            className="square"
            onClick={() =>
              props.addNewShape("square", "profile")
            }
          ></div>
          <div
            className="triangle"
            onClick={() =>
              props.addNewShape("triangle", "profile")
            }
          ></div>
        </div>
      </div>
      <div className="item">
        <Form.Label>{t("select background color")}</Form.Label>
        <Form.Control
          className="w-100"
          type="color"
          id="profileBackgroundColor"
          value={props.profileBackgroundColor}
          onChange={(e) => props.setProfileBackgroundColor(e.target.value)}
        />
      </div>
      <div className="item">
        <Form.Label>{t("select background")}</Form.Label>
        <div className="d-flex justify-content-between align-items-center">
          <Form.Control
            type="file"
            id="profileBackgroundImage"
            accept="image/*"
            onChange={props.handleProfileImageChange}
            style={{ width: "103px", marginRight: "5px", height: "38px" }}
          />
          {props.profileImagePerview && (
            <div className="position-relative">
              <button
                onClick={props.deleteProfileImage}
                className="delete-cover"
              >
                <FontAwesomeIcon icon={faX} />
              </button>
              <img
                src={props.profileImagePerview}
                alt="Preview"
                style={{
                  width: "100%",
                  height: "70px",
                  objectFit: "contain",
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="item">
        <Form.Label>{t("background size")}</Form.Label>
        <select
          style={{ width: "75px" }}
          className="form-control"
          value={props.profileImageSize}
          onChange={(e) =>
            props.setProfileImageSize(e.target.value)
          }
        >
          <option value=""></option>
          <option value="auto">{t("auto")}</option>
          <option value="100% 100%">{t("fit")}</option>
          <option value="contain">{t("contain")}</option>
          <option value="cover">{t("cover")}</option>
        </select>
      </div>
      <div className="item">
        <Form.Label>{t("background position")}</Form.Label>
        <select
          style={{ width: "75px" }}
          className="form-control"
          value={props.profileImagePosition}
          onChange={(e) =>
            props.setProfileImagePosition(e.target.value)
          }
        >
          <option value=""></option>
          <option value="center">{t("center")}</option>
          <option value="top">{t("top")}</option>
          <option value="bottom">{t("bottom")}</option>
          <option value="left">{t("left")}</option>
          <option value="right">{t("right")}</option>
        </select>
      </div>
          <div className="item">
            <Form.Label>{t("background opacity")}</Form.Label>
            <input
              type="number"
              min={0}
              max={100}
              className="form-control"
              value={props.profileImageOpacity}
              onChange={(e) => props.setProfileImageOpacity(e.target.value)}
            />
          </div>
    </div>
  );
}

export default ProfileConfig;
