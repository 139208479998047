import { checkViews, fetchCatalogById } from "../api/catalogs";
import { API_BASE_URL_WITH_PORT } from "../config/url";
import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Catalog from "./catalog";
import axios from "axios";

function ViewCatalog() {
  const [productCatalog, setProductCatalog] = useState();
  const [profileData, setProfileData] = useState();
  const [isLoading, setLoading] = useState(true);
  const [coverData, setCoverData] = useState();
  const { t } = useTranslation();
  const { slug } = useParams();

  const fetchIpAddress = async (catalogId) => {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      const ipAddress = response.data.ip;
      await checkViews({ipAddress, catalogId});
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      var catalogDataFromApi = await fetchCatalogById(slug);
      if (catalogDataFromApi) {
        setCoverData(JSON.parse(catalogDataFromApi?.coverData));
        setProfileData(JSON.parse(catalogDataFromApi?.profileData));
        const coverImage =
          catalogDataFromApi?.coverImage &&
          (catalogDataFromApi?.coverImage?.includes("data:image")
            ? catalogDataFromApi?.coverImage
            : `${API_BASE_URL_WITH_PORT}/${catalogDataFromApi?.coverImage?.replace(
                /\\/g,
                "/"
              )}`);

        const backCoverImage =
          catalogDataFromApi?.backCoverImage &&
          (catalogDataFromApi?.backCoverImage?.includes("data:image")
            ? catalogDataFromApi?.backCoverImage
            : `${API_BASE_URL_WITH_PORT}/${catalogDataFromApi?.backCoverImage?.replace(
                /\\/g,
                "/"
              )}`);

        const profileImage =
          catalogDataFromApi?.profileImage &&
          (catalogDataFromApi?.profileImage?.includes("data:image")
            ? catalogDataFromApi?.profileImage
            : `${API_BASE_URL_WITH_PORT}/${catalogDataFromApi?.profileImage.replace(
                /\\/g,
                "/"
              )}`);
        setProductCatalog({
          ...catalogDataFromApi,
          coverImage: coverImage,
          profileImage: profileImage,
          backCoverImage: backCoverImage,
          coverData: JSON.parse(catalogDataFromApi?.coverData),
          profileData: JSON.parse(catalogDataFromApi?.profileData),
          catalogSize: JSON.parse(catalogDataFromApi?.catalogSize),
          backCoverData: JSON.parse(catalogDataFromApi?.backCoverData),
          coverShapesList: JSON.parse(catalogDataFromApi?.coverShapesList),
          profileShapesList: JSON.parse(catalogDataFromApi?.profileShapesList),
        });
        fetchIpAddress(catalogDataFromApi?.id);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  return (
    <div className="catalogPage">
      <div className="catalogPageOverlay"></div>
      {isLoading ? (
        <h6>{t("loading")} ...</h6>
      ) : (
        <Catalog
          index={0}
          source="catalogPage"
          coverData={coverData}
          book={productCatalog.id}
          profileData={profileData}
          slug={productCatalog.slug}
          catalogId={productCatalog.id}
          productsCatalog={productCatalog}
          orientation={productCatalog.orientation}
          backCoverData={productCatalog.backCoverData}
        />
      )}
    </div>
  );
}

export default ViewCatalog;
