const ConfirmDialog = ({ show, onConfirm, onCancel, message }) => {
    if (!show) return null;
  
    return (
      <div className="confirm-dialog-backdrop">
        <div className="confirm-dialog">
          <p>{message}</p>
          <button onClick={onConfirm}>Confirm</button>
          <button onClick={onCancel}>Cancel</button>
        </div>
      </div>
    );
  };
  
  export default ConfirmDialog;