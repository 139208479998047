import React, { useEffect, useState } from "react";
import { decodeHtml } from "./decodeHtml";
import { useTranslation } from "react-i18next";
import { extractTextFromHtml } from "./extractText";

const PageProductsListConfig = ({
  page,
  mode,
  products,
  isLoading,
  productsListGridSystem,
  handleChangePageContent,
}) => {
  const { t, i18n } = useTranslation();
  const [draggingIndex, setDraggingIndex] = useState(null);
  const [colClass, setColClass] = useState({ cols: "col-4", rows: 3 });

  const handleDragStart = (index) => {
    setDraggingIndex(index);
  };

  const handleDragOver = (index) => {
    if (draggingIndex === index) return;
    const productsCopy = products.filter((_, idx) => idx !== draggingIndex);
    productsCopy.splice(index, 0, products[draggingIndex]);
    setDraggingIndex(index);
    handleChangePageContent(page.id, "productsList", productsCopy);
  };

  const handleDragEnd = () => {
    setDraggingIndex(null);
  };

  useEffect(() => {
    setColClass(productsListGridSystem);
  }, [productsListGridSystem]);

  const decodeHtmlEntities = (text) => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    return textArea.value;
  };

  return (
    <div className="products-list">
      <div className="row products-row">
        {isLoading ? (
          <h6>{t("loading")} ...</h6>
        ) : (
          products.map((product, index) => (
            <div
              key={product.product_id}
              className={colClass.cols}
              draggable
              onDragStart={() => handleDragStart(index)}
              onDragOver={() => handleDragOver(index)}
              onDragEnd={handleDragEnd}
              style={{
                height: `calc((100% / ${colClass.rows}) - ${
                  mode === "smallView" ? 3 : 8
                }px)`,
                position: "relative",
                opacity: draggingIndex === index ? 0.5 : 1,
                cursor: "move",
                padding: mode === "smallView" ? "1px" : "4px",
              }}
            >
              <div className="item-card" id={"product_" + product.product_id}>
                <div className={`item-image`}>
                  <img
                    // src={"https://sinaha.ae/image/" + product.image}
                    alt=""
                    style={{
                      backgroundImage: `url("https://sinaha.ae/image/${product.image}")`
                    }}
                  />
                </div>
                <div className="item-details">
                  {i18n.language === "ar" ? (
                    <p className="item-title">
                      {decodeHtmlEntities(product.ar_name)}
                    </p>
                  ) : (
                    <p className="item-title">
                      {decodeHtmlEntities(product.en_name)}
                    </p>
                  )}

                  <div className="item-description">
                    {i18n.language === "ar"
                      ? extractTextFromHtml(decodeHtml(product.ar_description))
                      : extractTextFromHtml(decodeHtml(product.en_description))}
                  </div>
                  <img
                    className="qr-code"
                    src={
                      "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https%3A%2F%2Fsinaha.ae%2Findex.php%3Froute%3Dproduct%2Fproduct%26product_id%3D" +
                      product.product_id
                    }
                    alt={product.en_name}
                    title={product.en_name}
                  />
                  <div className="sin-product-verfiy-logos">
                    {product.membership_level_list?.map((level, key) => {
                      return (
                        <div key={key} className="sin-product-verfiy-logo">
                          <img
                            src={
                              "https://sinaha.ae/image/" + level
                            }
                            alt="0"
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default PageProductsListConfig;
