import React, { useState, useRef, useEffect } from "react";

const RotatableDiv = ({
  handleRotate,
  productId,
  pageKey,
  pageID,
  rotate,
  index,
  id,
}) => {
  const divRef = useRef(null);
  const startrotate = useRef(0);
  const isRotating = useRef(false);
  const [localRotate, setlocalRotate] = useState(rotate);

  useEffect(() => {
    setlocalRotate(rotate);
  }, []);

  const handleMouseDown = (e) => {
    e.preventDefault();
    isRotating.current = true;
    const rect = divRef.current.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    const mouseX = e.clientX;
    const mouseY = e.clientY;
    startrotate.current =
      Math.atan2(mouseY - centerY, mouseX - centerX) * (180 / Math.PI) -
      localRotate;
  };

  const handleMouseMove = (e) => {
    if (!isRotating.current) return;

    const rect = divRef.current.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    const mouseX = e.clientX;
    const mouseY = e.clientY;

    let currentrotate =
      Math.atan2(mouseY - centerY, mouseX - centerX) * (180 / Math.PI) -
      startrotate.current;
    if (e.shiftKey) {
      currentrotate = snapTorotate(
        currentrotate,
        [0, 30, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360]
      );
    }
    setlocalRotate(parseInt(currentrotate));
  };

  const handleMouseUp = () => {
    isRotating.current = false;
    if (id.includes("image-") || id.includes("shape-") || id.includes("text-")) {
      handleRotate(
        pageID,
        pageKey,
        null,
        index,
        "rotate",
        parseInt(localRotate)
      );
    } else if (id.includes("cover") || id.includes("backCover")) {
      handleRotate(id, parseInt(localRotate), "rotate");
    } else if(id.includes("cShape")) {
      handleRotate(
        index,
        "rotate",
        parseInt(localRotate),
        "cover"
      )
    } else {
      handleRotate(
        pageID,
        pageKey,
        null,
        productId,
        "additionData",
        null,
        null,
        `${id}Rotate`,
        parseInt(localRotate)
      );
    }
  };

  const snapTorotate = (rotate, increments) => {
    return increments.reduce((prev, curr) =>
      Math.abs(curr - rotate) < Math.abs(prev - rotate) ? curr : prev
    );
  };

  return (
    <div
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseUp}
      onMouseUp={handleMouseUp}
      className="rotatable"
      ref={divRef}
    ></div>
  );
};

export default RotatableDiv;
