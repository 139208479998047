import Draggable from "react-draggable";
import "react-resizable/css/styles.css";
import { useTranslation } from "react-i18next";
import { ResizableBox } from "react-resizable";
import React, { useRef, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { calculateCatalogSize, mobileFontSize } from "../../config/_global";
import {
  faEye,
  faEyeSlash,
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faArrowsUpDownLeftRight,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

// Font Names
const fontNames = [
  { "": "" },
  { Cairo: '"Cairo", sans-serif' },
  { Georgia: '"Georgia", serif' },
  { Papyrus: "Papyrus, fantasy" },
  { Tahoma: "Tahoma, Geneva, sans-serif" },
  { Arial: "Arial, Helvetica, sans-serif" },
  { Verdana: "Verdana, Geneva, sans-serif" },
  { Impact: "Impact, Charcoal, sans-serif" },
  { Helvetica: "Helvetica, Arial, sans-serif" },
  { Courier: '"Courier New", Courier, monospace' },
  { Lucida: '"Lucida Console", Monaco, monospace' },
  { Consolas: 'Consolas, "Courier New", monospace' },
  { "Brush Script MT": '"Brush Script MT", cursive' },
  { Trebuchet: '"Trebuchet MS", Helvetica, sans-serif' },
  { "Times New Roman": '"Times New Roman", Times, serif' },
  { "Comic Sans MS": '"Comic Sans MS", cursive, sans-serif' },
  { Palatino: '"Palatino Linotype", "Book Antiqua", Palatino, serif' },
];

const ResizableAndDraggableBox = ({
  x,
  y,
  id,
  show,
  index,
  width,
  onDrag,
  height,
  zIndex,
  content,
  opacity,
  onResize,
  currentBoxId,
  setShowTools,
  setCurrentBoxId,
  setCurrentIndex,
  handleChangeShapesList,
}) => {
  const [axis, setAxis] = useState("both");

  const handleDrag = (e) => {
    if (e.shiftKey) {
      const deltaX = Math.abs(e.movementX);
      const deltaY = Math.abs(e.movementY);
      if (deltaX > deltaY) {
        setAxis("x");
      } else {
        setAxis("y");
      }
    } else {
      setAxis("both");
    }
  };

  return (
    <Draggable
      axis={axis}
      bounds="parent"
      position={{ x, y }}
      onDrag={handleDrag}
      handle=".handle-button"
      onStop={(e, data) =>
        id.includes("shape-")
          ? handleChangeShapesList(
              index,
              "position",
              {
                x: data.x,
                y: data.y,
              },
              "profile"
            )
          : onDrag(id, data.x, data.y)
      }
    >
      <div
        style={{ position: "absolute", zIndex: zIndex || 0 }}
        onClick={() => {
          setShowTools(true);
          setCurrentBoxId(id);
          setCurrentIndex(index);
        }}
      >
        <div className="handle-button">
          <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
        </div>
        <ResizableBox
          resizeHandles={["s", "w", "e", "n", "nw", "ne", "se", "sw"]}
          width={width}
          height={height}
          onResize={(e, { size }) =>
            id.includes("shape-")
              ? handleChangeShapesList(
                  index,
                  "size",
                  {
                    width: size?.width,
                    height: size?.height,
                  },
                  "profile"
                )
              : onResize(id, size?.width, size?.height)
          }
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              opacity: show ? opacity : 0.1,
              border: currentBoxId === id ? "2px solid #f00" : "",
            }}
          >
            {content}
          </div>
        </ResizableBox>
      </div>
    </Draggable>
  );
};

const Profile = ({
  mode,
  source,
  profileData,
  setProfileData,
  productsCatalog,
  handleProfileData,
  deleteFromShapesList,
  handleChangeShapesList,
}) => {
  const componentRef = useRef(null);
  const { i18n } = useTranslation();
  const [boxes, setBoxes] = useState([]);
  const [histories, setHistories] = useState([]);
  const [showTools, setShowTools] = useState(false);
  const [catalogSize, setCatalogSize] = useState(null);
  const [currentBoxId, setCurrentBoxId] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(false);

  useEffect(() => {
    const localIsMobile = window.innerWidth <= 768;
    const initialLocalProfileData = [
      {
        id: "logo",
        content: productsCatalog?.categoryId ? (
          <img
            src={"https://sinaha.ae/image/" + productsCatalog?.categoryIcon}
            className="customerLogo"
            title="customerLogo"
            alt="customerLogo"
          />
        ) : (
          <img
            src={"https://sinaha.ae/image/" + productsCatalog?.customerLogo}
            className="customerLogo"
            title="customerLogo"
            alt="customerLogo"
          />
        ),
        size: profileData?.logo?.size,
        show: profileData?.logo?.show,
        opacity: profileData?.logo?.opacity,
        position: profileData?.logo?.position,
      },
      {
        id: "name",
        content: productsCatalog?.categoryId ? (
          i18n.language === "ar" && productsCatalog?.arCategoryName ? (
            <h4
              style={{
                fontSize: `${
                  profileData
                    ? localIsMobile
                      ? mobileFontSize(profileData?.name?.fontSize)
                      : mode === "smallView"
                      ? profileData?.name?.fontSize / 5
                      : profileData?.name?.fontSize
                    : "25"
                }px`,
                fontFamily: profileData
                  ? profileData?.name?.fontName
                  : '"Cairo", sans-serif',
                color: `${
                  profileData ? profileData?.name?.fontColor : "#000000"
                }`,
                fontWeight: profileData?.name?.bold && "bold",
                fontStyle: profileData?.name?.italic && "italic",
                textAlign: profileData?.name?.textAlign || "left",
                textDecoration: profileData?.name?.underline && "underline",
              }}
              dangerouslySetInnerHTML={{
                __html: productsCatalog?.arCategoryName,
              }}
            />
          ) : (
            <h4
              style={{
                fontSize: `${
                  profileData
                    ? localIsMobile
                      ? mobileFontSize(profileData?.name?.fontSize)
                      : mode === "smallView"
                      ? profileData?.name?.fontSize / 5
                      : profileData?.name?.fontSize
                    : "25"
                }px`,
                fontFamily: profileData
                  ? profileData?.name?.fontName
                  : '"Cairo", sans-serif',
                color: `${
                  profileData ? profileData?.name?.fontColor : "#000000"
                }`,
                fontWeight: profileData?.name?.bold && "bold",
                fontStyle: profileData?.name?.italic && "italic",
                textAlign: profileData?.name?.textAlign || "left",
                textDecoration: profileData?.name?.underline && "underline",
              }}
              dangerouslySetInnerHTML={{
                __html: productsCatalog?.enCategoryName,
              }}
            />
          )
        ) : i18n.language === "ar" && productsCatalog?.arCustomerName ? (
          <h4
            style={{
              fontSize: `${
                profileData
                  ? localIsMobile
                    ? mobileFontSize(profileData?.name?.fontSize)
                    : mode === "smallView"
                    ? profileData?.name?.fontSize / 5
                    : profileData?.name?.fontSize
                  : "25"
              }px`,
              fontFamily: profileData
                ? profileData?.name?.fontName
                : '"Cairo", sans-serif',
              color: `${
                profileData ? profileData?.name?.fontColor : "#000000"
              }`,
              fontWeight: profileData?.name?.bold && "bold",
              fontStyle: profileData?.name?.italic && "italic",
              textAlign: profileData?.name?.textAlign || "left",
              textDecoration: profileData?.name?.underline && "underline",
            }}
            dangerouslySetInnerHTML={{
              __html: productsCatalog?.arCustomerName,
            }}
          />
        ) : (
          <h4
            style={{
              fontSize: `${
                profileData
                  ? localIsMobile
                    ? mobileFontSize(profileData?.name?.fontSize)
                    : mode === "smallView"
                    ? profileData?.name?.fontSize / 5
                    : profileData?.name?.fontSize
                  : "25"
              }px`,
              fontFamily: profileData
                ? profileData?.name?.fontName
                : '"Cairo", sans-serif',
              color: `${
                profileData ? profileData?.name?.fontColor : "#000000"
              }`,
              fontWeight: profileData?.name?.bold && "bold",
              fontStyle: profileData?.name?.italic && "italic",
              textAlign: profileData?.name?.textAlign || "left",
              textDecoration: profileData?.name?.underline && "underline",
            }}
            dangerouslySetInnerHTML={{
              __html: productsCatalog?.enCustomerName,
            }}
          />
        ),
        bold: profileData?.name?.bold,
        italic: profileData?.name?.italic,
        opacity: profileData?.name?.opacity,
        underline: profileData?.name?.underline,
        textAlign: profileData?.name?.textAlign,
        show: profileData ? profileData?.name?.show : true,
        fontColor: profileData ? profileData?.name?.fontColor : "#000000",
        position: profileData ? profileData?.name?.position : { x: 130, y: 20 },
        size: profileData
          ? profileData?.name?.size
          : { width: "auto", height: "auto" },
        fontName: profileData
          ? profileData?.name?.fontName
          : '"Times New Roman", Times, serif',
        fontSize: profileData
          ? localIsMobile
            ? mobileFontSize(profileData?.name?.fontSize)
            : profileData?.name?.fontSize
          : 14,
      },
      {
        id: "description",
        content: productsCatalog?.categoryId ? (
          <div
            style={{
              fontSize: `${
                profileData
                  ? localIsMobile
                    ? mobileFontSize(profileData?.description?.fontSize)
                    : mode === "smallView"
                    ? profileData?.description?.fontSize / 5
                    : profileData?.description?.fontSize
                  : "14"
              }px`,
              fontFamily: profileData
                ? profileData?.description?.fontName
                : '"Cairo", sans-serif',
              color: `${
                profileData ? profileData?.description?.fontColor : "#000000"
              }`,
              fontWeight: profileData?.description?.bold && "bold",
              fontStyle: profileData?.description?.italic && "italic",
              textAlign: profileData?.description?.textAlign || "left",
              textDecoration:
                profileData?.description?.underline && "underline",
            }}
            dangerouslySetInnerHTML={{
              __html: convertHtmlToPlainText(
                productsCatalog?.enCategoryDescription
              ),
            }}
          />
        ) : (
          <div
            style={{
              fontSize: `${
                profileData
                  ? localIsMobile
                    ? mobileFontSize(profileData?.description?.fontSize)
                    : mode === "smallView"
                    ? profileData?.description?.fontSize / 5
                    : profileData?.description?.fontSize
                  : "14"
              }px`,
              fontFamily: profileData
                ? profileData?.description?.fontName
                : '"Cairo", sans-serif',
              color: `${
                profileData ? profileData?.description?.fontColor : "#000000"
              }`,
              fontWeight: profileData?.description?.bold && "bold",
              fontStyle: profileData?.description?.italic && "italic",
              textAlign: profileData?.description?.textAlign || "left",
              textDecoration:
                profileData?.description?.underline && "underline",
            }}
            dangerouslySetInnerHTML={{
              __html: convertHtmlToPlainText(productsCatalog?.shortProfile),
            }}
          />
        ),
        bold: profileData?.description?.bold,
        italic: profileData?.description?.italic,
        opacity: profileData?.description?.opacity,
        underline: profileData?.description?.underline,
        textAlign: profileData?.description?.textAlign,
        show: profileData ? profileData?.description?.show : true,
        fontSize: profileData
          ? localIsMobile
            ? mobileFontSize(profileData?.description?.fontSize)
            : profileData?.description?.fontSize
          : 14,
        fontColor: profileData
          ? profileData?.description?.fontColor
          : "#000000",
        position: profileData
          ? profileData?.description?.position
          : { x: 20, y: 130 },
        size: profileData
          ? profileData?.description?.size
          : { width: "auto", height: "auto" },
        fontName: profileData
          ? profileData?.description?.fontName
          : '"Times New Roman", Times, serif',
      },
    ];
    setBoxes(initialLocalProfileData);
  }, [profileData]);

  useEffect(() => {
    const props = {
      mode,
      source,
      productsCatalog,
    };
    setCatalogSize(calculateCatalogSize(props));
  }, [productsCatalog]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === "z") {
        handleUndo();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [histories]);

  const handleUndo = () => {
    if (histories && histories.length > 0) {
      const lastHistory = histories[histories.length - 1];
      setProfileData(lastHistory);
      setHistories(histories.slice(0, -1));
    }
  };

  const saveToHistory = (newHistory) => {
    setHistories((prevHistories) => [...prevHistories, newHistory]);
  };

  const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const convertHtmlToPlainText = (htmlString) => {
    var text = stripHtml(htmlString);
    text = text.replace(/<\/?[^>]+(>|$)/g, "");
    return text;
  };

  const handleResize = (id, width, height) => {
    saveToHistory(profileData);
    setBoxes((prevBoxes) =>
      prevBoxes.map((box) =>
        box.id === id ? { ...box, size: { width: width, height: height } } : box
      )
    );
    setProfileData((prevStyles) => ({
      ...prevStyles,
      [id]: {
        ...prevStyles[id],
        size: { width: parseInt(width), height: parseInt(height) },
      },
    }));
  };

  const handleDrag = (id, x, y) => {
    saveToHistory(profileData);
    setBoxes((prevBoxes) =>
      prevBoxes.map((box) =>
        box.id === id
          ? { ...box, position: { x: parseInt(x), y: parseInt(y) } }
          : box
      )
    );
    setProfileData((prevStyles) => ({
      ...prevStyles,
      [id]: {
        ...prevStyles[id],
        position: { x: parseInt(x), y: parseInt(y) },
      },
    }));
  };

  return (
    <>
      {showTools && (
        <div className="resizableAndDraggableBoxNavBar">
          <label className="tools-item">
            <span>Show</span>
            <button
              className="show-button"
              onClick={(e) =>
                currentBoxId.includes("shape-")
                  ? handleChangeShapesList(
                      currentIndex,
                      "show",
                      !productsCatalog?.profileShapesList[currentIndex].show,
                      "profile"
                    )
                  : handleProfileData(currentBoxId, e, "show")
              }
            >
              <FontAwesomeIcon
                icon={
                  currentBoxId.includes("shape-")
                    ? productsCatalog?.profileShapesList[currentIndex].show
                      ? faEye
                      : faEyeSlash
                    : profileData[currentBoxId].show
                    ? faEye
                    : faEyeSlash
                }
              />{" "}
            </button>
          </label>
          <label className="tools-item">
            <span>Opacity</span>
            <input
              max={1}
              min={0.1}
              step={0.1}
              type="number"
              title="Opacity"
              className="opacity-input"
              value={
                currentBoxId.includes("shape-")
                  ? productsCatalog?.profileShapesList[currentIndex].opacity
                  : profileData[currentBoxId].opacity
              }
              onChange={(e) =>
                currentBoxId.includes("shape-")
                  ? handleChangeShapesList(
                      currentIndex,
                      "opacity",
                      e.target.value,
                      "profile"
                    )
                  : handleProfileData(currentBoxId, e, "opacity")
              }
            />
          </label>
          {currentBoxId !== "logo" && !currentBoxId.includes("shape-") && (
            <>
              <label className="tools-item">
                <span>Font Color</span>
                <input
                  type="color"
                  className="font-color-input"
                  value={profileData[currentBoxId].fontColor}
                  onChange={(e) =>
                    handleProfileData(currentBoxId, e, "fontColor")
                  }
                />
              </label>
              <label className="tools-item">
                <span>Font Size</span>
                <input
                  min={0}
                  type="number"
                  className="font-size-input"
                  value={profileData[currentBoxId].fontSize}
                  onChange={(e) =>
                    handleProfileData(currentBoxId, e, "fontSize")
                  }
                />
              </label>
              <label className="tools-item">
                <span>Font</span>
                <select
                  className="font-name-select"
                  value={profileData[currentBoxId].fontName}
                  onChange={(e) =>
                    handleProfileData(currentBoxId, e, "fontName")
                  }
                >
                  {fontNames &&
                    fontNames.map((item) =>
                      Object.entries(item).map(([key, value]) => (
                        <option
                          key={key}
                          value={value}
                          style={{ fontFamily: value }}
                        >
                          {key}
                        </option>
                      ))
                    )}
                </select>
              </label>
              <label className="tools-item">
                <span>Bold</span>
                <button
                  onClick={(e) => handleProfileData(currentBoxId, e, "bold")}
                  className={`bold-button ${
                    profileData[currentBoxId].bold ? "active" : ""
                  }`}
                >
                  B
                </button>
              </label>
              <label className="tools-item">
                <span>Italic</span>
                <button
                  onClick={(e) => handleProfileData(currentBoxId, e, "italic")}
                  className={`italic-button ${
                    profileData[currentBoxId].italic ? "active" : ""
                  }`}
                >
                  I
                </button>
              </label>
              <label className="tools-item">
                <span>Underline</span>
                <button
                  onClick={(e) =>
                    handleProfileData(currentBoxId, e, "underline")
                  }
                  className={`underline-button ${
                    profileData[currentBoxId].underline ? "active" : ""
                  }`}
                >
                  U
                </button>
              </label>
              <label className="tools-item">
                <span>Text Align</span>
                <div className="d-flex justify-content-between">
                  <div
                    className={`text-align ${
                      profileData[currentBoxId].textAlign === "left"
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      handleProfileData(currentBoxId, "left", "textAlign")
                    }
                  >
                    <FontAwesomeIcon icon={faAlignLeft} />
                  </div>
                  <div
                    className={`text-align ${
                      profileData[currentBoxId].textAlign === "center"
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      handleProfileData(currentBoxId, "center", "textAlign")
                    }
                  >
                    <FontAwesomeIcon icon={faAlignCenter} />
                  </div>
                  <div
                    className={`text-align ${
                      profileData[currentBoxId].textAlign === "right"
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      handleProfileData(currentBoxId, "right", "textAlign")
                    }
                  >
                    <FontAwesomeIcon icon={faAlignRight} />
                  </div>
                </div>
              </label>
            </>
          )}

          {currentBoxId.includes("shape-") && (
            <>
              {productsCatalog?.profileShapesList.find(
                (shape, index) => index === currentIndex
              )?.type !== "line" && (
                <label className="tools-item">
                  <span>BG Color</span>
                  <input
                    type="color"
                    className="bg-color-input"
                    value={
                      productsCatalog?.profileShapesList.find(
                        (shape, index) => index === currentIndex
                      )?.bgColor
                    }
                    onChange={(e) =>
                      handleChangeShapesList(
                        currentIndex,
                        "color",
                        e.target.value,
                        "profile"
                      )
                    }
                    title="BG Color"
                  />
                </label>
              )}
              <label className="tools-item">
                <span>Border Width</span>
                <input
                  type="number"
                  className="border-width-input"
                  value={
                    productsCatalog?.profileShapesList.find(
                      (shape, index) => index === currentIndex
                    )?.borderWidth
                  }
                  onChange={(e) =>
                    handleChangeShapesList(
                      currentIndex,
                      "borderWidth",
                      e.target.value,
                      "profile"
                    )
                  }
                  title="Border Width"
                />
              </label>
              <label className="tools-item">
                <span>Border Color</span>
                <input
                  type="color"
                  className="border-color-input"
                  value={
                    productsCatalog?.profileShapesList.find(
                      (shape, index) => index === currentIndex
                    )?.borderColor
                  }
                  onChange={(e) =>
                    handleChangeShapesList(
                      currentIndex,
                      "borderColor",
                      e.target.value,
                      "profile"
                    )
                  }
                  title="Border Color"
                />
              </label>
              {productsCatalog?.profileShapesList.find(
                (shape, index) => index === currentIndex
              )?.type === "square" && (
                <label className="tools-item">
                  <span>Border Radius</span>
                  <input
                    type="number"
                    title="Border Radius"
                    value={
                      productsCatalog?.profileShapesList.find(
                        (shape, index) => index === currentIndex
                      )?.borderRadius
                    }
                    className="border-radius-input"
                    onChange={(e) =>
                      handleChangeShapesList(
                        currentIndex,
                        "borderRadius",
                        e.target.value,
                        "profile"
                      )
                    }
                  />
                </label>
              )}
              <label className="tools-item">
                <span>Border Style</span>
                <select
                  className="border-style-select"
                  value={
                    productsCatalog?.profileShapesList.find(
                      (shape, index) => index === currentIndex
                    )?.borderStyle
                  }
                  onChange={(e) =>
                    handleChangeShapesList(
                      currentIndex,
                      "borderStyle",
                      e.target.value,
                      "profile"
                    )
                  }
                  title="Border Style"
                >
                  <option></option>
                  <option value="solid">Solid</option>
                  <option value="dashed">Dashed</option>
                  <option value="dotted">Dotted</option>
                </select>
              </label>
              <label className="tools-item">
                <span>Front Index</span>
                <input
                  min={0}
                  type="number"
                  className="zIndex-input"
                  value={
                    productsCatalog?.profileShapesList.find(
                      (shape, index) => index === currentIndex
                    )?.zIndex
                  }
                  onChange={(e) =>
                    handleChangeShapesList(
                      currentIndex,
                      "zIndex",
                      e.target.value,
                      "profile"
                    )
                  }
                  title="Index"
                />
              </label>
              <label className="tools-item">
                <span>Delete</span>
                <button
                  className="delete-button"
                  onClick={() => deleteFromShapesList(currentIndex, "profile")}
                  title="Delete"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </label>
            </>
          )}
        </div>
      )}
      <div
        className="page-style even"
        ref={componentRef}
        style={{
          margin: "0 auto",
          width: catalogSize ? catalogSize?.width : "100%",
          height: catalogSize ? catalogSize?.height : "100%",
        }}
      >
        {mode === "editable" && (
          <>
            <div className="editing-line-horizontal"></div>
            <div className="editing-line-vertical"></div>
          </>
        )}
        <div
          className={"company-profile " + productsCatalog?.mode}
          style={{
            backgroundColor: productsCatalog?.profileBackgroundColor,
            background: `linear-gradient(rgb(255 255 255 / ${
              productsCatalog?.profileImageOpacity
            }%), rgb(255 255 255 / ${
              productsCatalog?.profileImageOpacity
            }%)), url('${productsCatalog?.profileImage?.replace(
              /[^\x00-\x7F]/g,
              ""
            )}') no-repeat ${productsCatalog?.profileImagePosition} / ${
              productsCatalog?.profileImageSize
            }`,
          }}
        >
          <div className="even-shadow"></div>
          {boxes.map(({ id, size, show, content, opacity, position }) =>
            mode === "editable" ? (
              <ResizableAndDraggableBox
                id={id}
                key={id}
                show={show}
                x={position?.x}
                y={position?.y}
                content={content}
                opacity={opacity}
                width={size?.width}
                onDrag={handleDrag}
                height={size?.height}
                onResize={handleResize}
                currentBoxId={currentBoxId}
                setShowTools={setShowTools}
                setCurrentBoxId={setCurrentBoxId}
                setCurrentIndex={setCurrentIndex}
              />
            ) : (
              show && (
                <div
                  key={id}
                  style={{
                    opacity: opacity,
                    position: "absolute",
                    height: `${
                      (size?.height / productsCatalog?.catalogSize?.height) *
                      100
                    }%`,
                    width: `${
                      (size?.width / productsCatalog?.catalogSize?.width) * 100
                    }%`,
                    top: `${
                      (position?.y / productsCatalog?.catalogSize?.height) * 100
                    }%`,
                    left: `${
                      (position?.x / productsCatalog?.catalogSize?.width) * 100
                    }%`,
                  }}
                >
                  {content}
                </div>
              )
            )
          )}

          {productsCatalog?.profileShapesList?.length > 0 &&
            (mode === "editable"
              ? productsCatalog?.profileShapesList?.map((shape, index) => (
                  <ResizableAndDraggableBox
                    index={index}
                    show={shape?.show}
                    pageKey="shapesList"
                    id={`shape-${index}`}
                    key={`shape-${index}`}
                    rotate={shape?.rotate}
                    x={shape?.position?.x}
                    y={shape?.position?.y}
                    zIndex={shape?.zIndex}
                    shapeType={shape?.type}
                    opacity={shape?.opacity}
                    componentRef={componentRef}
                    currentBoxId={currentBoxId}
                    setShowTools={setShowTools}
                    setCurrentBoxId={setCurrentBoxId}
                    setCurrentIndex={setCurrentIndex}
                    handleChangeShapesList={handleChangeShapesList}
                    content={
                      <div
                        className={`${shape?.type} ${
                          shape?.type !== "line" && "w-100"
                        }  h-100 m-0`}
                        style={
                          shape?.type === "line"
                            ? {
                                position: "absolute",
                                top: shape?.y,
                                left: shape?.x,
                                borderLeftColor: shape?.borderColor,
                                borderLeftStyle: shape?.borderStyle,
                                borderLeftWidth: `${shape?.borderWidth}px`,
                              }
                            : {
                                position: "absolute",
                                top: shape?.y,
                                left: shape?.x,
                                backgroundColor: shape?.bgColor,
                                borderStyle: shape?.borderStyle,
                                borderColor: shape?.borderColor,
                                borderWidth: `${shape?.borderWidth}px`,
                                borderRadius:
                                  shape?.type === "square" &&
                                  `${shape?.borderRadius}px`,
                              }
                        }
                      ></div>
                    }
                    width={shape?.size ? shape?.size?.width : 100}
                    height={shape?.size ? shape?.size?.height : 100}
                  />
                ))
              : productsCatalog?.profileShapesList?.map((shape, index) => (
                    <div
                      key={index}
                      className={`${shape?.type} m-0`}
                      style={
                        shape?.type === "line"
                          ? {
                              position: "absolute",
                              opacity: shape?.opacity,
                              zIndex: `${shape?.zIndex}`,
                              display: !shape?.show && "none",
                              borderLeftColor: shape?.borderColor,
                              borderLeftStyle: shape?.borderStyle,
                              transform: `rotate(${shape?.rotate}deg)`,
                              left: `${
                                shape
                                  ? (shape?.position?.x /
                                      productsCatalog?.catalogSize?.width) *
                                    100
                                  : 20
                              }%`,
                              top: `${
                                shape
                                  ? (shape?.position?.y /
                                      productsCatalog?.catalogSize?.height) *
                                    100
                                  : 20
                              }%`,
                              height: `${
                                shape
                                  ? (shape?.size?.height /
                                      productsCatalog?.catalogSize?.height) *
                                    100
                                  : 100
                              }%`,
                              borderLeftWidth: `${
                                mode === "smallView"
                                  ? shape?.borderWidth / 4
                                  : shape?.borderWidth
                              }px`,
                            }
                          : {
                              position: "absolute",
                              zIndex: shape?.zIndex,
                              opacity: shape?.opacity,
                              backgroundColor: shape?.bgColor,
                              display: !shape?.show && "none",
                              borderStyle: shape?.borderStyle,
                              borderColor: shape?.borderColor,
                              transform: `rotate(${shape?.rotate}deg)`,
                              left: `${
                                shape
                                  ? (shape?.position?.x /
                                      productsCatalog?.catalogSize?.width) *
                                    100
                                  : 20
                              }%`,
                              width: `${
                                shape
                                  ? (shape?.size?.width /
                                      productsCatalog?.catalogSize?.width) *
                                    100
                                  : 100
                              }%`,
                              top: `${
                                shape
                                  ? (shape?.position?.y /
                                      productsCatalog?.catalogSize?.height) *
                                    100
                                  : 20
                              }%`,
                              height: `${
                                shape
                                  ? (shape?.size?.height /
                                      productsCatalog?.catalogSize?.height) *
                                    100
                                  : 100
                              }%`,
                              borderWidth: `${
                                mode === "smallView"
                                  ? shape?.borderWidth / 4
                                  : shape?.borderWidth
                              }px`,
                              borderRadius:
                                shape?.type === "square" &&
                                `${
                                  mode === "smallView"
                                    ? shape?.borderRadius / 4
                                    : shape?.borderRadius
                                }px`,
                            }
                      }
                    ></div>
                )))}
        </div>
      </div>
    </>
  );
};

export default Profile;
