import { calculateCatalogSize } from "../../../config/_global";
import React, { useEffect, useState } from "react";
import Customize from "./customize";
import Template1 from "./template1";
import Template2 from "./template2";

function Cover(props) {
  const [catalogSize, setCatalogSize] = useState(null);

  useEffect(() => {
    setCatalogSize(calculateCatalogSize(props));
  }, [props.productsCatalog]);

  return (
    <div
    
      className={`page-style-cover ${props.productsCatalog?.designTemplate} hard`}
      style={{
        margin: "0 auto",
        width: catalogSize ? catalogSize?.width : '100%',
        height: catalogSize ? catalogSize?.height : '100%',
      }}
    >
      {props.productsCatalog?.designTemplate === "temp-2" ? (
        <Template2 productsCatalog={props.productsCatalog} />
      ) : props.productsCatalog?.designTemplate === "temp-1" ? (
        <Template1 productsCatalog={props.productsCatalog} />
      ) : (
        <Customize
          mode={props.mode}
          fontNames={props.fontNames}
          coverData={props.coverData}
          // bookCoverRef={props.bookCoverRef}
          setCoverData={props.setCoverData}
          productsCatalog={props.productsCatalog}
          handleCoverData={props.handleCoverData}
          setCurrentTopRender={props.setCurrentTopRender}
          deleteFromShapesList={props.deleteFromShapesList}
          handleChangeShapesList={props.handleChangeShapesList}
        />
      )}
    </div>
  );
}

export default Cover;
