import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import React from "react";

function BackCoverConfig(props) {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-center align-items-center h-100 w-100 selected">
      <div className="item">
        <Form.Label>{t("Select main color")}</Form.Label>
        <Form.Control
          className="w-100"
          type="color"
          id="mainColor"
          value={props.mainColor}
          onChange={(e) => props.handleChangeMainColor(e.target.value)}
        />
      </div>
      <div className="item">
        <Form.Label>{t("Select design")}</Form.Label>
        <div>
          <Form.Check
            inline
            label={t("design") + " 1"}
            value="temp-1"
            name="designTemplate"
            type="radio"
            checked={props.backCoverDesignTemplate === "temp-1"}
            onChange={(e) => {
              props.setBackCoverDesignTemplate(e.target.value);
            }}
          />
          <Form.Check
            inline
            type="radio"
            value="customize"
            name="designTemplate"
            label={t("customize")}
            checked={props.backCoverDesignTemplate === "customize"}
            onChange={(e) => props.setBackCoverDesignTemplate(e.target.value)}
          />
        </div>
      </div>
      <div className="item">
        <Form.Label>{t("Select cover background")}</Form.Label>
        <div className="d-flex justify-content-between align-items-center">
          <Form.Control
            type="file"
            id="coverImage"
            accept="image/*"
            onChange={props.handleBackCoverImageChange}
            style={{ marginRight: "5px", height: "38px", width: "104px" }}
          />
          <div className="position-relative">
            {props.backCoverImagePreviewUrl && (
              <>
                <button
                  onClick={props.deleteBackCoverImage}
                  className="delete-cover"
                >
                  <FontAwesomeIcon icon={faX} />
                </button>
                <img
                  src={props.backCoverImagePreviewUrl}
                  alt="Preview"
                  style={{
                    width: "100%",
                    height: "70px",
                    objectFit: "contain",
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BackCoverConfig;
