export const mobileFontSize = (baseSize) => {
  const rateWidth = window.innerWidth / 768;
  return `${baseSize * rateWidth}px`;
};

export const calculateCatalogSize = (props) => {
  let width, height;
  const localIsMobile = window.innerWidth <= 768;
  if (localIsMobile && props.source === "catalogPage") {
    width = `${window.innerWidth - 40}px`;
    height = `${width * 1.3}px`;
  } else {
    width = `${
      props.mode === "smallView"
        ? 114
        : props.productsCatalog?.catalogSize?.width
    }px`;
    if (props.productsCatalog?.orientation === "Portrait") {
      height = `${
        props.mode === "smallView"
          ? props.productsCatalog?.catalogSize?.height / 5.12
          : props.productsCatalog?.catalogSize?.height
      }px`;
    } else {
      height = `${
        props.mode === "smallView"
          ? props.productsCatalog?.catalogSize?.height / 8.3
          : props.productsCatalog?.catalogSize?.height
      }px`;
    }
  }
  return { width, height };
};

// Function to remove spaces and capitalize the first letter of each word
export const capitalizeWordsNoSpaces = (input) => {
  return input
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");
};

// Function to remove spaces, capitalize each word but keep the first letter of the first word lowercase
export const capitalizeWordsNoSpacesLowerFirst = (input) => {
  const words = input.toLowerCase().split(" ");
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  // Make the first word's first letter lowercase
  capitalizedWords[0] =
    capitalizedWords[0].charAt(0).toLowerCase() + capitalizedWords[0].slice(1);
  return capitalizedWords.join("");
};

export const convertToKebabCase = (input) => {
  return input.toLowerCase().replace(/\s+/g, "-");
};
