import React, { createContext, useState } from 'react';

export const CatalogContext = createContext();

export const CatalogProvider = ({ children }) => {
  const [catalogSize, setCatalogSize] = useState({ width: 550, height: 800 });

  return (
    <CatalogContext.Provider value={{ catalogSize, setCatalogSize }}>
      {children}
    </CatalogContext.Provider>
  );
};