import React, { useState } from "react";

const Pagination = ({ totalPages, currentPage, setCurrentPage }) => {
  const [startPage, setStartPage] = useState(1);
  const visiblePages = 9;

  // Calculate the end page to show based on the startPage
  const endPage = Math.min(startPage + visiblePages - 1, totalPages);

  const handleClick = (page) => {
    setCurrentPage(page);

    if (page === endPage && page < totalPages) {
      setStartPage(startPage + 4);
    } else if (page === startPage && page > 1) {
      setStartPage(Math.max(1, startPage - 4));
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      handleClick(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      handleClick(currentPage - 1);
    }
  };

  const handleFirst = () => {
    setStartPage(1);
    setCurrentPage(1);
  };

  const handleLast = () => {
    const newStart = Math.max(totalPages - visiblePages + 1, 1);
    setStartPage(newStart);
    setCurrentPage(totalPages);
  };

  return (
    <nav>
      <ul className="pagination pagination-sm mt-3 position-absolute start-0 end-0 justify-content-center">
        {startPage !== 1 && (
          <>
            <li onClick={handleFirst}>
              <span className="page-link"> First</span>
            </li>
            <li onClick={handlePrevious}>
              <span className="page-link"> Previous</span>
            </li>
          </>
        )}

        {Array.from({ length: endPage - startPage + 1 }, (_, i) => {
          const page = startPage + i;
          return (
            <li
              onClick={() => handleClick(page)}
              key={page}
              className={currentPage === page ? "active" : ""}
            >
              <span className="page-link">{page}</span>
            </li>
          );
        })}
        {endPage < totalPages && (
          <>
            <li onClick={handleNext}>
              <span className="page-link"> Next</span>
            </li>
            <li onClick={handleLast}>
              <span className="page-link"> Last</span>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;
