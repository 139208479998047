import React from "react";
import { decodeHtml } from "./decodeHtml";
import { useTranslation } from "react-i18next";
import { Modal, Row, Col } from "react-bootstrap";
import { extractTextFromHtml } from "./extractText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoice, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

function ProductModal({ show, handleClose, product }) {
  const { t, i18n } = useTranslation();
  if (!product) return null;

  const decodeHtmlEntities = (text) => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    return textArea.value;
  };

  return (
    <Modal show={show} onHide={handleClose} centered className="product-modal">
      <Modal.Header closeButton>
        <Modal.Title>
          {i18n.language === "ar" ? (
            <p className="item-title">{decodeHtmlEntities(product.ar_name)}</p>
          ) : (
            <p className="item-title">{decodeHtmlEntities(product.en_name)}</p>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} md={6} lg={6}>
            <img
              alt=""
              className="product-image"
              src={"https://sinaha.ae/image/" + product.image}
              />
          </Col>
          <Col xs={12} md={6} lg={6}>
            <div className="product-details">
              {i18n.language === "ar"
                ? extractTextFromHtml(decodeHtml(product.ar_description))
                : extractTextFromHtml(decodeHtml(product.en_description))}
              <div className="icons mt-3">
                {product.membership_level_list?.map((level, key) => {
                  return (
                    <div key={key}>
                      <img
                        className="icon"
                        src={"https://sinaha.ae/image/" + level}
                        alt="0"
                        style={{
                          width:
                            level === "logo-en-100x100.png" ? "80%" : "40%",
                        }}
                      />
                    </div>
                  );
                })}
                <img
                  className="qr-code-image"
                  src={
                    "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https%3A%2F%2Fsinaha.ae%2Findex.php%3Froute%3Dproduct%2Fproduct%26product_id%3D" +
                    product.product_id
                  }
                  alt={product.en_name}
                  title={product.en_name}
                />
              </div>
              <hr />
              <div className="mt-3 d-flex justify-content-between">
                <a
                  href={
                    product.keyword
                      ? `https://sinaha.ae/en/${product.keyword}`
                      : `https://sinaha.ae/index.php?route=product/product&product_id=${product.product_id}`
                  }
                  target="_blank"
                  style={{ width: "45%" }}
                  className="btn btn-success d-block"
                >
                  <FontAwesomeIcon icon={faInfoCircle} /> &nbsp;&nbsp;
                  {t("More Details")}
                </a>
                <a
                  href={`https://sinaha.ae/en/sinahaqt-rfq&product_id=${product.product_id}`}
                  target="_blank"
                  style={{ width: "45%" }}
                  className="btn btn-primary d-block"
                >
                  <FontAwesomeIcon icon={faFileInvoice} /> &nbsp;&nbsp;{" "}
                  {t("RFQ")}
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default ProductModal;
