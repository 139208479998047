import Nav from "react-bootstrap/Nav";
import { useCookies } from "react-cookie";
import Navbar from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import NavDropdown from "react-bootstrap/NavDropdown";
import { startTransition, useEffect, useState } from "react";
import { fetchAllEvents } from "../api/events";

function Header({
  aggregatorExecuteScroll,
  aboutMarkExecuteScroll,
  contactExecuteScroll,
  from,
}) {
  const [cookies, setCookie] = useCookies(["lang"]);
  let current_location = document.location.origin;
  const [events, setEvents] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    fetchDataFromAPI();
  }, []);

  const fetchDataFromAPI = async () => {
    try {
      startTransition(async () => {
        const getEvents = await fetchAllEvents();
        setEvents(getEvents);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const changeLanguage = (event, lang) => {
    i18n.changeLanguage(lang);
    setCookie("lang", lang);
    if ((lang !== "" && lang === "ar") || i18n.language === "ar") {
      document.body.classList.add("direction-rtl");
    } else {
      document.body.classList.remove("direction-rtl");
    }
  };

  return (
    <div className="position-fixed">
      <div className="top-header"></div>
      <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href={current_location}>
            <img
              src="/assets/images/sinaha-smart-catalog.png"
              className="logo-image"
              alt="Bootstrap Themes"
              width="700"
              height="500"
              loading="lazy"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                onClick={aboutMarkExecuteScroll}
                href={from === "home" ? "" : current_location}
              >
                {t("about")}
              </Nav.Link>
              <Nav.Link
                onClick={aggregatorExecuteScroll}
                href={from === "home" ? "" : current_location}
              >
                {t("aggregator")}
              </Nav.Link>
              <Nav.Link
                onClick={contactExecuteScroll}
                href={from === "home" ? "" : current_location}
              >
                {t("contact")}
              </Nav.Link>
              <NavDropdown title={t("Events")} id="basic-nav-dropdown">
                {events &&
                  events.map((event) => (
                    <NavDropdown.Item href={`/event/${event.slug}`}>
                      {i18n.language === "ar" ? event.arTitle : event.enTitle}
                    </NavDropdown.Item>
                  ))}
              </NavDropdown>
            </Nav>
            <Nav>
              <NavDropdown
                title={i18n.language === "ar" ? "العربية" : "English"}
                id="collapsible-nav-dropdown"
              >
                <NavDropdown.Item onClick={(e) => changeLanguage(e, "en")}>
                  English
                </NavDropdown.Item>
                <NavDropdown.Item onClick={(e) => changeLanguage(e, "ar")}>
                  العربية{" "}
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
