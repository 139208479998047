import api from "../config/api";

// Function to create a new catalog
export const createCatalog = async (formData) => {
  try {
    const response = await api.post("/catalogs", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

// Function to create a new catalog
export const createImage = async (formData) => {
  try {
    const response = await api.post("/catalogs/createImage", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

// Function to fetch all catalogs
export const fetchCatalogs = async () => {
  try {
    const response = await api.get("/catalogs");
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

// Function to fetch a specific catalog by ID
export const fetchCatalogById = async (slug) => {
  try {
    const response = await api.get(`/catalogs/${slug}`);
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

// Function to fetch a specific catalog by ID
export const fetchCatalogByCategory = async (id, from = "catalogPage") => {
  try {
    const response = await api.get(
      `/catalogs/0/?categoryId=${id}&from=${from}`
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

// Function to fetch a specific catalog by ID
export const fetchCatalogByCustomer = async (id, from = "catalogPage") => {
  try {
    const response = await api.get(
      `/catalogs/0/?customerId=${id}&from=${from}`
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

// Function to fetch a specific catalog page by ID
export const fetchCatalogPageById = async (id, page_number) => {
  try {
    const response = await api.get(`/catalogs/getPage/${id}/${page_number}`);
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

// Function to fetch a specific catalog by ID
export const fetchAll = async (
  orientation,
  limit = 5,
  page = 1,
  type = "Factories",
  currentCategory = null,
  from = "home",
  keyword = null
) => {
  try {
    const response = await api.get(
      `/catalogs/orientations/${orientation}/${limit}/${page}/${type}/${currentCategory}/${from}/${keyword}`
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

// Function to fetch a specific catalog by ID
export const fetchCatalogViews = async (catalogId) => {
  try {
    const response = await api.get(`/catalogviews/${catalogId}`);
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

// Function to fetch a specific catalog by ID
export const checkViews = async (data) => {
  try {
    const response = await api.post(`/catalogviews/check`, data);
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

// Function to fetch a specific catalog page by ID
export const imageProxy = async (url) => {
  try {
    const response = await api.post(`/catalogs/image-proxy`, { url });
    console.log('response', response)
    return response;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

// // Function to create a new catalog
// export const createViews = async (data) => {
//   try {
//     const response = await api.post("/catalogviews", data, {
//       headers: { "Content-Type": "multipart/form-data" },
//     });
//     return response.data;
//   } catch (error) {
//     throw new Error(
//       error.response ? error.response.data.message : error.message
//     );
//   }
// };
