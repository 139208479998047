import {
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function Align(props) {
  return (
    <label className="tools-item">
      <span>{props?.title}</span>
      <div className="d-flex justify-content-between">
        <div
          className={`text-align ${
            props?.page?.productsList?.find(
              (product) => product.product_id === props?.currentProductId
            )?.additionData[`${props?.currentBoxId.split("-")[0]}TextAlign`] ===
              "left" && "active"
          }`}
          onClick={() =>
            props?.currentBoxId.includes("text-")
              ? props?.applyStyle("justifyLeft")
              : props?.handleChangePageContent(
                  props?.currentPageId,
                  props?.currentPageKey,
                  null,
                  props?.currentProductId,
                  "additionData",
                  null,
                  null,
                  `${props?.currentBoxId.split("-")[0]}TextAlign`,
                  "left"
                )
          }
        >
          <FontAwesomeIcon icon={faAlignLeft} />
        </div>
        <div
          className={`text-align ${
            props?.page?.productsList?.find(
              (product) => product.product_id === props?.currentProductId
            )?.additionData[`${props?.currentBoxId.split("-")[0]}TextAlign`] ===
              "center" && "active"
          }`}
          onClick={() =>
            props?.currentBoxId.includes("text-")
              ? props?.applyStyle("justifyCenter")
              : props?.handleChangePageContent(
                  props?.currentPageId,
                  props?.currentPageKey,
                  null,
                  props?.currentProductId,
                  "additionData",
                  null,
                  null,
                  `${props?.currentBoxId.split("-")[0]}TextAlign`,
                  "center"
                )
          }
        >
          <FontAwesomeIcon icon={faAlignCenter} />
        </div>
        <div
          className={`text-align ${
            props?.page?.productsList?.find(
              (product) => product.product_id === props?.currentProductId
            )?.additionData[`${props?.currentBoxId.split("-")[0]}TextAlign`] ===
              "right" && "active"
          }`}
          onClick={() =>
            props?.currentBoxId.includes("text-")
              ? props?.applyStyle("justifyRight")
              : props?.handleChangePageContent(
                  props?.currentPageId,
                  props?.currentPageKey,
                  null,
                  props?.currentProductId,
                  "additionData",
                  null,
                  null,
                  `${props?.currentBoxId.split("-")[0]}TextAlign`,
                  "right"
                )
          }
        >
          <FontAwesomeIcon icon={faAlignRight} />
        </div>
      </div>
    </label>
  );
}

export default Align;
