import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { faShop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Form, Modal, Table } from "react-bootstrap";

function ProductsConfig(props) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageProducts, setPageProducts] = useState(null);
  const [unCheckedProducts, setUnCheckedProducts] = useState(null);

  useEffect(() => {
    if (props.currentPage) {
      var localPageProducts = props.currentPage.productsIDs.split(",");
      setPageProducts(localPageProducts);
      if (localPageProducts.length > 0) {
        if (props.numberOfProductsPerPage > localPageProducts.length) {
          const unchecked = props.products.filter(
            (product) =>
              !props.checkedProducts.some(
                (checkedProduct) =>
                  checkedProduct.product_id === product.product_id
              )
          );
          setUnCheckedProducts(unchecked);
        }
      }
    }
  }, [props.currentPage]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const divide = () => {
    setIsModalOpen(false);
    props.divideProducts();
  };

  return (
    <div>
      <button
        className={`btn btn-primary rounded-0 ${
          props.currentCenterRender === "products" && "active"
        }`}
        onClick={openModal}
      >
        <FontAwesomeIcon icon={faShop} />
        &nbsp;{t("Products")}
      </button>
      <Modal
        className="products-modal"
        style={{
          zIndex: "999999",
        }}
        show={isModalOpen}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Products List")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>{t("Number of products in one page")}</Form.Label>
          <Form.Select
            value={props.numberOfProductsPerPage}
            aria-label="Default select example"
            onChange={(e) => props.setNumberOfProductsPerPage(e.target.value)}
          >
            <option value={2}>2</option>
            <option value={4}>4</option>
            <option value={6}>6</option>
            <option value={8}>8</option>
            <option value={9}>9</option>
          </Form.Select>
          <Table>
            <thead>
              <tr>
                <th>
                  <Form.Check
                    inline
                    label={t("check all")}
                    name="checkAll"
                    type="checkbox"
                    checked={props.isAllChecked}
                    onChange={props.handleProductsSelectAllChange}
                  />
                </th>
                <th>{t("product name")}</th>
                <th>{t("product image")}</th>
              </tr>
            </thead>
            <tbody className={pageProducts ? "pageProducts" : ""}>
              {props.isLoading ? (
                <tr>
                  <td colSpan={3}>Loading...</td>
                </tr>
              ) : props.products.length > 0 ? (
                props.products.map((product, i) => {
                  return (
                    product.en_name && (
                      <tr
                        key={i}
                        className={
                          pageProducts &&
                          pageProducts.includes(product.product_id.toString())
                            ? "exist"
                            : "notExist"
                        }
                      >
                        <td>
                          <Form.Check
                            inline
                            label=""
                            name="check"
                            type="checkbox"
                            className={`some ${props.checkedProducts.some(
                              (checkedProduct) =>
                                checkedProduct.product_id === product.product_id
                            )} ${props.checkedProducts[i]?.product_id}`}
                            checked={props.checkedProducts.some(
                              (checkedProduct) =>
                                checkedProduct.product_id === product.product_id
                            )}
                            id={`product-${product.product_id}`}
                            onChange={(e) =>
                              props.handleProductsCheckboxChange(e, product)
                            }
                          />
                          {i + 1}
                        </td>
                        <td>{props.decodeHtmlEntities(product.en_name)}</td>
                        <td>
                          <img
                            alt=""
                            style={{
                              width: "120px",
                              height: "120px",
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat",
                              backgroundImage: `url("https://sinaha.ae/image/${product.image}")`,
                            }}
                          />
                        </td>
                      </tr>
                    )
                  );
                })
              ) : (
                <tr>
                  <td colSpan={3}>
                    <Alert variant="danger">
                      No Products assign to this factory
                    </Alert>
                  </td>
                </tr>
              )}
              {unCheckedProducts &&
                (unCheckedProducts.length > 0 ? (
                  <>
                    <tr>
                      <td colSpan={3}>{t("Un Checked Products")}</td>
                    </tr>
                    {unCheckedProducts.map((product, i) => {
                      return (
                        product.en_name && (
                          <tr key={i}>
                            <td>
                              <Form.Check
                                inline
                                label=""
                                name="check"
                                type="checkbox"
                                className={`some ${props.checkedProducts.some(
                                  (checkedProduct) =>
                                    checkedProduct.product_id ===
                                    product.product_id
                                )} ${props.checkedProducts[i]?.product_id}`}
                                checked={props.checkedProducts.some(
                                  (checkedProduct) =>
                                    checkedProduct.product_id ===
                                    product.product_id
                                )}
                                id={`product-${product.product_id}`}
                                onChange={(e) =>
                                  props.handleProductsCheckboxChange(e, product)
                                }
                              />
                              {i + 1}
                            </td>
                            <td>{props.decodeHtmlEntities(product.en_name)}</td>
                            <td>
                              <img
                                alt=""
                                style={{
                                  backgroundImage: `url("https://sinaha.ae/image/${product.image}")`,
                                }}
                              />
                            </td>
                          </tr>
                        )
                      );
                    })}
                  </>
                ) : (
                  <></>
                ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={divide} variant="info">
            {t("Done")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ProductsConfig;
