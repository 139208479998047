import React, { useRef, useState, useEffect, useTransition } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API_BASE_URL_WITH_PORT } from "../config/url";
import Carousel from "react-bootstrap/Carousel";
import { useTranslation } from "react-i18next";
import { fetchAll } from "../api/catalogs";
import { fetchAllEvents } from "../api/events";
import Form from "react-bootstrap/Form";
import Catalog from "./catalog";
import Header from "./header";
import {
  faMagnifyingGlass,
  faArrowsRotate,
  faMapMarkerAlt,
  faPaintbrush,
  faShareNodes,
  faPaperPlane,
  faQrcode,
  faSortUp,
  faLeaf,
  faPhone,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

const useScrollAboutMark = (id) => {
  const aboutMarkRef = useRef(null);
  const aboutMarkExecuteScroll = () =>
    aboutMarkRef.current.scrollIntoView({ behavior: "smooth" });
  return [aboutMarkExecuteScroll, aboutMarkRef];
};

const useScrollAggregator = (id) => {
  const aggregatorRef = useRef(null);
  const aggregatorExecuteScroll = () =>
    aggregatorRef.current.scrollIntoView({ behavior: "smooth" });
  return [aggregatorExecuteScroll, aggregatorRef];
};

const useScrollContact = (id) => {
  const contactRef = useRef(null);
  const contactExecuteScroll = () =>
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  return [contactExecuteScroll, contactRef];
};

function Home() {
  const [aggregatorExecuteScroll, aggregatorRef] = useScrollAggregator();
  const [aboutMarkExecuteScroll, aboutMarkRef] = useScrollAboutMark();
  const [productsCatalogList, setProductsCatalogList] = useState([]);
  const [contactExecuteScroll, contactRef] = useScrollContact();
  const [currentType, setCurrentType] = useState("Factories");
  const [isPending, startTransition] = useTransition();
  const [openedBook, setOpenedBook] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    fetchDataFromAPI();
  }, [currentType]);

  useEffect(() => {
    fetchEventsDataFromAPI();
  }, []);

  const fetchEventsDataFromAPI = async () => {
    try {
      startTransition(async () => {
        const getEvents = await fetchAllEvents();
        setEvents(getEvents);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataFromAPI = async () => {
    try {
      startTransition(async () => {
        setLoading(true);
        var all = [];
        var landscapeCatalog = await fetchAll("Landscape", 5, 1, currentType);
        var portraitCatalog = await fetchAll("Portrait", 5, 1, currentType);
        const parsedPortraitCatalog = portraitCatalog?.data.map((catalog) => ({
          ...catalog,
          coverImage:
            catalog?.coverImage &&
            (catalog?.coverImage?.includes("data:image")
              ? catalog?.coverImage
              : `${API_BASE_URL_WITH_PORT}/${catalog?.coverImage?.replace(
                  /\\/g,
                  "/"
                )}`),
          coverData: catalog?.coverData && JSON.parse(catalog?.coverData),
          catalogSize: catalog?.catalogSize && JSON.parse(catalog?.catalogSize),
          profileData: catalog?.profileData && JSON.parse(catalog?.profileData),
          backCoverData:
            catalog?.backCoverData && JSON.parse(catalog?.backCoverData),
          coverShapesList: JSON.parse(catalog?.coverShapesList),
          profileShapesList: JSON.parse(catalog?.profileShapesList),
        }));
        const parsedLandscapeCatalog = landscapeCatalog?.data.map(
          (catalog) => ({
            ...catalog,
            coverImage:
              catalog?.coverImage &&
              (catalog?.coverImage?.includes("data:image")
                ? catalog?.coverImage
                : `${API_BASE_URL_WITH_PORT}/${catalog?.coverImage?.replace(
                    /\\/g,
                    "/"
                  )}`),
            coverData: catalog?.coverData && JSON.parse(catalog?.coverData),
            catalogSize:
              catalog?.catalogSize && JSON.parse(catalog?.catalogSize),
            profileData:
              catalog?.profileData && JSON.parse(catalog?.profileData),
            backCoverData:
              catalog?.backCoverData && JSON.parse(catalog?.backCoverData),
            coverShapesList: JSON.parse(catalog?.coverShapesList),
            profileShapesList: JSON.parse(catalog?.profileShapesList),
          })
        );
        all.push(parsedPortraitCatalog);
        all.push(parsedLandscapeCatalog);
        setProductsCatalogList(all);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const openBook = (book) => {
    setOpenedBook(book);
  };

  const closeBook = () => {
    setOpenedBook(null);
  };

  return (
    <div className="main">
      <Header
        aboutMarkExecuteScroll={aboutMarkExecuteScroll}
        aggregatorExecuteScroll={aggregatorExecuteScroll}
        contactExecuteScroll={contactExecuteScroll}
      />
      <section className="bg-header">
        <div className="container">
          <div className="row flex-lg-row-reverse align-items-center g-5">
            <div className="col-md-6">
              <Carousel fade={true} interval={2000}>
                <Carousel.Item>
                  <img
                    src="/assets/images/samples/Horizontal_Book_Mockup_6-removebg-preview.png"
                    className="d-block mx-lg-auto header-image"
                    alt="Bootstrap Themes"
                    width="700"
                    height="500"
                    loading="lazy"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src="/assets/images/samples/alseedawi_book_mockup_6-removebg-preview.png"
                    className="d-block mx-lg-auto header-image"
                    alt="Bootstrap Themes"
                    width="700"
                    height="500"
                    loading="lazy"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src="/assets/images/samples/hunter_Book_Mockup_6-removebg-preview.png"
                    className="d-block mx-lg-auto header-image"
                    alt="Bootstrap Themes"
                    width="700"
                    height="500"
                    loading="lazy"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src="/assets/images/samples/future_Book_Mockup_6-removebg-preview.png"
                    className="d-block mx-lg-auto header-image"
                    alt="Bootstrap Themes"
                    width="700"
                    height="500"
                    loading="lazy"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src="/assets/images/samples/white_Book_Mockup_6-removebg-preview.png"
                    className="d-block mx-lg-auto header-image"
                    alt="Bootstrap Themes"
                    width="700"
                    height="500"
                    loading="lazy"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src="/assets/images/samples/national_Book_Mockup_6-removebg-preview.png"
                    className="d-block mx-lg-auto header-image"
                    alt="Bootstrap Themes"
                    width="700"
                    height="500"
                    loading="lazy"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
            <div className="col-md-6">
              <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">
                {t("headerTitle")}
              </h1>
              <p className="lead">{t("headerDescription")}</p>
              <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                <button
                  onClick={aboutMarkExecuteScroll}
                  type="button"
                  className="btn btn-primary btn-lg px-4 me-md-2"
                >
                  {t("browse")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="features" ref={aboutMarkRef}>
        <div className="container">
          <h2 className="section-title">{t("featureTitle")}</h2>
          <div className="row">
            <div className="col-md-10">
              <p>{t("featureDescription")}</p>
              <p>{t("featureDescription2")}</p>
              <p>{t("featureDescription3")}</p>
            </div>
            <div className="col-md-2">
              <img
                src="/assets/images/sinaha-smart-catalog-icon.png"
                className="img-fluid h-auto about-icon"
                alt="Bootstrap Themes"
                width="700"
                height="500"
                loading="lazy"
              />
            </div>
          </div>
          <h2 className="section-title mt-3">
            {t("Request for a quote while browsing the smart catalog")}
          </h2>
          <div className="row">
            <div className="col-md-12">
              <p>{t("rfq-description-1")}</p>
              <p>{t("rfq-description-2")}</p>
              <div className="mb-5">
                <a
                  href="https://sinaha.ae/en/sinahaqt-rfq"
                  target="_blank"
                  className="btn btn-primary btn-lg px-4 me-md-2"
                >
                  {t("Go to Sinaha QT")}
                </a>
              </div>
            </div>
          </div>
          <h2 className="section-title">{t("features")}</h2>
          <div className="row g-5 text-center">
            <div className="col-lg-2">
              <div className="bd-placeholder-img rounded-circle icon">
                <FontAwesomeIcon icon={faPaintbrush} />
              </div>
              <h4 className="fw-normal">{t("design")}</h4>
              <p>{t("designDescription")}</p>
            </div>
            <div className="col-lg-2">
              <div className="bd-placeholder-img rounded-circle icon">
                <FontAwesomeIcon icon={faArrowsRotate} />
              </div>
              <h4 className="fw-normal">{t("update")}</h4>
              <p>{t("updateDescription")}</p>
            </div>
            <div className="col-lg-2">
              <div className="bd-placeholder-img rounded-circle icon">
                <FontAwesomeIcon icon={faShareNodes} />
              </div>
              <h4 className="fw-normal">{t("share")}</h4>
              <p>{t("shareDescription")}</p>
            </div>
            <div className="col-lg-2">
              <div className="bd-placeholder-img rounded-circle icon">
                <FontAwesomeIcon icon={faQrcode} />
              </div>
              <h4 className="fw-normal">{t("qrCode")}</h4>
              <p>{t("qrCodeDescription")}</p>
            </div>
            <div className="col-lg-2">
              <div className="bd-placeholder-img rounded-circle icon">
                <FontAwesomeIcon icon={faLeaf} />
              </div>
              <h4 className="fw-normal">{t("ecoFreindly")}</h4>
              <p>{t("ecoFreindlyDescription")}</p>
            </div>
            <div className="col-lg-2">
              <div className="bd-placeholder-img rounded-circle icon">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </div>
              <h4 className="fw-normal">{t("search")}</h4>
              <p>{t("searchDescription")}</p>
            </div>
          </div>
        </div>
      </section>

      <hr className="featurette-divider" />

      <section className="events">
        <div className="container">
          <h2 className="section-title">{t("Events")}</h2>
          <div className="row mt-5">
            {events.length > 0 &&
              events.map((event, index) => (
                <div className="col-md-6 text-center event-block">
                  <a href={`/event/${event.slug}`} target="_blank">
                    <img
                      src={`/assets/images/${event.slug}/${event.headerImage}`}
                      className="w-100 events-image"
                    />
                    <h3 className="mt-1">
                      {" "}
                      {i18n.language === "ar"
                        ? event.arTitle
                        : event.enTitle}{" "}
                    </h3>
                  </a>
                </div>
              ))}
          </div>
        </div>
      </section>

      <hr className="featurette-divider" />

      <section className="marketing aggregator home" ref={aggregatorRef}>
        <div className="container">
          <div className="d-flex justify-content-between">
            <h2 className="section-title">{t("aggregator")}</h2>
            <Form.Group controlId="formBasicSelect">
              <Form.Label>{t("Catalog Type")}</Form.Label>
              <Form.Select
                value={currentType}
                aria-label={currentType}
                onChange={(e) => setCurrentType(e.target.value)}
              >
                <option value="Factories">{t("Factories")}</option>
                <option value="Categories">{t("Categories")}</option>
              </Form.Select>
            </Form.Group>
          </div>

          {isLoading ? (
            <h6>{t("loading")} ...</h6>
          ) : (
            <>
              {productsCatalogList.map((products, i) => {
                return (
                  products.length > 0 && (
                    <div className="shelf-books" key={i}>
                      <div className="catalgos">
                        <div className="overlay-bg"></div>
                        <div className="row g-0">
                          {products.map((productsCatalog, j) => {
                            return (
                              <div className="col-md-2" key={j}>
                                <Catalog
                                  index={j}
                                  source="home"
                                  book={openedBook}
                                  openBook={openBook}
                                  closeBook={closeBook}
                                  slug={productsCatalog?.slug}
                                  catalogId={productsCatalog?.id}
                                  productsCatalog={productsCatalog}
                                  coverData={productsCatalog?.coverData}
                                  profileData={productsCatalog?.profileData}
                                  orientation={productsCatalog?.orientation}
                                  backCoverData={productsCatalog?.backCoverData}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
              <a
                href={`/allCatalog/Factories/All/empty`}
                type="button"
                className="btn btn-primary btn-lg px-4 me-md-2 all-catalogs"
              >
                {t("All")}
              </a>
            </>
          )}
        </div>
      </section>

      <hr className="featurette-divider" />

      <section className="contact" ref={contactRef}>
        <div className="container">
          <h2 className="section-title">{t("contact")}</h2>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4 my-5">
              <a href="tel:+97125558989">
                <div className="card border-0">
                  <div className="card-body text-center">
                    <span className="fs-1">
                      <FontAwesomeIcon icon={faPhone} />
                    </span>
                    <h4 className="text-uppercase mb-3">{t("callus")}</h4>
                    <p>+97125558989</p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 my-5">
              <a
                href="https://maps.app.goo.gl/e9QiYfq8D4mSppRC8"
                target="_blank"
              >
                <div className="card border-0">
                  <div className="card-body text-center">
                    <span className="fs-1">
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                    </span>
                    <h4 className="text-uppercase mb-3">{t("address")}</h4>
                    <address>{t("addressContent")}</address>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 my-5">
              <a href="mailto:info@sinaha.ae">
                <div className="card border-0">
                  <div className="card-body text-center">
                    <span className="fs-1">
                      <FontAwesomeIcon icon={faGlobe} />
                    </span>
                    <h4 className="text-uppercase mb-3">{t("email")}</h4>
                    <p>info@sinaha.ae</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      <footer className="w-100 py-4 flex-shrink-0 bg-header-dark">
        <div className="container py-4">
          <div className="row gy-4 gx-5">
            <div className="col-lg-4 col-md-6">
              <h5 className="h1 text-white">
                <img
                  src="/assets/images/sinaha-smart-catalog-white.png"
                  className="w-100 h-auto"
                  alt="Bootstrap Themes"
                  width="700"
                  height="500"
                  loading="lazy"
                />
              </h5>
              <p className="small text-white mb-0">
                &copy; 2024 {t("sinahaPlatform")}
              </p>
            </div>
            <div className="col-lg-2 col-md-6">
              <h5 className="text-white mb-3">{t("quickLinks")}</h5>
              <ul className="list-unstyled text-white p-0">
                <li className="mb-2">
                  <a href="https://sinaha.ae/en/invest-in-uae" target="_blank">
                    {t("investInUAE")}
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="https://sinahaqt.sinaha.ae/forms/ticket?styled=1&with_logo=1"
                    target="_blank"
                  >
                    {t("support")}
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-6">
              <h5 className="text-white mb-3">{t("quickLinks")}</h5>
              <ul className="list-unstyled text-white p-0">
                <li className="mb-2">
                  <a href="https://madeintheemirates.sinaha.ae" target="_blank">
                    {t("madeInTheEmirates")}
                  </a>
                </li>
                <li className="mb-2">
                  <a href="https://sinaha.ae" target="_blank">
                    {t("sinahaPlatform")}
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="col-lg-4 col-md-6"
              style={
                i18n.language === "ar"
                  ? {
                      borderRight: "1px solid #fff",
                    }
                  : {
                      borderLeft: "1px solid #fff",
                    }
              }
            >
              <h5 className="text-white mb-3">{t("newsletter")}</h5>
              <p className="small text-white">{t("newsletterDescription")}</p>
              <a href="#" className="go-up">
                <FontAwesomeIcon icon={faSortUp} />
              </a>
              <form action="#">
                <div className="input-group mb-3">
                  <input
                    className={
                      (i18n.language === "ar" && " border-radius-ar-2 ") +
                      " form-control "
                    }
                    type="text"
                    placeholder={t("enterEmail")}
                    aria-label={t("enterEmail")}
                    aria-describedby="button-addon2"
                  />
                  <button
                    className={
                      (i18n.language === "ar" && "border-radius-ar ") +
                      " btn btn-success "
                    }
                    id="button-addon2"
                    type="button"
                  >
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Home;
