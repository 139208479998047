import Home from "./components/home";
import Event from "./components/event";
import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import CreateCatalog from "./components/create";
import { Routes, Route } from "react-router-dom";
import AllCatalog from "./components/allCatalog";
import ViewCatalog from "./components/viewCatalog";

function App() {
  const { i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(["lang"]);
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    var lang = queryParameters.has("lang") ? queryParameters.get("lang") : "";
    if (lang !== "") {
      i18n.changeLanguage(lang);
      setCookie("lang", lang);
    } else {
      lang = cookies.lang;
      i18n.changeLanguage(lang);
    }
    if ((lang !== "" && lang === "ar") || i18n.language === "ar") {
      document.body.classList.add("direction-rtl");
    } else {
      document.body.classList.remove("direction-rtl");
    }
  }, []);

  const products = [
    { id: 1, name: "Product A" },
    { id: 2, name: "Product B" },
    { id: 3, name: "Product C" },
    { id: 4, name: "Product D" },
    { id: 5, name: "Product E" },
    { id: 6, name: "Product F" },
    { id: 7, name: "Product G" },
    { id: 8, name: "Product H" },
    { id: 9, name: "Product I" },
    { id: 10, name: "Product J" },
    { id: 11, name: "Product K" },
    { id: 12, name: "Product L" },
    { id: 13, name: "Product M" },
    { id: 14, name: "Product N" },
    { id: 15, name: "Product O" },
  ];

  return (
    <Routes>
      <Route path="/" exact={true} element={<Home />} />
      <Route path="/event/:slug" exact={true} element={<Event />} />
      <Route path="/catalog/:slug" exact={true} element={<ViewCatalog />} />
      <Route path="/create/:urlType/:urlID" exact={true} element={<CreateCatalog />} />
      <Route path="/allCatalog/:type/:category/:keyword" exact={true} element={<AllCatalog />} />
    </Routes>
  );
}

export default App;
