import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import React from "react";

function CatalogConfig(props) {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-center align-items-center h-100 w-100 selected">
      <div className="item">
        <Form.Label>{t("Select main color")}</Form.Label>
        <Form.Control
          className="w-100"
          type="color"
          id="mainColor"
          value={props.mainColor}
          onChange={(e) => props.handleChangeMainColor(e.target.value)}
        />
      </div>
      {props.designTemplate === "customize" && (
        <div className="item">
          <Form.Label>{t("shapes list")}</Form.Label>
          <div className="d-flex">
            <div
              className="line"
              onClick={() => props.addNewShape("line", "cover")}
            ></div>
            <div
              className="circle"
              onClick={() => props.addNewShape("circle", "cover")}
            ></div>
            <div
              className="square"
              onClick={() => props.addNewShape("square", "cover")}
            ></div>
            <div
              className="triangle"
              onClick={() => props.addNewShape("triangle", "cover")}
            ></div>
          </div>
        </div>
      )}
      <div className="item">
        <Form.Label>{t("Select cover background")}</Form.Label>
        <div className="d-flex justify-content-between align-items-center">
          <Form.Control
            type="file"
            id="coverImage"
            accept="image/*"
            onChange={props.handleCoverImageChange}
            style={{ marginRight: "5px", height: "38px", width: "104px" }}
          />
          <div className="position-relative">
            {props.coverImagePreviewUrl && (
              <>
                <button
                  onClick={props.deleteCoverImage}
                  className="delete-cover"
                >
                  <FontAwesomeIcon icon={faX} />
                </button>
                <img
                  src={props.coverImagePreviewUrl}
                  alt="Preview"
                  style={{
                    width: "100%",
                    height: "70px",
                    objectFit: "contain",
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
      {props.designTemplate === "customize" && (
        <>
          <div className="item">
            <Form.Label>{t("background size")}</Form.Label>
            <select
              style={{ width: "75px" }}
              className="form-control"
              value={props.coverImageSize}
              onChange={(e) => props.setCoverImageSize(e.target.value)}
            >
              <option value=""></option>
              <option value="auto">{t("auto")}</option>
              <option value="100% 100%">{t("fit")}</option>
              <option value="contain">{t("contain")}</option>
              <option value="cover">{t("cover")}</option>
            </select>
          </div>
          <div className="item">
            <Form.Label>{t("background position")}</Form.Label>
            <select
              style={{ width: "75px" }}
              className="form-control"
              value={props.coverImagePosition}
              onChange={(e) => props.setCoverImagePosition(e.target.value)}
            >
              <option value=""></option>
              <option value="center">{t("center")}</option>
              <option value="top">{t("top")}</option>
              <option value="bottom">{t("bottom")}</option>
              <option value="left">{t("left")}</option>
              <option value="right">{t("right")}</option>
            </select>
          </div>
          <div className="item">
            <Form.Label>{t("background opacity")}</Form.Label>
            <input
              type="number"
              min={0}
              max={100}
              className="form-control"
              value={props.coverImageOpacity}
              onChange={(e) => props.setCoverImageOpacity(e.target.value)}
            />
          </div>
        </>
      )}
      <div className="item">
        <Form.Label>{t("Select design")}</Form.Label>
        <div>
          <Form.Check
            inline
            label={t("design") + " 1"}
            value="temp-1"
            name="designTemplate"
            type="radio"
            checked={props.designTemplate === "temp-1"}
            onChange={(e) => {
              props.setDesignTemplate(e.target.value);
            }}
          />
          <Form.Check
            inline
            label={t("design") + " 2"}
            value="temp-2"
            name="designTemplate"
            type="radio"
            checked={props.designTemplate === "temp-2"}
            onChange={(e) => {
              props.setDesignTemplate(e.target.value);
            }}
          />
          <Form.Check
            inline
            type="radio"
            value="customize"
            name="designTemplate"
            label={t("customize")}
            checked={props.designTemplate === "customize"}
            onChange={(e) => props.setDesignTemplate(e.target.value)}
          />
        </div>
      </div>
      <div className="item">
        <Form.Label>{t("orientation")}</Form.Label>
        <div>
          <Form.Check
            inline
            label={t("Portrait")}
            value="Portrait"
            name="orientation"
            type="radio"
            checked={props.orientation === "Portrait"}
            onChange={(e) => {
              props.setOrientation(e.target.value);
            }}
          />
          <Form.Check
            inline
            label={t("Landscape")}
            value="Landscape"
            name="orientation"
            type="radio"
            checked={props.orientation === "Landscape"}
            onChange={(e) => {
              props.setOrientation(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default CatalogConfig;
