import $ from "jquery";
import "turn.js";
import Page from "./page";
import { jsPDF } from "jspdf";
import Profile from "./profile";
import Cover from "./covers/cover";
import BackCover from "./backCover";
import html2canvas from "html2canvas";
import PageContent from "./pageContent";
import ReactDOM from "react-dom/client";
import { useCookies } from "react-cookie";
import {
  faArrowUpRightFromSquare,
  faStepBackward,
  faStepForward,
  faSearchMinus,
  faSearchPlus,
  faArrowRight,
  faArrowLeft,
  faShareAlt,
  faLanguage,
  faEnvelope,
  faSearch,
  faClose,
  faUndo,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faLinkedin,
  faWhatsapp,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { fetchCatalogPageById } from "../../api/catalogs";
import React, { useRef, useEffect, useState } from "react";
import { Nav, Form, Button, NavDropdown, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingOverlay from "../loadingOverlay";

// Font Names
const fontNames = [
  { "": "" },
  { Cairo: '"Cairo", sans-serif' },
  { Georgia: '"Georgia", serif' },
  { Papyrus: "Papyrus, fantasy" },
  { Tahoma: "Tahoma, Geneva, sans-serif" },
  { Arial: "Arial, Helvetica, sans-serif" },
  { Verdana: "Verdana, Geneva, sans-serif" },
  { Impact: "Impact, Charcoal, sans-serif" },
  { Helvetica: "Helvetica, Arial, sans-serif" },
  { Courier: '"Courier New", Courier, monospace' },
  { Lucida: '"Lucida Console", Monaco, monospace' },
  { Consolas: 'Consolas, "Courier New", monospace' },
  { "Brush Script MT": '"Brush Script MT", cursive' },
  { Trebuchet: '"Trebuchet MS", Helvetica, sans-serif' },
  { "Times New Roman": '"Times New Roman", Times, serif' },
  { "Comic Sans MS": '"Comic Sans MS", cursive, sans-serif' },
  { Palatino: '"Palatino Linotype", "Book Antiqua", Palatino, serif' },
];

const Catalog = ({
  slug,
  book,
  source,
  closeBook,
  catalogId,
  coverData,
  profileData,
  setCoverData,
  navClassName,
  backCoverData,
  setProfileData,
  productsCatalog,
  setBackCoverData,
  handleChangeQrCodeSize,
  handleChangePageImageSize,
  handleChangeQrCodePosition,
  handleChangePageImagePosition,
}) => {
  const bookRef = useRef(null);
  const bookCoverRef = useRef(null);
  let navigate = useNavigate();
  const searchRef = useRef(null);
  const { t, i18n } = useTranslation();
  const [currentX, setCurrentX] = useState(null);
  const [pagesCount, setPagesCount] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [cookies, setCookie] = useCookies(["lang"]);
  const pagesCountWithoutCovers =
    productsCatalog?.pagesCount % 2 === 0
      ? productsCatalog?.pagesCount + 1
      : productsCatalog?.pagesCount;

  let newPosX = 0,
    newPosY = 0,
    startPosX = 0,
    startPosY = 0;

  var zoomLevel = 100;
  var maxZoom = 220;
  var minZoom = 80;

  useEffect(() => {
    setPagesCount(productsCatalog?.pagesCount);
    const updateBookSize = () => {
      const localIsMobile = window.innerWidth <= 768;
      setIsMobile(localIsMobile);
      var bookWidth = productsCatalog?.catalogSize?.width,
        bookHeight = productsCatalog?.catalogSize?.height;
      if (source === "catalogPage") {
        bookWidth =
          window.innerWidth > 768 ? bookWidth * 2 : window.innerWidth - 40;
        bookHeight = window.innerWidth > 768 ? bookHeight : bookWidth * 1.3;
      }

      if (source === "home" || source === "allCatalog") {
        bookWidth = window.innerWidth > 768 ? bookWidth * 2 : bookWidth;
      }

      $(bookRef.current).turn({
        width: bookWidth,
        autoCenter: true,
        height: bookHeight,
        display: localIsMobile ? "single" : "double",
      });
      $(bookRef.current).on("turning", (event, page, view) => {
        loadPageContent(page);
      });
      $(".turn-page-wrapper[page='1']").addClass("first-page");
    };

    if (source === "catalogPage") {
      document.addEventListener("mousedown", function (e) {
        const target = e.target.closest(".moved-object");
        if (target) {
          e.preventDefault();
          startPosX = e.clientX;
          startPosY = e.clientY;
          document.addEventListener("mousemove", mouseMove);
          document.el = target;
          document.addEventListener("mouseup", function () {
            document.removeEventListener("mousemove", mouseMove);
          });
        }
      });
    }
    updateBookSize();
  }, []);

  const loadPageContent = (pageNumber) => {
    var pageNumberWithoutCoverAndProfile = pageNumber - 1;
    if (productsCatalog?.pagesCount === 1 && pageNumber === 2) {
      fetchPageContent(pageNumberWithoutCoverAndProfile);
    } else if (
      parseInt(pageNumber) <= productsCatalog?.pagesCount &&
      pageNumber !== 1
    ) {
      fetchPageContent(pageNumberWithoutCoverAndProfile);
      fetchPageContent(pageNumberWithoutCoverAndProfile + 1);
      if (productsCatalog?.pagesCount > 2) {
        fetchPageContent(pageNumberWithoutCoverAndProfile + 2);
      }
    }
  };

  const fetchPageContent = (pageNumber) => {
    if (
      parseInt(pageNumber) <= productsCatalog?.pagesCount ||
      (productsCatalog?.pagesCount === 1 && pageNumber === 2)
    ) {
      const pages = productsCatalog?.pages;
      if (productsCatalog?.mode && productsCatalog?.mode === "preview") {
        var pageIndexWithoutCovers = pageNumber - 1;
        const page = pages.filter(
          (_, index) => index === pageIndexWithoutCovers
        )[0];
        var element = $(".catalog-cover.active .p" + (pageNumber + 2));
        if (
          element.length &&
          $(".catalog-cover.active .p" + (pageNumber + 2) + " .products-list")
            .length === 0
        ) {
          if (page) {
            const rootElement = ReactDOM.createRoot(element[0]);
            rootElement.render(
              <PageContent
                page={page}
                source={source}
                mode="rendered"
                pagesCount={pages?.length}
                productsCatalog={productsCatalog}
                pageNumber={pageIndexWithoutCovers + 1}
                catalogSize={productsCatalog?.catalogSize}
                handleChangeQrCodeSize={handleChangeQrCodeSize}
                handleChangePageImageSize={handleChangePageImageSize}
                handleChangeQrCodePosition={handleChangeQrCodePosition}
                handleChangePageImagePosition={handleChangePageImagePosition}
              />
            );
          }
        }
      } else {
        fetchCatalogPageById(productsCatalog?.id, pageNumber)
          .then((response) => {
            if (response) {
              const page = {
                ...response,
                productsList: response.productsList
                  ? JSON.parse(response.productsList)
                  : [],
                imagesListGridSystem: response.imagesListGridSystem
                  ? response.imagesListGridSystem
                  : "customize",
                imagesList: response.imagesList
                  ? JSON.parse(response.imagesList)
                  : [{ src: null, alt: null, image: null, id: "1" }],
                shapesList: response.shapesList
                  ? JSON.parse(response.shapesList)
                  : [],
                textsList: response.textsList
                  ? JSON.parse(response.textsList)
                  : [],
                productsListGridSystem: response.productsListGridSystem
                  ? JSON.parse(response.productsListGridSystem)
                  : { cols: "col-4", rows: 3 },
              };
              var element = $(".catalog-cover.active .p" + (pageNumber + 2));
              if (
                element.length &&
                $(
                  ".catalog-cover.active .p" +
                    (pageNumber + 2) +
                    " .products-list"
                ).length === 0
              ) {
                const rootElement = ReactDOM.createRoot(element[0]);
                rootElement.render(
                  <PageContent
                    page={page}
                    source={source}
                    mode="rendered"
                    pageNumber={pageNumber}
                    productsCatalog={productsCatalog}
                    pagesCount={productsCatalog?.pagesCount}
                    catalogSize={productsCatalog?.catalogSize}
                  />
                );
              }
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  };

  const mouseMove = (e) => {
    newPosX = startPosX - e.clientX;
    newPosY = startPosY - e.clientY;
    startPosX = e.clientX;
    startPosY = e.clientY;
    var el = e.currentTarget.el;
    el.style.top = el.offsetTop - newPosY + "px";
    el.style.left = el.offsetLeft - newPosX + "px";
  };

  const open = (id) => {
    if (productsCatalog?.mode && productsCatalog?.mode === "preview") {
    } else {
      navigate(`/catalog/${slug}`);
      // if (isMobile) {
      //   navigate(`/catalog/${slug}`);
      // } else if (book === null) {
      //   openBook(id);
      //   var x = 140 + 216 * index;
      //   if (globalOrientation === "Portrait") {
      //     x = x - 234;
      //   }
      //   setCurrentX(x);
      //   setTimeout(() => {
      //     $(
      //       "<style>.catalog-cover.active .page-style{display: block;}</style>"
      //     ).appendTo(document.head);
      //     var container = $("html,body");
      //     var scrollTo = $(".catalog-control.active");
      //     container.scrollTop(scrollTo.offset().top);
      //     let start, end;
      //     if (pagesCount < 7) {
      //       start = 3;
      //       end = pagesCount;
      //     } else {
      //       start = 3;
      //       end = 7;
      //     }
      //     renderPages(start, end);
      //   }, 1000);
      //   $(".overlay-bg").show();
      // }
    }
  };

  const close = () => {
    closeBook();
    setCurrentX(null);
    $(".overlay-bg").hide();
    $(bookRef.current).turn("page", 1);
    $(".catalog-cover").removeAttr("style");
    $(".turn-page-wrapper[page='1']").addClass("first-page");
    $(".shelf-books .catalgos .catalog-cover").removeAttr("style");
    $(".shelf-books .catalgos .catalog-cover .book-container").removeAttr(
      "style"
    );
    $(
      "<style>.catalog-cover.active .page-style{display: none;}</style>"
    ).appendTo(document.head);
  };

  const last = () => {
    $(".book-container-customize.active").turn("page", pagesCount);
  };

  const next = () => {
    $(".book-container-customize.active").turn("next");
  };

  const share = (website) => {
    var currentUrl = window.location.href;
    switch (website) {
      case "whatsapp":
        window.open(
          "https://wa.me/?text=" + encodeURIComponent(currentUrl),
          "_blank"
        );
        break;
      case "twitter":
        window.open(
          "https://twitter.com/share?url=" + encodeURIComponent(currentUrl),
          "_blank"
        );
        break;
      case "facebook":
        window.open(
          "https://www.facebook.com/sharer/sharer.php?u=" +
            encodeURIComponent(currentUrl),
          "_blank"
        );
        break;
      case "email":
        window.open(
          "mailto:?subject=Product Catalog&amp;body=" +
            encodeURIComponent(currentUrl),
          "_blank"
        );
        break;
      case "linkedin":
        window.open(
          "https://www.linkedin.com/shareArticle?mini=true&url=" +
            encodeURIComponent(currentUrl),
          "_blank"
        );
        break;
      default:
        return "";
    }
  };

  const first = () => {
    $(".book-container-customize.active").turn("page", 1);
  };

  const zoomIn = () => {
    zoomPage(10, $(this));
  };

  const zoomOut = () => {
    zoomPage(-10, $(this));
  };

  const previous = () => {
    $(".book-container-customize.active").turn("previous");
  };

  const zoomReset = () => {
    zoomPage(0, $(this));
  };

  const zoomPage = (step, trigger) => {
    if (
      (zoomLevel >= maxZoom && step > 0) ||
      (zoomLevel <= minZoom && step < 0)
    )
      return;

    if (step === 0) {
      zoomLevel = 100;
      $(".catalog-cover").removeClass("moved-object");
      $(".catalog-cover").css({
        top: "unset",
        left: "unset",
      });
    } else {
      $(".catalog-cover.active").addClass("moved-object");
      zoomLevel = zoomLevel + step;
    }

    $(".catalog-cover.active .book-container").css({
      transform: "scale(" + zoomLevel / 100 + ")",
      transformOrigin: "50% 50%",
    });

    if (zoomLevel > 100)
      $(".catalog-cover.active .book-container").css({
        width: "100%",
      });
    else
      $(".catalog-cover.active .book-container").css({
        width: "100%",
      });

    if (zoomLevel >= maxZoom || zoomLevel <= minZoom)
      trigger.addClass("disabled");
    else trigger.parents("ul").find(".disabled").removeClass("disabled");
    if (zoomLevel !== 100) $("#zoomReset").removeClass("disabled");
    else $("#zoomReset").addClass("disabled");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      search();
    }
  };

  const search = () => {
    var searchInput = searchRef.current.value;
    $(".item-title").each(function () {
      if (searchInput) {
        if ($(this).text().toLowerCase().includes(searchInput.toLowerCase())) {
          $(this)
            .parent()
            .parent()
            .css("box-shadow", `0px 0px 8px 1px ${productsCatalog?.mainColor}`);
          var page = $(this)
            .parent()
            .parent()
            .parent()
            .parent()
            .parent()
            .parent()
            .attr("data-page");
          $(bookRef.current).turn("page", page);
        } else {
          $(this).parent().parent().css("box-shadow", "");
        }
      } else {
        $(this).parent().parent().css("box-shadow", "");
      }
    });
  };

  const changeLanguage = (event, lang) => {
    i18n.changeLanguage(lang);
    setCookie("lang", lang);
    if ((lang !== "" && lang === "ar") || i18n.language === "ar") {
      document.body.classList.add("direction-rtl");
    } else {
      document.body.classList.remove("direction-rtl");
    }
  };

  // const generatePDF = async (elementToPrintId) => {
  //   const element = document.getElementById(elementToPrintId);
  //   if (!element) {
  //     throw new Error(`Element with id ${elementToPrintId} not found`);
  //   }
  //   const canvas = await html2canvas(element, { scale: 2 });
  //   const data = canvas.toDataURL("image/png");
  //   const pdf = new jsPDF({
  //     orientation: productsCatalog?.orientation,
  //     unit: "mm",
  //     format: [productsCatalog?.catalogSize?.width, productsCatalog?.catalogSize?.height],
  //   });
  //   const imgProperties = pdf.getImageProperties(data);
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

  //   pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
  //   pdf.save("print.pdf");
  // };

  // const handleGeneratePdf = () => {
  //   // $(`#catalog-pdf-${productsCatalog?.id}`).show();
  //   const doc = new jsPDF({
  //     orientation: productsCatalog?.orientation,
  //     // format: 'a4',
  //     format: [
  //       productsCatalog?.catalogSize?.width,
  //       productsCatalog?.catalogSize?.height,
  //     ],
  //     unit: "px",
  //   });

  //   // Adding the fonts.
  //   // doc.setFont("Inter-Regular", "normal");

  //   doc.html(bookCoverRef.current, {
  //     async callback(doc) {
  //       await doc.save("document");
  //       // $(`#catalog-pdf-${productsCatalog?.id}`).hide();
  //     },
  //   });
  // };

  // const pageRefs = useRef([]);

  // const addToRefs = (el) => {
  //   if (el && !pageRefs.current.includes(el)) {
  //     pageRefs.current.push(el);
  //   }
  // };
  const [showPDF, setShowPDF] = useState(false);

  const handleClose = () => setShowPDF(false);
  const handleShow = () => {
    setShowPDF(true);
    preparePDF();
  };
  const [pdfPagesContent, setPdfPagesContent] = useState([]);
  const [jsPDFObject, setJsPDFObject] = useState(null);

  const preparePDF = async () => {
    if (pdfPagesContent.length === 0) {
      const pdf = new jsPDF({
        orientation: productsCatalog?.orientation,
        format: [
          productsCatalog?.catalogSize?.width,
          productsCatalog?.catalogSize?.height,
        ],
        unit: "px",
      });
      // let finished = false;
      // let LocalPdfPagesContent = [];

      for (let pageNumber = 1; pageNumber <= pagesCount; pageNumber++) {
        // const element = array[index];

        const response = await fetchCatalogPageById(
          productsCatalog?.id,
          pageNumber
        );
        // console.log("response", response);
        if (response) {
          const pdfPage = {
            ...response,
            productsList: response.productsList
              ? JSON.parse(response.productsList)
              : [],
            imagesListGridSystem: response.imagesListGridSystem
              ? response.imagesListGridSystem
              : "customize",
            imagesList: response.imagesList
              ? JSON.parse(response.imagesList)
              : [{ src: null, alt: null, image: null, id: "1" }],
            shapesList: response.shapesList
              ? JSON.parse(response.shapesList)
              : [],
            textsList: response.textsList ? JSON.parse(response.textsList) : [],
            productsListGridSystem: response.productsListGridSystem
              ? JSON.parse(response.productsListGridSystem)
              : { cols: "col-4", rows: 3 },
          };
          setPdfPagesContent((prevItems) => [...prevItems, pdfPage]); // Push new item into state
          // setPdfPagesContent({
          //   ...pdfPagesContent,
          //   pdfPage,
          // });
          // LocalPdfPagesContent.push(pdfPage);
          // finished = true;
        }
        // .then(async (response) => {
        // if (response) {
        //   const page = {
        //     ...response,
        //     productsList: response.productsList
        //       ? JSON.parse(response.productsList)
        //       : [],
        //     imagesListGridSystem: response.imagesListGridSystem
        //       ? response.imagesListGridSystem
        //       : "customize",
        //     imagesList: response.imagesList
        //       ? JSON.parse(response.imagesList)
        //       : [{ src: null, alt: null, image: null, id: "1" }],
        //     shapesList: response.shapesList
        //       ? JSON.parse(response.shapesList)
        //       : [],
        //     textsList: response.textsList ? JSON.parse(response.textsList) : [],
        //     productsListGridSystem: response.productsListGridSystem
        //       ? JSON.parse(response.productsListGridSystem)
        //       : { cols: "col-4", rows: 3 },
        //   };
        //   var element = $(`#catalog-${productsCatalog?.id}-page-${pageNumber}`);
        //   console.log("id", `#catalog-${productsCatalog?.id}-page-${pageNumber}`);
        //   console.log("element", element);
        //   if (
        //     element.length &&
        //     $(".catalog-cover.active .p" + (pageNumber + 2) + " .products-list")
        //       .length === 0
        //   ) {
        //     const rootElement = ReactDOM.createRoot(element[0]);
        //     rootElement.render(
        //       <PageContent
        //         page={page}
        //         source={source}
        //         mode="rendered"
        //         pageNumber={pageNumber}
        //         productsCatalog={productsCatalog}
        //         pagesCount={productsCatalog?.pagesCount}
        //         catalogSize={productsCatalog?.catalogSize}
        //       />
        //     );
        //     setTimeout(async () => {
        //       const canvas = await html2canvas(rootElement, {
        //         scale: 3, // Higher scale for better quality
        //         useCORS: true, // Handle cross-origin images
        //         backgroundColor: null, // Preserve background color
        //       });

        //       const imgData = canvas.toDataURL("image/png");
        //       const imgWidth = productsCatalog?.catalogSize?.width; // A4 width in mm
        //       const imgHeight = productsCatalog?.catalogSize?.height; // Maintain aspect ratio

        //       if (pageNumber > 1) pdf.addPage(); // Add a new page for every div after the first
        //       pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        //     }, 5000);
        //   }
        // }
        // })
        // .catch((e) => {
        //   console.log(e);
        // });
      }
      setJsPDFObject(pdf);
    // setTimeout(async () => {
    // pageRefs.current.length
    // if (finished && LocalPdfPagesContent?.length > 0) {
    //   console.log("pagesCount.length + 3", pagesCount + 3);
    //   for (let i = 0; i < pagesCount + 3; i++) {
    //     const pageId = `pdf-page-${i}`; // Unique ID for each page
    //     console.log("pageId", pageId);
    //     const pageElement = document.getElementById(pageId);
    //     // pageRefs.current[i]
    //     const canvas = await html2canvas(pageElement, {
    //       // scale: 2, // Increase scale for better quality
    //       scale: 3, // Higher scale for better quality
    //       useCORS: true, // Handle cross-origin images
    //       backgroundColor: null, // Preserve background color
    //     });

    //     const imgData = canvas.toDataURL("image/png");
    //     const imgWidth = productsCatalog?.catalogSize?.width; // A4 width in mm
    //     const imgHeight = productsCatalog?.catalogSize?.height; // Maintain aspect ratio

    //     if (i > 0) pdf.addPage(); // Add a new page for every div after the first
    //     pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
    //   }
    //   setJsPDFObject(pdf);
    //   // pdf.save("multi-page-export.pdf");
    // }
  }

    // }, 5000);
  };
  const [loadingPDF, setLoadingPDF] = useState(false);

  const exportPDF = async () => {
    if (pdfPagesContent?.length > 0) {
      setLoadingPDF(true); // Show overlay
      // console.log("pagesCount.length + 3", pagesCount + 3);
      for (let i = 0; i < pagesCount + 3; i++) {
        const pageId = `pdf-page-${i}`; // Unique ID for each page
        // console.log("pageId", pageId);
        const pageElement = document.getElementById(pageId);
        // pageRefs.current[i]
        const canvas = await html2canvas(pageElement, {
          scale: 3, // Higher scale for better quality
          useCORS: true, // Handle cross-origin images
          allowTaint: true,
          backgroundColor: null, // Preserve background color
        });

        const imgData = canvas.toDataURL("image/png");
        const imgWidth = productsCatalog?.catalogSize?.width; // A4 width in mm
        const imgHeight = productsCatalog?.catalogSize?.height; // Maintain aspect ratio

        if (i > 0) jsPDFObject.addPage(); // Add a new page for every div after the first
        jsPDFObject.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      }
      jsPDFObject?.save(`${productsCatalog?.enCustomerName} Smart Catalog.pdf`);
    }
    setLoadingPDF(false); // Hide overlay
    handleClose();
  };

  // const exportPDF = async () => {
  //   const pdf = new jsPDF({
  //     orientation: productsCatalog?.orientation,
  //     format: [
  //       productsCatalog?.catalogSize?.width,
  //       productsCatalog?.catalogSize?.height,
  //     ],
  //     unit: "px",
  //   });

  //   for (let i = 0; i < pageRefs.current.length; i++) {
  //     const element = pageRefs.current[i];

  //     // Render HTML directly into the PDF
  //     await pdf.html(element, {
  //       width: productsCatalog?.catalogSize?.width, // A4 width minus margins (210mm - 20mm)
  //       windowWidth: element.scrollWidth, // Set windowWidth to maintain scaling
  //       html2canvas: {
  //         // scale: 2,  // Use a higher scale for better quality
  //         useCORS: true, // Handle cross-origin images
  //         backgroundColor: null, // Preserve the background color
  //       },
  //     });

  //     if (i < pageRefs.current.length - 1) {
  //       pdf.addPage(); // Add new page after each div except the last one
  //     }
  //   }

  //   pdf.save("multi-page-export.pdf");
  // };

  const pagesElements = [];

  for (let index = 0; index < pagesCountWithoutCovers; index++) {
    pagesElements.push(
      <Page
        key={index}
        pageNumber={index + 1}
        productsCatalog={productsCatalog}
        pagesCount={pagesCountWithoutCovers}
      />
    );
  }
  // const pdfpagesElements = [];

  // for (let index = 1; index <= pagesCount; index++) {
  //   pdfpagesElements.push(
  //     <div
  //       id={`catalog-${productsCatalog?.id}-page-${index}`}
  //       key={index}
  //     ></div>
  //   );
  // }

  return (
    <>
      <div>
        <div
          className={`navbar-customize bottom-0 catalog-control ${
            book === catalogId ? "active" : ""
          } ${navClassName && navClassName}`}
        >
          <ul className="navbar-nav-customize">
            {source === "catalogPage" && (
              <Nav.Link href="/">
                <img
                  src="/assets/images/sinaha-smart-catalog-white.png"
                  className="logo-image"
                  alt="Bootstrap Themes"
                  loading="lazy"
                />
              </Nav.Link>
            )}
            <Nav.Link
              id="first-button"
              className="hide-link"
              onClick={() => first()}
            >
              <FontAwesomeIcon icon={faStepBackward} />
            </Nav.Link>
            <Nav.Link
              id="previous-button"
              className="hide-link"
              onClick={() => previous()}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </Nav.Link>
            {!navClassName && (
              <NavDropdown
                title={<FontAwesomeIcon icon={faShareAlt} />}
                id={"shareDropdown" + catalogId}
                className="hide-link"
              >
                <NavDropdown.Item onClick={() => share("whatsapp")}>
                  <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => share("twitter")}>
                  <FontAwesomeIcon icon={faXTwitter} /> Twitter
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => share("facebook")}>
                  <FontAwesomeIcon icon={faFacebook} /> Facebook
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => share("linkedin")}>
                  <FontAwesomeIcon icon={faLinkedin} /> Linkedin
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => share("email")}>
                  <FontAwesomeIcon icon={faEnvelope} /> Email
                </NavDropdown.Item>
              </NavDropdown>
            )}
            <Nav.Link
              id="pdf-button"
              className="hide-link"
              // onClick={() => generatePDF(`catalog-cover-${catalogId}`)}
              onClick={() => handleShow()}
            >
              <FontAwesomeIcon icon={faFilePdf} />
            </Nav.Link>
            <NavDropdown
              title={<FontAwesomeIcon icon={faLanguage} />}
              id={"languageDropdown" + catalogId}
              className="hide-link"
            >
              <NavDropdown.Item onClick={(e) => changeLanguage(e, "en")}>
                English
              </NavDropdown.Item>
              <NavDropdown.Item onClick={(e) => changeLanguage(e, "ar")}>
                العربية
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              id="zoomIn"
              onClick={() => zoomIn()}
              className="hide-link"
            >
              <FontAwesomeIcon icon={faSearchPlus} />
            </Nav.Link>
            <Nav.Link
              id="zoomOut"
              onClick={() => zoomOut()}
              className="hide-link"
            >
              <FontAwesomeIcon icon={faSearchMinus} />
            </Nav.Link>
            <Nav.Link
              id="zoomReset"
              onClick={() => zoomReset()}
              className="hide-link"
            >
              <FontAwesomeIcon icon={faUndo} />
            </Nav.Link>
            <Nav.Link
              id="next-button"
              onClick={() => next()}
              className="hide-link"
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </Nav.Link>
            <Nav.Link
              id="last-button"
              onClick={() => last()}
              className="hide-link"
            >
              <FontAwesomeIcon icon={faStepForward} />
            </Nav.Link>
            {(source === "home" || source === "allCatalog") && (
              <Nav.Link id="close" onClick={() => close()}>
                <FontAwesomeIcon icon={faClose} />
              </Nav.Link>
            )}
            <div className="d-flex justify-content-end">
              <Form.Control
                type="search"
                placeholder={t("search")}
                className="me-2 search-input"
                aria-label="Search"
                ref={searchRef}
                onKeyPress={handleKeyPress}
              />
              <Button
                variant="outline-light"
                onClick={() => search()}
                type="button"
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </div>
            {(source === "home" || source === "allCatalog") && (
              <Nav.Link id="open" href={`/catalog/${slug}`} target="_blank">
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </Nav.Link>
            )}
          </ul>
        </div>

        {loadingPDF && <LoadingOverlay />}

        <div
          className={`body-class ${source}Div ${productsCatalog?.orientation}Div`}
        >
          <div className="shelf-container">
            <div className="shelf-card"></div>
          </div>
          <div
            className={`catalog-cover ${book === catalogId ? "active" : ""} ${
              productsCatalog?.orientation
            } ${productsCatalog?.designTemplate}`}
            style={
              currentX && book === catalogId
                ? { transform: `translate(-${currentX}px, 0)` }
                : isMobile && (source === "home" || source === "allCatalog")
                ? {
                    transform: `translate(-${
                      productsCatalog?.orientation === "Portrait" ? "20" : "25"
                    }%, -${
                      productsCatalog?.orientation === "Portrait" ? "25" : "0"
                    }%) scale(${
                      productsCatalog?.orientation === "Portrait"
                        ? "0.35"
                        : "0.30"
                    })`,
                  }
                : {}
            }
            onClick={() => open(catalogId)}
          >
            <div className="book-container">
              <div
                className={`book-container-customize ${
                  book === catalogId ? "active" : ""
                } ${i18n.language === "ar" ? "turn-ar" : ""}`}
                ref={bookRef}
                id={`catalog-${catalogId}`}
              >
                <div className="book-page" ref={bookCoverRef}>
                  <Cover
                    mode="rendered"
                    source={source}
                    fontNames={fontNames}
                    coverData={coverData}
                    // bookCoverRef={bookCoverRef}
                    setCoverData={setCoverData}
                    productsCatalog={productsCatalog}
                  />
                </div>
                <div className="book-page">
                  <Profile
                    mode="done"
                    source={source}
                    profileData={profileData}
                    setProfileData={setProfileData}
                    productsCatalog={productsCatalog}
                  />
                </div>
                {pagesElements}
                <div className="book-page">
                  <BackCover
                    mode="done"
                    source={source}
                    backCoverData={backCoverData}
                    productsCatalog={productsCatalog}
                    setBackCoverData={setBackCoverData}
                    backCoverDesignTemplate={
                      productsCatalog?.backCoverDesignTemplate
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {source === "catalogPage" && (
          <div
            className={`navbar-customize catalog-control hide-nav ${
              book === catalogId ? "active" : ""
            } `}
          >
            <ul className="navbar-nav-customize">
              <Nav.Link id="first-button" onClick={() => first()}>
                <FontAwesomeIcon icon={faStepBackward} />
              </Nav.Link>
              <Nav.Link id="previous-button" onClick={() => previous()}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </Nav.Link>
              <NavDropdown
                title={<FontAwesomeIcon icon={faShareAlt} />}
                id={"shareDropdown" + catalogId}
              >
                <NavDropdown.Item onClick={() => share("whatsapp")}>
                  <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => share("twitter")}>
                  <FontAwesomeIcon icon={faXTwitter} /> Twitter
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => share("facebook")}>
                  <FontAwesomeIcon icon={faFacebook} /> Facebook
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => share("linkedin")}>
                  <FontAwesomeIcon icon={faLinkedin} /> Linkedin
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => share("email")}>
                  <FontAwesomeIcon icon={faEnvelope} /> Email
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title={<FontAwesomeIcon icon={faLanguage} />}
                id={"languageDropdown" + catalogId}
              >
                <NavDropdown.Item onClick={(e) => changeLanguage(e, "en")}>
                  English
                </NavDropdown.Item>
                <NavDropdown.Item onClick={(e) => changeLanguage(e, "ar")}>
                  العربية
                </NavDropdown.Item>
              </NavDropdown>
              {!isMobile && (
                <>
                  <Nav.Link id="zoomIn" onClick={() => zoomIn()}>
                    <FontAwesomeIcon icon={faSearchPlus} />
                  </Nav.Link>
                  <Nav.Link id="zoomOut" onClick={() => zoomOut()}>
                    <FontAwesomeIcon icon={faSearchMinus} />
                  </Nav.Link>
                  <Nav.Link id="zoomReset" onClick={() => zoomReset()}>
                    <FontAwesomeIcon icon={faUndo} />
                  </Nav.Link>
                </>
              )}
              <Nav.Link id="next-button" onClick={() => next()}>
                <FontAwesomeIcon icon={faArrowRight} />
              </Nav.Link>
              <Nav.Link id="last-button" onClick={() => last()}>
                <FontAwesomeIcon icon={faStepForward} />
              </Nav.Link>
            </ul>
          </div>
        )}
      </div>
      {/* style={{display: "block",position: "absolute",top: "77px",right: "21.35%"}} */}
      {/* ref={bookCoverRef}  */}

      <Modal show={showPDF} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Export To PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {pdfpagesElements} */}
          {pdfPagesContent.length > 0 && (
            <div
              id={`catalog-pdf-${productsCatalog?.id}`}
              style={{
                width: productsCatalog?.catalogSize?.width,
                margin: "0 auto"
                // height: productsCatalog?.catalogSize?.height,
              }}
            >
              <div className="book-page" id={`pdf-page-${0}`}>
                <Cover
                  mode="rendered"
                  source={source}
                  fontNames={fontNames}
                  coverData={coverData}
                  // bookCoverRef={bookCoverRef}
                  setCoverData={setCoverData}
                  productsCatalog={productsCatalog}
                />
              </div>
              <div className="book-page" id={`pdf-page-${1}`}>
                <Profile
                  mode="done"
                  source={source}
                  profileData={profileData}
                  setProfileData={setProfileData}
                  productsCatalog={productsCatalog}
                />
              </div>
              {pdfPagesContent.map((pdfPageContent, index) => (
                <div
                  className="book-page"
                  id={`pdf-page-${index + 2}`}
                  key={index}
                >
                  <PageContent
                    source={source}
                    mode="rendered"
                    page={pdfPageContent}
                    pageNumber={index + 1}
                    productsCatalog={productsCatalog}
                    pagesCount={productsCatalog?.pagesCount}
                    catalogSize={productsCatalog?.catalogSize}
                  />
                </div>
              ))}
              <div
                className="book-page"
                id={`pdf-page-${2 + pdfPagesContent.length}`}
              >
                <BackCover
                  mode="done"
                  source={source}
                  backCoverData={backCoverData}
                  productsCatalog={productsCatalog}
                  setBackCoverData={setBackCoverData}
                  backCoverDesignTemplate={
                    productsCatalog?.backCoverDesignTemplate
                  }
                />
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button variant="primary" onClick={exportPDF}>
            Export To PDF
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Catalog;
