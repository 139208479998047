import {
  createImage,
  fetchCatalogByCategory,
  fetchCatalogByCustomer,
} from "../../api/catalogs";
import { faFileExport, faEye, faX } from "@fortawesome/free-solid-svg-icons";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API_BASE_URL_WITH_PORT } from "../../config/url";
import { ToastContainer, toast } from "react-toastify";
import { fetchProducts } from "../../api/products";
import { createCatalog } from "../../api/catalogs";
import PageContent from "../catalog/pageContent";
import BackCoverConfig from "./backCoverConfig";
import "react-toastify/dist/ReactToastify.css";
import ProductsConfig from "./productsConfig";
import BackCover from "../catalog/backCover";
import { useParams } from "react-router-dom";
import ProfileConfig from "./profileConfig";
import CatalogConfig from "./catalogConfig";
import Cover from "../catalog/covers/cover";
import { useEffect, useState } from "react";
import Profile from "../catalog/profile";
import ConfirmDialog from "../confirm";
import PageConfig from "./pageConfig";
import { Nav } from "react-bootstrap";
import Catalog from "../catalog";
import { t } from "i18next";

// Font Names
const fontNames = [
  { "": "" },
  { Cairo: '"Cairo", sans-serif' },
  { Georgia: '"Georgia", serif' },
  { Papyrus: "Papyrus, fantasy" },
  { Tahoma: "Tahoma, Geneva, sans-serif" },
  { Arial: "Arial, Helvetica, sans-serif" },
  { Verdana: "Verdana, Geneva, sans-serif" },
  { Impact: "Impact, Charcoal, sans-serif" },
  { Helvetica: "Helvetica, Arial, sans-serif" },
  { Courier: '"Courier New", Courier, monospace' },
  { Lucida: '"Lucida Console", Monaco, monospace' },
  { Consolas: 'Consolas, "Courier New", monospace' },
  { "Brush Script MT": '"Brush Script MT", cursive' },
  { Trebuchet: '"Trebuchet MS", Helvetica, sans-serif' },
  { "Times New Roman": '"Times New Roman", Times, serif' },
  { "Comic Sans MS": '"Comic Sans MS", cursive, sans-serif' },
  { Palatino: '"Palatino Linotype", "Book Antiqua", Palatino, serif' },
];

// Chunk Array
const chunkArray = (array, size) => {
  const result = array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / size);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);
  return result;
};

// Initial Profile Data
const initialProfileData = {
  logo: {
    show: true,
    opacity: 1,
    position: { x: 20, y: 20 },
    size: { width: 100, height: 100 },
  },
  name: {
    show: true,
    opacity: 1,
    bold: false,
    fontSize: 14,
    italic: false,
    underline: false,
    textAlign: "left",
    fontColor: "#000000",
    position: { x: 140, y: 20 },
    size: { width: 220, height: 40 },
    fontName: '"Times New Roman", Times, serif',
  },
  description: {
    show: true,
    opacity: 1,
    bold: false,
    fontSize: 14,
    italic: false,
    underline: false,
    textAlign: "left",
    fontColor: "#000000",
    position: { x: 20, y: 170 },
    size: { width: 490, height: 80 },
    fontName: '"Times New Roman", Times, serif',
  },
};

// Initial Cover Data
const initialCoverData = {
  coverLogo: {
    rotate: 0,
    opacity: 1,
    color: "#000000",
    position: { x: 42, y: 43 },
    size: { width: 100, height: 100 },
  },
  coverQrCode: {
    rotate: 0,
    opacity: 1,
    color: "#000000",
    position: { x: 433, y: 31 },
    size: { width: 100, height: 100 },
  },
  coverName: {
    rotate: 0,
    opacity: 1,
    bold: false,
    fontSize: 14,
    italic: false,
    underline: false,
    color: "#000000",
    textAlign: "left",
    position: { x: 171, y: 460 },
    size: { width: 157, height: 21 },
    fontName: '"Times New Roman", Times, serif',
  },
  coverTitle: {
    rotate: 0,
    opacity: 1,
    bold: false,
    fontSize: 14,
    italic: false,
    underline: false,
    color: "#000000",
    textAlign: "left",
    position: { x: 217, y: 677 },
    size: { width: 112, height: 49 },
    fontName: '"Times New Roman", Times, serif',
  },
  coverCopyRight: {
    rotate: 0,
    opacity: 1,
    bold: false,
    fontSize: 14,
    italic: false,
    underline: false,
    color: "#000000",
    textAlign: "left",
    position: { x: 11, y: 762 },
    size: { width: 226, height: 22 },
    fontName: '"Times New Roman", Times, serif',
  },
  coverYear: {
    rotate: 0,
    opacity: 1,
    bold: false,
    fontSize: 14,
    italic: false,
    underline: false,
    color: "#000000",
    textAlign: "left",
    position: { x: 316, y: 769 },
    size: { width: 203, height: 22 },
    fontName: '"Times New Roman", Times, serif',
  },
};

// Initial Back Cover Data
const initialBackCoverData = {
  backCoverLogo: {
    rotate: 0,
    color: "#000000",
    position: { x: 42, y: 43 },
    size: { width: 100, height: 100 },
  },
};

const CreateCatalog = () => {
  const { urlID, urlType } = useParams();
  const [profileBackgroundColor, setProfileBackgroundColor] =
    useState("#eeeeee");
  const [profileImagePosition, setProfileImagePosition] = useState("center");
  const [profileImagePerview, setProfileImagePerview] = useState("");
  const [profileData, setProfileData] = useState(initialProfileData);
  const [profileImageSize, setProfileImageSize] = useState("cover");
  const [profileImageOpacity, setProfileImageOpacity] = useState(0);
  const [profileShapesList, setProfileShapesList] = useState([]);
  const [profileImage, setProfileImage] = useState(null);
  const [shortProfile, setShortProfile] = useState("");
  // End Profile States
  // Catalog State
  const [backCoverDesignTemplate, setBackCoverDesignTemplate] =
    useState("temp-1");
  const [backCoverImagePreviewUrl, setBackCoverImagePreviewUrl] = useState("");
  const [catalogSize, setCatalogSize] = useState({ width: 550, height: 800 });
  const [numberOfProductsPerPage, setNumberOfProductsPerPage] = useState(9);
  const [backCoverData, setBackCoverData] = useState(initialBackCoverData);
  const [coverImagePosition, setCoverImagePosition] = useState("center");
  const [coverImagePreviewUrl, setCoverImagePreviewUrl] = useState("");
  const [designTemplate, setDesignTemplate] = useState("temp-2");
  const [coverImageOpacity, setCoverImageOpacity] = useState(0);
  const [coverImageSize, setCoverImageSize] = useState("cover");
  const [exportedCatalog, setExportedCatalog] = useState(null);
  const [coverData, setCoverData] = useState(initialCoverData);
  const [coverShapesList, setCoverShapesList] = useState([]);
  const [backCoverImage, setBackCoverImage] = useState(null);
  const [orientation, setOrientation] = useState("Portrait");
  const [existCatalogID, setExistCatalogID] = useState(0);
  const [mainColor, setMainColor] = useState("#000000");
  const [customerName, setCustomerName] = useState("");
  const [customerLogo, setCustomerLogo] = useState("");
  const [coverImage, setCoverImage] = useState(null);
  // End Catalog State
  // Pages States
  const [pages, setPages] = useState([]);
  // End Pages States
  // Products States
  const [checkedProducts, setCheckedProducts] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [products, setProducts] = useState([]);
  // End Products States
  // Page States
  const [currentPageIndex, setCurrentPageIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  // other states
  const [currentCenterRender, setCurrentCenterRender] = useState("catalog");
  const [currentDeletedPage, setCurrentDeletedPage] = useState(false);
  const [currentTopRender, setCurrentTopRender] = useState("item");
  const [showConfirm, setShowConfirm] = useState(false);
  const [rendered, setRendered] = useState(false);
  const [isLoading, setLoading] = useState(true);
  // get orientation
  useEffect(() => {
    if (orientation) {
      setExportedCatalog({
        ...exportedCatalog,
        orientation: orientation,
        catalogSize:
          orientation === "Portrait"
            ? { width: 550, height: 800 }
            : { width: 750, height: 500 },
      });
      setCatalogSize(
        orientation === "Portrait"
          ? { width: 550, height: 800 }
          : { width: 750, height: 500 }
      );
    }
  }, [orientation]);
  // get design template
  useEffect(() => {
    if (designTemplate) {
      setExportedCatalog({
        ...exportedCatalog,
        designTemplate: designTemplate,
      });
    }
  }, [designTemplate]);
  // get Profile Image
  useEffect(() => {
    setExportedCatalog({
      ...exportedCatalog,
      profileImageSize: profileImageSize,
      profileImageOpacity: profileImageOpacity,
      profileImagePosition: profileImagePosition,
      profileBackgroundColor: profileBackgroundColor,
    });
  }, [
    profileImagePosition,
    profileImageSize,
    profileBackgroundColor,
    profileImageOpacity,
  ]);
  // get Cover Image
  useEffect(() => {
    setExportedCatalog({
      ...exportedCatalog,
      coverImageSize: coverImageSize,
      coverImageOpacity: coverImageOpacity,
      coverImagePosition: coverImagePosition,
    });
  }, [coverImagePosition, coverImageSize, coverImageOpacity]);
  // get back Cover
  useEffect(() => {
    setExportedCatalog({
      ...exportedCatalog,
      backCoverDesignTemplate,
    });
  }, [backCoverDesignTemplate]);
  // get current catalog if exist
  useEffect(() => {
    document.body.classList.add("create-catalog");
    fetchDataFromAPI();
  }, []);
  // get current catalog if exist
  const fetchDataFromAPI = async () => {
    try {
      setRendered(false);
      setLoading(true);
      var locatExistCatalogId = 0;
      var catalogDataFromApi = null;
      if (urlType === "category") {
        catalogDataFromApi = await fetchCatalogByCategory(urlID, "createPage");
      } else if (urlType === "seller") {
        catalogDataFromApi = await fetchCatalogByCustomer(urlID, "createPage");
      }
      var localcatalogSize = catalogSize;
      if (catalogDataFromApi) {
        var localPages = [];
        localPages =
          catalogDataFromApi?.catalogPages.length > 0
            ? catalogDataFromApi?.catalogPages
            : [];
        localPages = localPages.map((page) => ({
          ...page,
          imagesListShow: false,
          id: page.id.toString(),
          textsList: page.textsList ? JSON.parse(page.textsList) : [],
          shapesList: page.shapesList ? JSON.parse(page.shapesList) : [],
          productsList: page.productsList ? JSON.parse(page.productsList) : [],
          imagesListGridSystem: page?.imagesListGridSystem
            ? page?.imagesListGridSystem
            : "customize",
          imagesList: page?.imagesList
            ? JSON.parse(page?.imagesList)
            : [{ imagePreviewUrl: null, alt: null, image: null, id: "1" }],
          productsListGridSystem: page.productsListGridSystem
            ? JSON.parse(page.productsListGridSystem)
            : { cols: "col-4", rows: 3 },
        }));
        setCheckedProducts(catalogDataFromApi?.productsList);
        var localPagesCount = localPages.length;
        catalogDataFromApi = catalogDataFromApi?.catalogData;
        if (catalogDataFromApi) {
          locatExistCatalogId = catalogDataFromApi?.id;
          if (catalogDataFromApi?.coverData) {
            setCoverData(JSON.parse(catalogDataFromApi?.coverData));
          }
          if (catalogDataFromApi?.backCoverData) {
            setBackCoverData(JSON.parse(catalogDataFromApi?.backCoverData));
          }
          if (catalogDataFromApi?.profileData) {
            setProfileData(JSON.parse(catalogDataFromApi?.profileData));
          }
          setPages(localPages);
          setExistCatalogID(catalogDataFromApi?.id);
          setOrientation(catalogDataFromApi?.orientation);
          setMainColor(catalogDataFromApi?.mainColor || "#eeeeee");
          setCoverImageSize(catalogDataFromApi?.coverImageSize || "cover");
          setDesignTemplate(catalogDataFromApi?.designTemplate || "temp-1");
          setProfileImageSize(catalogDataFromApi?.profileImageSize || "cover");
          setProfileShapesList(
            catalogDataFromApi?.profileShapesList
              ? JSON.parse(catalogDataFromApi?.profileShapesList)
              : []
          );
          setCoverShapesList(
            catalogDataFromApi?.coverShapesList
              ? JSON.parse(catalogDataFromApi?.coverShapesList)
              : []
          );
          setNumberOfProductsPerPage(
            catalogDataFromApi?.numberOfProductsPerPage || 9
          );
          setProfileImagePosition(
            catalogDataFromApi?.profileImagePosition || "center"
          );
          setCoverImagePosition(
            catalogDataFromApi?.coverImagePosition || "center"
          );
          setCoverImageOpacity(catalogDataFromApi?.coverImageOpacity || 0);
          setProfileImageOpacity(catalogDataFromApi?.profileImageOpacity || 0);
          setBackCoverDesignTemplate(
            catalogDataFromApi?.backCoverDesignTemplate || "temp-1"
          );
          setCoverImagePreviewUrl(
            catalogDataFromApi?.coverImage &&
              (catalogDataFromApi?.coverImage?.includes("data:image")
                ? catalogDataFromApi?.coverImage
                : `${API_BASE_URL_WITH_PORT}/${catalogDataFromApi?.coverImage?.replace(
                    /\\/g,
                    "/"
                  )}`)
          );
          setBackCoverImagePreviewUrl(
            catalogDataFromApi?.backCoverImage &&
              (catalogDataFromApi?.backCoverImage?.includes("data:image")
                ? catalogDataFromApi?.backCoverImage
                : `${API_BASE_URL_WITH_PORT}/${catalogDataFromApi?.backCoverImage?.replace(
                    /\\/g,
                    "/"
                  )}`)
          );
          setCoverImage(
            catalogDataFromApi?.coverImage && catalogDataFromApi?.coverImage
          );
          setProfileImage(
            catalogDataFromApi?.profileImage && catalogDataFromApi?.profileImage
          );
          setProfileBackgroundColor(
            catalogDataFromApi?.profileBackgroundColor &&
              catalogDataFromApi?.profileBackgroundColor
          );
          setBackCoverImage(
            catalogDataFromApi?.backCoverImage &&
              catalogDataFromApi?.backCoverImage
          );
          setProfileImagePerview(
            catalogDataFromApi?.profileImage &&
              (catalogDataFromApi?.profileImage?.includes("data:image")
                ? catalogDataFromApi?.profileImage
                : `${API_BASE_URL_WITH_PORT}/${catalogDataFromApi?.profileImage?.replace(
                    /\\/g,
                    "/"
                  )}`)
          );
          localcatalogSize = JSON.parse(catalogDataFromApi?.catalogSize);
          setCatalogSize(localcatalogSize);
        }
      }
      var productsList = await fetchProducts(urlID, urlType);
      var categoryIcon = "";
      var customerLogo = "";
      var customerName = "";
      var shortProfile = "";
      var categoryName = "";
      var enCategoryDescription = "";
      if (productsList.data.length > 0) {
        const filteredProducts = productsList.data.filter(
          (product) => product.en_name && product.en_name.trim() !== ""
        );
        setProducts(filteredProducts);
      }
      if (productsList.companyData.length > 0) {
        enCategoryDescription = productsList.companyData[0].description;
        setCustomerName(productsList.companyData[0].enCustomerName);
        setCustomerLogo(productsList.companyData[0].customerLogo);
        setShortProfile(productsList.companyData[0].shortProfile);
        customerName = productsList.companyData[0].enCustomerName;
        customerLogo = productsList.companyData[0].customerLogo;
        shortProfile = productsList.companyData[0].shortProfile;
        categoryIcon = productsList.companyData[0].categoryIcon;
        categoryName = productsList.companyData[0].name;
      }
      var initalExportedCatalog = {
        mode: "preview",
        pages: localPages,
        mainColor: mainColor,
        coverImage: coverImage,
        id: locatExistCatalogId,
        orientation: orientation,
        profileImage: profileImage,
        pagesCount: localPagesCount,
        catalogSize: localcatalogSize,
        coverImageSize: coverImageSize,
        backCoverImage: backCoverImage,
        designTemplate: designTemplate,
        profileImageSize: profileImageSize,
        coverImageOpacity: coverImageOpacity,
        coverImagePosition: coverImagePosition,
        profileImageOpacity: profileImageOpacity,
        profileImagePosition: profileImagePosition,
        backCoverDesignTemplate: backCoverDesignTemplate,
        numberOfProductsPerPage: numberOfProductsPerPage,
      };
      if (urlType === "category") {
        initalExportedCatalog.categoryId = urlID;
        initalExportedCatalog.categoryIcon = categoryIcon;
        initalExportedCatalog.slug = slugify(categoryName);
        initalExportedCatalog.enCategoryName = categoryName;
        initalExportedCatalog.enCategoryDescription = enCategoryDescription;
      } else if (urlType === "seller") {
        initalExportedCatalog.customerId = urlID;
        initalExportedCatalog.customerLogo = customerLogo;
        initalExportedCatalog.shortProfile = shortProfile;
        initalExportedCatalog.slug = slugify(customerName);
        initalExportedCatalog.enCustomerName = customerName;
      }
      if (catalogDataFromApi) {
        initalExportedCatalog.mainColor = catalogDataFromApi?.mainColor;
        initalExportedCatalog.orientation = catalogDataFromApi?.orientation;
        initalExportedCatalog.coverImageOpacity =
          catalogDataFromApi?.coverImageOpacity || 0;
        initalExportedCatalog.coverImageSize =
          catalogDataFromApi?.coverImageSize || "cover";
        initalExportedCatalog.profileImageSize =
          catalogDataFromApi?.profileImageSize || "cover";
        initalExportedCatalog.profileImageOpacity =
          catalogDataFromApi?.profileImageOpacity || 0;
        initalExportedCatalog.coverImagePosition =
          catalogDataFromApi?.coverImagePosition || "center";
        initalExportedCatalog.profileImagePosition =
          catalogDataFromApi?.profileImagePosition || "center";
        initalExportedCatalog.designTemplate =
          catalogDataFromApi?.designTemplate;
        initalExportedCatalog.profileBackgroundColor =
          catalogDataFromApi?.profileBackgroundColor;
        initalExportedCatalog.backCoverDesignTemplate =
          catalogDataFromApi?.backCoverDesignTemplate;
        initalExportedCatalog.numberOfProductsPerPage =
          catalogDataFromApi?.numberOfProductsPerPage;
        initalExportedCatalog.coverImage =
          catalogDataFromApi?.coverImage?.includes("data:image")
            ? catalogDataFromApi?.coverImage
            : `${API_BASE_URL_WITH_PORT}/${catalogDataFromApi?.coverImage?.replace(
                /\\/g,
                "/"
              )}`;
        initalExportedCatalog.backCoverImage =
          catalogDataFromApi?.backCoverImage?.includes("data:image")
            ? catalogDataFromApi?.backCoverImage
            : `${API_BASE_URL_WITH_PORT}/${catalogDataFromApi?.backCoverImage?.replace(
                /\\/g,
                "/"
              )}`;
        initalExportedCatalog.profileImage =
          catalogDataFromApi?.profileImage?.includes("data:image")
            ? catalogDataFromApi?.profileImage
            : `${API_BASE_URL_WITH_PORT}/${catalogDataFromApi?.profileImage?.replace(
                /\\/g,
                "/"
              )}`;
        initalExportedCatalog.coverShapesList =
          catalogDataFromApi?.coverShapesList
            ? JSON.parse(catalogDataFromApi?.coverShapesList)
            : [];
        initalExportedCatalog.profileShapesList =
          catalogDataFromApi?.profileShapesList
            ? JSON.parse(catalogDataFromApi?.profileShapesList)
            : [];
      }
      setExportedCatalog(initalExportedCatalog);
      setLoading(false);
      setTimeout(() => {
        setRendered(true);
      }, 100);
    } catch (error) {
      console.log(error);
    }
  };
  //************ Profile Data ************//
  // handle profile data
  const handleProfileData = (id, e, key) => {
    setProfileData((prevStyles) => ({
      ...prevStyles,
      [id]: {
        ...prevStyles[id],
        [key]:
          key === "fontSize"
            ? parseInt(e.target.value)
            : key === "show" ||
              key === "bold" ||
              key === "italic" ||
              key === "underline"
            ? !prevStyles[id][key]
            : key === "textAlign"
            ? e
            : e.target.value,
      },
    }));
  };
  // Handle Profile Background Image Change
  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    setProfileImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImagePerview(reader.result);
        setExportedCatalog({
          ...exportedCatalog,
          profileImage: reader.result,
        });
      };
      reader.readAsDataURL(file);
    } else {
      setProfileImagePerview("");
      setExportedCatalog({
        ...exportedCatalog,
        profileImage: null,
      });
    }
  };
  // Delete Profile Image
  const deleteProfileImage = (e) => {
    setProfileImage(null);
    setProfileImagePerview(null);
    setExportedCatalog({
      ...exportedCatalog,
      profileImage: null,
    });
  };
  //************ Products ************//
  // Handle Products Select All Change
  const handleProductsSelectAllChange = () => {
    var newcheckedProducts = [];
    if (!isAllChecked) {
      products.forEach((product) => {
        if (product.en_name) {
          newcheckedProducts.push(product);
        }
      });
    }
    setCheckedProducts(newcheckedProducts);
    setIsAllChecked(!isAllChecked);
  };
  // Handle Products Checkbox Change
  const handleProductsCheckboxChange = (e, product) => {
    if (
      e.target.checked &&
      !checkedProducts.some(
        (checkedProduct) => checkedProduct.product_id === product.product_id
      )
    ) {
      setCheckedProducts([...checkedProducts, product]);
    } else {
      var localCheckedProducts = checkedProducts.filter(
        (checkedProduct) => checkedProduct.product_id !== product.product_id
      );
      setCheckedProducts(localCheckedProducts);
    }
  };
  //************ End Products ************//
  //************ Pages ************//
  // Generate Page ID
  const generatePageID = () => {
    const number = Math.floor(10000 + Math.random() * 90000);
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const letter1 = letters.charAt(Math.floor(Math.random() * letters.length));
    const letter2 = letters.charAt(Math.floor(Math.random() * letters.length));
    return letter1 + "" + letter2 + number.toString();
  };
  // generate slug url
  const slugify = (text) => {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "")
      .replace(/--+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "")
      .toLowerCase();
  };
  // Add Page
  const addPage = () => {
    const newPage = {
      productsListGridSystem: { cols: "col-4", rows: 3 },
      sortOrder: pages.length > 0 ? pages.length + 1 : 1,
      imagesListGridSystem: "column",
      pageBackgroundColor: "#eeeeee",
      pageType: "non-customized",
      imagesListShow: false,
      id: generatePageID(),
      numberOfProducts: 0,
      productsList: [],
      productsIDs: "",
      imagesList: [],
      shapesList: [],
      textsList: [],
    };
    setPages([...pages, newPage]);
  };
  // Handle Change Page Content
  const handleChangePageContent = (
    pageId,
    key,
    value,
    subIndex = null,
    subKey = null,
    subValue = null,
    subSubIndex = null,
    subSubKey = null,
    subSubValue = null
  ) => {
    setPages((prevPages) =>
      prevPages.map((page) => {
        if (page.id === pageId) {
          var newValue = value;
          if (subIndex !== null) {
            newValue = page[key].map((item, i) => {
              var condition =
                key === "productsList"
                  ? subIndex === item.product_id
                  : subIndex === i;
              if (condition) {
                return {
                  ...item,
                  [subKey]: subSubKey
                    ? {
                        ...item[subKey],
                        [subSubKey]:
                          subSubKey.includes("Show") ||
                          subSubKey.includes("Bold") ||
                          subSubKey.includes("Italic") ||
                          subSubKey.includes("Underline")
                            ? !item[subKey][subSubKey]
                            : subSubValue,
                      }
                    : subKey === "show"
                    ? !item[subKey]
                    : subValue,
                };
              } else {
                return item;
              }
            });
          }

          return {
            ...page,
            [key]: newValue,
          };
        } else {
          return page;
        }
      })
    );

    var newValue = value;

    if (subIndex !== null) {
      newValue = currentPage[key].map((item, i) => {
        var condition =
          key === "productsList"
            ? subIndex === item.product_id
            : subIndex === i;
        if (condition) {
          return {
            ...item,
            [subKey]: subSubKey
              ? {
                  ...item[subKey],
                  [subSubKey]:
                    subSubKey.includes("Show") ||
                    subSubKey.includes("Bold") ||
                    subSubKey.includes("Italic") ||
                    subSubKey.includes("Underline")
                      ? !item[subKey][subSubKey]
                      : subSubValue,
                }
              : subKey === "show"
              ? !item[subKey]
              : subValue,
          };
        } else {
          return item;
        }
      });
    }

    setCurrentPage({
      ...currentPage,
      [key]: newValue,
    });
  };
  // Remove Page
  const handleDelete = (id) => {
    setCurrentDeletedPage(id);
    setShowConfirm(true);
  };
  // handle Cancel
  const handleCancel = () => {
    setCurrentDeletedPage(null);
    setShowConfirm(false);
  };
  // remove Page
  const removePage = (id) => {
    const newPages = pages.filter((page) => page.id !== id);
    setPages(newPages);
    setShowConfirm(false);
    if (newPages.length === 0) {
      setCurrentCenterRender("profile");
    }
    setCurrentPageIndex(null);
  };
  // Handle handle Add Remove Product From To Page Products List
  const handleAddRemoveProductFromToPageProductsList = (
    pageId,
    updatedProducts
  ) => {
    const prevPages = pages.map((page) => {
      if (page.id === pageId) {
        var mainProducts = page.productsList;
        const mainProductsMap = new Map(
          mainProducts.map((product) => [product.product_id, product])
        );
        updatedProducts.forEach((product) => {
          if (!mainProductsMap.has(product.product_id)) {
            mainProducts.push(product);
          }
        });
        return {
          ...page,
          productsList: mainProducts.filter((product) => {
            return updatedProducts.some(
              (newProduct) => newProduct.product_id === product.product_id
            );
          }),
        };
      } else {
        return page;
      }
    });
    setPages(prevPages);
    var currentPageMainProducts = currentPage.productsList;
    const mainProductsMap = new Map(
      currentPageMainProducts.map((product) => [product.product_id, product])
    );
    updatedProducts.forEach((product) => {
      if (!mainProductsMap.has(product.product_id)) {
        currentPageMainProducts.push(product);
      }
    });
    setCurrentPage({
      ...currentPage,
      productsList: currentPageMainProducts.filter((product) =>
        updatedProducts.some(
          (newProduct) => newProduct.product_id === product.product_id
        )
      ),
    });
    const allProducts = prevPages.flatMap((page) => page.productsList);
    setCheckedProducts(allProducts);
  };
  // Handle image upload
  const handlePageImagesChange = async (id, e, index) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("pageImage", file);
    try {
      const response = await createImage(formData);
      const imageUrl = response.imageUrl;
      const updatedPages = pages.map((page) => {
        if (page.id === id) {
          let updatedImages = [...page?.imagesList];
          updatedImages[index].imagePreviewUrl = imageUrl;
          return {
            ...page,
            imagesList: updatedImages,
          };
        }
        return page;
      });
      setPages(updatedPages);
      let updatedImages = [...currentPage?.imagesList];
      updatedImages[index].imagePreviewUrl = imageUrl;
      setCurrentPage({ ...currentPage, imagesList: updatedImages });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  // On Drag End To Sort Pages
  const onDragEndSortPages = (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedPages = Array.from(pages);
    const [removed] = reorderedPages.splice(result.source.index, 1);
    reorderedPages.splice(result.destination.index, 0, removed);
    const updatedPages = reorderedPages.map((page, index) => ({
      ...page,
      sortOrder: index + 1,
    }));
    updatedPages.sort((a, b) => a.sortOrder - b.sortOrder);
    setPages(updatedPages);
  };
  // Handle Drag End To Sort Pages
  const onDragEndSortImages = (result) => {
    if (!result.destination) return;
    const sourcePageIndex = pages.findIndex(
      (p) => p.id === result.source.droppableId
    );
    const destPageIndex = pages.findIndex(
      (p) => p.id === result.destination.droppableId
    );
    const sourcePage = pages[sourcePageIndex];
    const destPage = pages[destPageIndex];
    const [movedItem] = sourcePage?.imagesList.splice(result.source.index, 1);
    if (sourcePageIndex === destPageIndex) {
      sourcePage?.imagesList.splice(result.destination.index, 0, movedItem);
      const updatedPages = [...pages];
      updatedPages[sourcePageIndex] = sourcePage;
      setPages(updatedPages);
    } else {
      destPage?.imagesList.splice(result.destination.index, 0, movedItem);
      const updatedPages = [...pages];
      updatedPages[sourcePageIndex] = sourcePage;
      updatedPages[destPageIndex] = destPage;
      setPages(updatedPages);
    }
  };
  // Handle Products List Grid System Change
  const handleProductsListGridSystemChange = (e, id) => {
    handleChangePageContent(
      id,
      "productsListGridSystem",
      JSON.parse(e.target.value)
    );
    divideProductsIntoEveryPage(JSON.parse(e.target.value), id);
  };
  // Add New Page Image
  const addNewPageImage = (id) => {
    const newPages = pages.map((page) => {
      if (page.id === id) {
        const updatedImages = [
          ...page?.imagesList,
          {
            alt: "",
            zIndex: 0,
            rotate: 0,
            opacity: 1,
            show: true,
            image: null,

            objectFit: "cover",
            objectPosition: "center",

            imagePreviewUrl: "",
            position: { x: 20, y: 20 },
            size: { width: 100, height: 100 },
            id: (page?.imagesList.length + 1).toString(),
          },
        ];
        return {
          ...page,
          imagesList: updatedImages,
        };
      }
      return page;
    });
    setPages(newPages);
    const updatedImages = [
      ...currentPage?.imagesList,
      {
        alt: "",
        zIndex: 0,
        rotate: 0,
        opacity: 1,
        show: true,
        image: null,
        objectFit: "cover",
        imagePreviewUrl: "",
        objectPosition: "center",
        position: { x: 20, y: 20 },
        size: { width: 100, height: 100 },
        id: (currentPage?.imagesList.length + 1).toString(),
      },
    ];
    setCurrentPage({ ...currentPage, imagesList: updatedImages });
  };
  // handle Change Profile Shapes List
  const addNewShape = (type, stateKey) => {
    const shapesList =
      stateKey === "cover" ? coverShapesList : profileShapesList;
    const newShapesList = [
      ...shapesList,
      {
        type,
        rotate: 0,
        zIndex: 0,
        opacity: 1,
        show: true,
        borderWidth: 1,
        bgColor: "#000000",
        borderStyle: "solid",
        borderColor: "#000000",
        position: { x: 20, y: 20 },
        size: { width: 100, height: 100 },
        id: (shapesList.length + 1).toString(),
        borderRadius: type === "circle" ? "50%" : 0,
      },
    ];
    if (stateKey === "cover") {
      setCoverShapesList(newShapesList);
    } else {
      setProfileShapesList(newShapesList);
    }
    setExportedCatalog({
      ...exportedCatalog,
      [`${stateKey}ShapesList`]: newShapesList,
    });
  };
  // Add New Profile Text
  const handleChangeShapesList = (index, key, value, stateKey) => {
    const shapesList =
      stateKey === "cover" ? coverShapesList : profileShapesList;
    const newShapesList = shapesList.map((shape, shapeIndex) => {
      if (shapeIndex === index) {
        return {
          ...shape,
          [key]: value,
        };
      } else {
        return shape;
      }
    });
    if (stateKey === "cover") {
      setCoverShapesList(newShapesList);
    } else {
      setProfileShapesList(newShapesList);
    }
    setExportedCatalog({
      ...exportedCatalog,
      [`${stateKey}ShapesList`]: newShapesList,
    });
  };
  // Delete Profile shape
  const deleteFromShapesList = (shapeIndex, stateKey) => {
    const shapesList =
      stateKey === "cover" ? coverShapesList : profileShapesList;
    const newShapesList = [...shapesList];
    newShapesList.splice(shapeIndex, 1);
    if (stateKey === "cover") {
      setCoverShapesList(newShapesList);
    } else {
      setProfileShapesList(newShapesList);
    }
    setExportedCatalog({
      ...exportedCatalog,
      [`${stateKey}ShapesList`]: newShapesList,
    });
  };
  // Add New Page Text
  const addNewPageText = (id) => {
    const newPages = pages.map((page) => {
      if (page.id === id) {
        const updatedTexts = [
          ...page.textsList,
          {
            zIndex: 0,
            rotate: 0,
            opacity: 1,
            show: true,
            bold: false,
            content: "",
            fontSize: 14,
            italic: false,
            underline: false,
            fontColor: "#000000",
            position: { x: 20, y: 20 },
            size: { width: 100, height: 100 },
            id: (page.textsList.length + 1).toString(),
            fontName: '"Times New Roman", Times, serif',
          },
        ];
        return {
          ...page,
          textsList: updatedTexts,
        };
      }
      return page;
    });
    setPages(newPages);
    const updatedTexts = [
      ...currentPage.textsList,
      {
        zIndex: 0,
        rotate: 0,
        opacity: 1,
        show: true,
        bold: false,
        content: "",
        fontSize: 14,
        italic: false,
        underline: false,
        fontColor: "#000000",
        position: { x: 20, y: 20 },
        size: { width: 100, height: 100 },
        fontName: '"Times New Roman", Times, serif',
        id: (currentPage.textsList.length + 1).toString(),
      },
    ];
    setCurrentPage({ ...currentPage, textsList: updatedTexts });
  };
  // Add New Page shape
  const addNewPageShape = (id, type) => {
    const newShape = {
      rotate: 0,
      zIndex: 0,
      type: type,
      opacity: 1,
      show: true,
      borderWidth: 1,
      bgColor: "#000000",
      borderStyle: "solid",
      borderColor: "#000000",
      position: { x: 20, y: 20 },
      size: { width: 100, height: 100 },
      borderRadius: type === "circle" ? "50%" : 0,
    };
    const newPages = pages.map((page) => {
      if (page.id === id) {
        const updatedShapes = [
          ...page.shapesList,
          { ...newShape, id: (page.shapesList.length + 1).toString() },
        ];

        return {
          ...page,
          shapesList: updatedShapes,
        };
      }
      return page;
    });
    setPages(newPages);
    const updatedShapes = [
      ...currentPage.shapesList,
      { ...newShape, id: (currentPage.shapesList.length + 1).toString() },
    ];
    setCurrentPage({ ...currentPage, shapesList: updatedShapes });
  };
  // Delete Page shape
  const deletePageText = (id, shape_index) => {
    const newPages = pages.map((page) => {
      if (page.id === id) {
        const updatedTexts = [...page.textsList];
        updatedTexts.splice(shape_index, 1);
        return {
          ...page,
          textsList: updatedTexts,
        };
      }
      return page;
    });
    setPages(newPages);
    const updatedTexts = [...currentPage.textsList];
    updatedTexts.splice(shape_index, 1);
    setCurrentPage({
      ...currentPage,
      textsList: updatedTexts,
    });
  };
  // Delete Page Image
  const deletePageImage = (id, image_index) => {
    const newPages = pages.map((page) => {
      if (page.id === id) {
        const updatedImages = [...page?.imagesList];
        updatedImages.splice(image_index, 1);
        return {
          ...page,
          imagesList: updatedImages,
        };
      }
      return page;
    });
    setPages(newPages);
    const updatedImages = [...currentPage?.imagesList];
    updatedImages.splice(image_index, 1);
    setCurrentPage({
      ...currentPage,
      imagesList: updatedImages,
    });
  };
  // Delete Page shape
  const deletePageShape = (id, shape_index) => {
    const newPages = pages.map((page) => {
      if (page.id === id) {
        const updatedShapes = [...page.shapesList];
        updatedShapes.splice(shape_index, 1);
        return {
          ...page,
          shapesList: updatedShapes,
        };
      }
      return page;
    });
    setPages(newPages);
    const updatedShapes = [...currentPage.shapesList];
    updatedShapes.splice(shape_index, 1);
    setCurrentPage({
      ...currentPage,
      shapesList: updatedShapes,
    });
  };
  //************ End Pages ************//
  //************ Catalog ************//
  // Handle Change Main Color
  const handleChangeMainColor = (value) => {
    setMainColor(value);
    setExportedCatalog({
      ...exportedCatalog,
      mainColor: value,
    });
  };
  // Delete Cover Image
  const deleteCoverImage = (e) => {
    setCoverImage(null);
    setCoverImagePreviewUrl(null);
    setExportedCatalog({
      ...exportedCatalog,
      coverImage: null,
    });
  };
  // Handle Cover Image Change
  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    setCoverImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCoverImagePreviewUrl(reader.result);
        setExportedCatalog({
          ...exportedCatalog,
          coverImage: reader.result,
        });
      };
      reader.readAsDataURL(file);
    } else {
      setCoverImagePreviewUrl("");
      setExportedCatalog({
        ...exportedCatalog,
        coverImage: null,
      });
    }
  };
  // Delete Back Cover Image
  const deleteBackCoverImage = (e) => {
    setBackCoverImage(null);
    setBackCoverImagePreviewUrl(null);
    setExportedCatalog({
      ...exportedCatalog,
      backCoverImage: null,
    });
  };
  // Handle Cover Image Change
  const handleBackCoverImageChange = (e) => {
    const file = e.target.files[0];
    setBackCoverImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBackCoverImagePreviewUrl(reader.result);
        setExportedCatalog({
          ...exportedCatalog,
          backCoverImage: reader.result,
        });
      };
      reader.readAsDataURL(file);
    } else {
      setBackCoverImagePreviewUrl("");
      setExportedCatalog({
        ...exportedCatalog,
        backCoverImage: null,
      });
    }
  };
  // Products List Grid System Based Products Length
  const setProductsListGridSystemBasedProductsLength = (productCount) => {
    if (productCount <= 2) {
      return { cols: "col-12", rows: 2 };
    } else if (productCount <= 4) {
      return { cols: "col-6", rows: 2 };
    } else if (productCount <= 6) {
      return { cols: "col-4", rows: 2 };
    } else {
      return { cols: "col-4", rows: 3 };
    }
  };
  // Divide Products
  const getProductsLengthBasedListGridSystem = (productsListGridSystem) => {
    const { cols, rows } = productsListGridSystem;
    switch (`${cols}-${rows}`) {
      case "col-4-3":
        return 9;
      case "col-4-2":
        return 6;
      case "col-6-2":
        return 4;
      case "col-12-2":
        return 2;
      default:
        return 0;
    }
  };
  // divide Products Into Every Page
  const divideProductsIntoEveryPage = (gridSystem, pageId) => {
    let currentPageNewProductsList = [];
    const updatedPages = pages.map((page) => ({ ...page, productsList: [] }));
    let currentIndex = 0;
    for (let page of updatedPages) {
      const numberOfProduct =
        pageId === page.id
          ? getProductsLengthBasedListGridSystem(gridSystem)
          : getProductsLengthBasedListGridSystem(page.productsListGridSystem);
      page.productsList = checkedProducts.slice(
        currentIndex,
        currentIndex + numberOfProduct
      );
      if (pageId === page.id) {
        page.productsListGridSystem = gridSystem;
        currentPageNewProductsList = page.productsList;
      }
      currentIndex += numberOfProduct;
    }
    setPages(updatedPages);
    setCurrentPage({
      ...currentPage,
      productsList: currentPageNewProductsList,
      productsListGridSystem: gridSystem,
    });
  };
  // divide Products
  const divideProducts = () => {
    setPages((prevPages) => {
      const customizedPages = prevPages.filter(
        (page) => page.pageType === "customized"
      );
      const customizedSortOrders = customizedPages.map(
        (page) => page.sortOrder
      );
      const chunkedProducts = chunkArray(
        checkedProducts,
        numberOfProductsPerPage
      );
      let newPages = [];
      for (let i = 0; i < chunkedProducts.length; i++) {
        const chunkedProduct = chunkedProducts[i];

        let sortOrder = i + 1;
        while (customizedSortOrders.includes(sortOrder)) {
          sortOrder++;
        }

        const additionData = {
          productDescriptionFontName: '"Times New Roman", Times, serif',
          productDescriptionSize: { width: 400, height: 50 },
          productDescriptionPosition: { x: 20, y: 250 },
          productDescriptionFontColor: "#000000",
          productDescriptionTextAlign: "left",
          productDescriptionUnderline: false,
          productDescriptionItalic: false,
          productDescriptionFontSize: 14,
          productDescriptionBold: false,
          productDescriptionShow: true,
          productDescriptionOpacity: 1,
          productDescriptionRotate: 0,
          productDescriptionZIndex: 1,

          productTitleFontName: '"Times New Roman", Times, serif',
          productTitleSize: { width: 200, height: 30 },
          productTitlePosition: { x: 20, y: 180 },
          productTitleFontColor: "#000000",
          productTitleTextAlign: "left",
          productTitleUnderline: false,
          productTitleItalic: false,
          productTitleFontSize: 14,
          productTitleBold: false,
          productTitleShow: true,
          productTitleOpacity: 1,
          productTitleRotate: 0,
          productTitleZIndex: 1,

          productQrCodeSize: { width: 100, height: 100 },
          productQrCodePosition: { x: 20, y: 20 },
          productQrCodeBgColor: "#000000",
          productQrCodeShow: true,
          productQrCodeOpacity: 1,
          productQrCodeRotate: 0,
          productQrCodeZIndex: 1,
        };

        const updatedChunkedProductWithAdditionData = chunkedProduct.map(
          (product) => ({
            ...product,
            additionData,
            label: product.en_name,
            value: product.product_id,
          })
        );

        newPages.push({
          productsListGridSystem: setProductsListGridSystemBasedProductsLength(
            updatedChunkedProductWithAdditionData.length
          ),
          numberOfProducts: updatedChunkedProductWithAdditionData.length,
          productsList: updatedChunkedProductWithAdditionData,
          imagesListGridSystem: "column",
          pageBackgroundColor: "#eeeeee",
          pageType: "non-customized",
          imagesListShow: false,
          id: generatePageID(),
          sortOrder: sortOrder,
          imagesList: [],
          shapesList: [],
          textsList: [],
        });
      }
      const mergedPages = [...customizedPages, ...newPages].sort(
        (a, b) => a.sortOrder - b.sortOrder
      );
      return mergedPages;
    });
  };
  // Preview Catalog
  const previewCatalog = () => {
    setRendered(false);
    const updatedPages = pages.map((page) => ({
      ...page,
      productsIDs: page.productsList
        .map((product) => product.product_id)
        .join(","),
    }));
    var productCatalog = {
      mode: "preview",
      id: existCatalogID,
      pages: updatedPages,
      coverData: coverData,
      mainColor: mainColor,
      catalogSize: catalogSize,
      orientation: orientation,
      pagesCount: pages.length,
      customerLogo: customerLogo,
      shortProfile: shortProfile,
      customerName: customerName,
      backCoverData: backCoverData,
      enCustomerName: customerName,
      coverImageSize: coverImageSize,
      designTemplate: designTemplate,
      coverImage: coverImagePreviewUrl,
      profileImage: profileImagePerview,
      profileImageSize: profileImageSize,
      coverImageOpacity: coverImageOpacity,
      coverImagePosition: coverImagePosition,
      categoryIcon: products[0]?.categoryIcon,
      profileImageOpacity: profileImageOpacity,
      backCoverImage: backCoverImagePreviewUrl,
      profileImagePosition: profileImagePosition,
      enCategoryName: products[0]?.enCategoryName,
      arCategoryName: products[0]?.arCategoryName,
      arCustomerName: products[0]?.arCustomerName,
      enCategoryDescription: products[0].description,
      profileBackgroundColor: profileBackgroundColor,
      backCoverDesignTemplate: backCoverDesignTemplate,
      numberOfProductsPerPage: numberOfProductsPerPage,
      categoryId: urlType === "category" ? urlID : null,
      customerId: urlType === "customer" ? urlID : null,
      coverShapesList: coverShapesList ? coverShapesList : [],
      profileShapesList: profileShapesList ? profileShapesList : [],
      slug:
        urlType === "category"
          ? slugify(products[0].name)
          : slugify(customerName),
    };
    setExportedCatalog(productCatalog);
    setCurrentCenterRender("catalog");
    setCurrentPageIndex(null);
    setCurrentTopRender(null);
    setCurrentPage(null);
    setTimeout(() => {
      setRendered(true);
    }, 100);
  };
  // handle Cover data
  const handleCoverData = (id, e, key) => {
    setCoverData((prevStyles) => ({
      ...prevStyles,
      [id]: {
        ...prevStyles[id],
        [key]:
          key === "fontSize"
            ? parseInt(e.target.value)
            : key === "rotate"
            ? e
            : key === "bold" || key === "italic" || key === "underline"
            ? !prevStyles[id][key]
            : key === "textAlign"
            ? e
            : e.target.value,
      },
    }));
  };
  // handle Back Cover data
  const handleBackCoverData = (id, e, key) => {
    setBackCoverData((prevStyles) => ({
      ...prevStyles,
      [id]: {
        ...prevStyles[id],
        [key]:
          key === "fontSize"
            ? parseInt(e.target.value)
            : key === "rotate"
            ? e
            : key === "bold" || key === "italic" || key === "underline"
            ? !prevStyles[id][key]
            : e.target.value,
      },
    }));
  };
  // Export Catalog
  const exportCatalog = async () => {
    const formData = new FormData();
    const slug =
      urlType === "category"
        ? slugify(exportedCatalog.enCategoryName)
        : slugify(exportedCatalog.enCustomerName);
    if (urlType === "seller") {
      formData.append("customerId", urlID);
    } else if (urlType === "category") {
      formData.append("categoryId", urlID);
    }
    formData.append("slug", slug);
    formData.append("mainColor", mainColor);
    formData.append("coverImage", coverImage);
    formData.append("orientation", orientation);
    formData.append("profileImage", profileImage);
    formData.append("existCatalogId", existCatalogID);
    formData.append("backCoverImage", backCoverImage);
    formData.append("coverImageSize", coverImageSize);
    formData.append("designTemplate", designTemplate);
    formData.append("profileImageSize", profileImageSize);
    formData.append("coverData", JSON.stringify(coverData));
    formData.append("coverImageOpacity", coverImageOpacity);
    formData.append("coverImagePosition", coverImagePosition);
    formData.append("profileData", JSON.stringify(profileData));
    formData.append("profileImageOpacity", profileImageOpacity);
    formData.append("catalogSize", JSON.stringify(catalogSize));
    formData.append("profileImagePosition", profileImagePosition);
    formData.append("backCoverData", JSON.stringify(backCoverData));
    formData.append("profileBackgroundColor", profileBackgroundColor);
    formData.append("backCoverDesignTemplate", backCoverDesignTemplate);
    formData.append("coverShapesList", JSON.stringify(coverShapesList));
    formData.append("numberOfProductsPerPage", numberOfProductsPerPage);
    formData.append("profileShapesList", JSON.stringify(profileShapesList));
    const updatedPages = pages.map((page) => ({
      ...page,
      productsList: page.productsList.map((product) => ({
        ...product,
        width: null,
        image: null,
        weight: null,
        length: null,
        height: null,
        avatar: null,
        en_name: null,
        ar_name: null,
        category_id: null,
        shortProfile: null,
        enCustomerName: null,
        arCustomerName: null,
        en_description: null,
        ar_description: null,
        membership_level: null,
        membership_level_list: null,
      })),
      productsIDs: page.productsList
        .map((product) => product.product_id)
        .join(","),
    }));
    formData.append("pages", JSON.stringify(updatedPages));
    try {
      const response = await createCatalog(formData);
      if (response) {
        toast.success("The Catalog Saved Successfully");
        // setTimeout(() => {
        //   window.open(
        //     `${API_BASE_URL}/catalog/${response.slug}`,
        //     "_blank"
        //   );
        // }, 3000);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  //************ End Catalog ************//
  // Decode Html Entities
  const decodeHtmlEntities = (text) => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    return textArea.value;
  };
  // hange Current Page
  const changecurrentPage = (page, index) => {
    setCurrentPage(page);
    setCurrentPageIndex(index);
  };
  // get render menu clicking
  const renderTopComponent = () => {
    switch (currentTopRender) {
      case "config":
        return (
          <CatalogConfig
            mainColor={mainColor}
            addNewShape={addNewShape}
            catalogSize={catalogSize}
            orientation={orientation}
            coverImageSize={coverImageSize}
            setCatalogSize={setCatalogSize}
            setOrientation={setOrientation}
            designTemplate={designTemplate}
            deleteCoverImage={deleteCoverImage}
            setCoverImageSize={setCoverImageSize}
            setDesignTemplate={setDesignTemplate}
            coverImageOpacity={coverImageOpacity}
            coverImagePosition={coverImagePosition}
            coverImagePreviewUrl={coverImagePreviewUrl}
            setCoverImageOpacity={setCoverImageOpacity}
            setCoverImagePosition={setCoverImagePosition}
            handleChangeMainColor={handleChangeMainColor}
            handleCoverImageChange={handleCoverImageChange}
          />
        );
      case "profile":
        return (
          <ProfileConfig
            addNewShape={addNewShape}
            profileImageSize={profileImageSize}
            deleteProfileImage={deleteProfileImage}
            profileImageOpacity={profileImageOpacity}
            profileImagePerview={profileImagePerview}
            setProfileImageSize={setProfileImageSize}
            profileImagePosition={profileImagePosition}
            setProfileImageOpacity={setProfileImageOpacity}
            profileBackgroundColor={profileBackgroundColor}
            setProfileImagePosition={setProfileImagePosition}
            handleProfileImageChange={handleProfileImageChange}
            setProfileBackgroundColor={setProfileBackgroundColor}
          />
        );
      case "page":
        return (
          <PageConfig
            products={products}
            currentPage={currentPage}
            addNewPageText={addNewPageText}
            addNewPageImage={addNewPageImage}
            addNewPageShape={addNewPageShape}
            deletePageImage={deletePageImage}
            deletePageShape={deletePageShape}
            onDragEndSortImages={onDragEndSortImages}
            handlePageImagesChange={handlePageImagesChange}
            handleChangePageContent={handleChangePageContent}
            handleProductsListGridSystemChange={
              handleProductsListGridSystemChange
            }
            handleAddRemoveProductFromToPageProductsList={
              handleAddRemoveProductFromToPageProductsList
            }
          />
        );
      case "back":
        return (
          <BackCoverConfig
            mainColor={mainColor}
            deleteBackCoverImage={deleteBackCoverImage}
            handleChangeMainColor={handleChangeMainColor}
            backCoverImagePreviewUrl={backCoverImagePreviewUrl}
            handleBackCoverImageChange={handleBackCoverImageChange}
            backCoverDesignTemplate={backCoverDesignTemplate}
            setBackCoverDesignTemplate={setBackCoverDesignTemplate}
          />
        );
      default:
        return <div />;
    }
  };
  // get render menu clicking
  const renderCenterComponent = () => {
    switch (currentCenterRender) {
      case "catalog":
        return (
          rendered && (
            <div className="d-flex justify-content-center h-100 overflow-hidden">
              <Catalog
                index={0}
                source="catalogPage"
                coverData={coverData}
                profileData={profileData}
                book={exportedCatalog.id}
                slug={exportedCatalog.slug}
                setCoverData={setCoverData}
                backCoverData={backCoverData}
                catalogId={exportedCatalog.id}
                setProfileData={setProfileData}
                openBook={() => console.log("")}
                closeBook={() => console.log("")}
                productsCatalog={exportedCatalog}
                handleCoverData={handleCoverData}
                navClassName="perviewCatalogFromCreate"
              />
            </div>
          )
        );
      case "page":
        return (
          <PageContent
            mode="editable"
            page={currentPage}
            source="catalogPage"
            pagesCount={pages.length}
            deletePageText={deletePageText}
            deletePageImage={deletePageImage}
            deletePageShape={deletePageShape}
            pageNumber={currentPageIndex + 1}
            productsCatalog={exportedCatalog}
            setCurrentTopRender={setCurrentTopRender}
            catalogSize={exportedCatalog?.catalogSize}
            handlePageImagesChange={handlePageImagesChange}
            handleChangePageContent={handleChangePageContent}
            numberOfProductsPerPage={numberOfProductsPerPage}
          />
        );
      case "cover":
        return (
          <div className={`${designTemplate} ${orientation}`}>
            <Cover
              mode="editable"
              source="catalogPage"
              fontNames={fontNames}
              coverData={coverData}
              setCoverData={setCoverData}
              handleCoverData={handleCoverData}
              productsCatalog={exportedCatalog}
              setCurrentTopRender={setCurrentTopRender}
              deleteFromShapesList={deleteFromShapesList}
              handleChangeShapesList={handleChangeShapesList}
            />
          </div>
        );
      case "profile":
        return (
          <Profile
            mode="editable"
            source="catalogPage"
            profileData={profileData}
            setProfileData={setProfileData}
            productsCatalog={exportedCatalog}
            handleProfileData={handleProfileData}
            profileShapesList={profileShapesList}
            deleteFromShapesList={deleteFromShapesList}
            handleChangeShapesList={handleChangeShapesList}
          />
        );
      case "back":
        return (
          <BackCover
            mode="editable"
            source="catalogPage"
            backCoverData={backCoverData}
            productsCatalog={exportedCatalog}
            setBackCoverData={setBackCoverData}
            handleBackCoverData={handleBackCoverData}
            backCoverDesignTemplate={backCoverDesignTemplate}
          />
        );
      default:
        return <div />;
    }
  };
  return (
    <div className="container-fluid create-catalog">
      <ToastContainer />
      <div className="row">
        <div className="col-1">
          <div className="left-fixed-navbar">
            <Nav className="flex-column justify-content-evenly h-100">
              <div
                className="w-100 selected-left-item"
                style={{ backgroundColor: "transparent" }}
              >
                <img
                  className="logo-image d-block"
                  alt="customerLogo"
                  src={"https://sinaha.ae/image/" + customerLogo}
                />
              </div>
              <div className="w-100 selected-left-item">
                <img
                  src="/assets/images/sinaha-smart-catalog.png"
                  className="logo-image d-block"
                  alt="Bootstrap Themes"
                  width="700"
                  height="500"
                  loading="lazy"
                />
              </div>
              <ProductsConfig
                products={products}
                isLoading={isLoading}
                isAllChecked={isAllChecked}
                divideProducts={divideProducts}
                checkedProducts={checkedProducts}
                decodeHtmlEntities={decodeHtmlEntities}
                currentCenterRender={currentCenterRender}
                numberOfProductsPerPage={numberOfProductsPerPage}
                setNumberOfProductsPerPage={setNumberOfProductsPerPage}
                handleProductsCheckboxChange={handleProductsCheckboxChange}
                handleProductsSelectAllChange={handleProductsSelectAllChange}
              />
              <button
                className={`btn btn-primary rounded-0 ${
                  currentCenterRender === "catalog" && "active"
                }`}
                onClick={() => previewCatalog()}
              >
                <FontAwesomeIcon icon={faEye} /> &nbsp; Preview
              </button>
              <button
                onClick={() => exportCatalog()}
                className={`btn btn-primary rounded-0 ${
                  currentCenterRender === "export" && "active"
                }`}
              >
                <FontAwesomeIcon icon={faFileExport} /> &nbsp; Export
              </button>
            </Nav>
          </div>
        </div>
        <div className="col-10">
          <div className="top-fixed-navbar">{renderTopComponent()}</div>
          <div className="center-content">{renderCenterComponent()}</div>
        </div>
        <div className="col-1">
          <div className="d-flex flex-column justify-content-evenly align-items-center flex-nowrap h-100 right-fixed-navbar">
            {exportedCatalog && rendered && (
              <div className="pages-content">
                <div className="pages-scrollable-content">
                  <div
                    className={`${designTemplate} ${orientation} perview page-button ${
                      currentCenterRender === "cover" && "active"
                    }`}
                    onClick={() => {
                      setCurrentCenterRender("cover");
                      setCurrentTopRender("config");
                      setCurrentPageIndex(null);
                      setCurrentPage(null);
                    }}
                  >
                    <Cover
                      mode="smallView"
                      source="catalogPage"
                      coverData={coverData}
                      productsCatalog={exportedCatalog}
                    />
                  </div>
                  <div
                    className={`page-button ${
                      currentCenterRender === "profile" && "active"
                    }`}
                    onClick={() => {
                      setCurrentCenterRender("profile");
                      setCurrentTopRender("profile");
                      setCurrentPageIndex(null);
                      setCurrentPage(null);
                    }}
                  >
                    <Profile
                      mode="smallView"
                      source="catalogPage"
                      profileData={profileData}
                      productsCatalog={exportedCatalog}
                    />
                  </div>
                  <DragDropContext onDragEnd={onDragEndSortPages}>
                    <Droppable droppableId="pages">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="droppable-column"
                        >
                          {pages &&
                            pages.map((page, index) => {
                              return (
                                <Draggable
                                  bounds="parent"
                                  key={page.id}
                                  draggableId={page.id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className="draggable-item"
                                    >
                                      <div
                                        className={`page-button ${
                                          currentPageIndex === index && "active"
                                        }`}
                                      >
                                        <a
                                          className="remove-page"
                                          onClick={() => handleDelete(page.id)}
                                        >
                                          <FontAwesomeIcon icon={faX} />
                                        </a>
                                        <div
                                          className="w-100 h-100"
                                          onClick={() => {
                                            setCurrentCenterRender("page");
                                            setCurrentTopRender("page");
                                            changecurrentPage(page, index);
                                          }}
                                        >
                                          <PageContent
                                            page={page}
                                            mode="smallView"
                                            source="catalogPage"
                                            pageNumber={index + 1}
                                            pagesCount={pages.length}
                                            productsCatalog={exportedCatalog}
                                            numberOfProductsPerPage={
                                              numberOfProductsPerPage
                                            }
                                            catalogSize={
                                              exportedCatalog?.catalogSize
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <div
                    className={`page-button ${
                      currentCenterRender === "back" && "active"
                    }`}
                    onClick={() => {
                      setCurrentCenterRender("back");
                      setCurrentTopRender("back");
                      setCurrentPageIndex(null);
                      setCurrentPage(null);
                    }}
                  >
                    <BackCover
                      mode="smallView"
                      source="catalogPage"
                      backCoverData={backCoverData}
                      productsCatalog={exportedCatalog}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="pages-footer">
              <button
                className="pages-new btn btn-success"
                onClick={() => addPage()}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        show={showConfirm}
        onCancel={handleCancel}
        onConfirm={() => removePage(currentDeletedPage)}
        message={t("Are you sure you want to delete the page?")}
      />
    </div>
  );
};

export default CreateCatalog;
