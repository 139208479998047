import { faEye, faEyeSlash, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  capitalizeWordsNoSpaces,
  capitalizeWordsNoSpacesLowerFirst,
  convertToKebabCase,
} from "../../../config/_global";

function Button(props) {
  return (
    <label className="tools-item">
      <span>{props?.title}</span>
      <button
        title={capitalizeWordsNoSpacesLowerFirst(props?.title)}
        className={`${convertToKebabCase(props?.title)}-button ${
          props?.page?.productsList?.find(
            (product) => product.product_id === props?.currentProductId
          )?.additionData[
            `${props?.currentBoxId.split("-")[0]}${capitalizeWordsNoSpaces(
              props?.title
            )}`
          ]
            ? "active"
            : ""
        }`}
        onClick={
          props?.title === "Delete"
            ? props?.currentBoxId.includes("shape-")
              ? () => {
                  props?.deletePageShape(props?.page.id, props?.currentIndex);
                  props?.clearSelectedTools();
                }
              : props?.currentBoxId.includes("image-")
              ? () => {
                  props?.deletePageImage(props?.page.id, props?.currentIndex);
                  props?.clearSelectedTools();
                }
              : () => {
                  props?.deletePageText(props?.page.id, props?.currentIndex);
                  props?.clearSelectedTools();
                }
            : props?.currentBoxId.includes("image-") ||
              props?.currentBoxId.includes("shape-") ||
              props?.currentBoxId.includes("text-")
            ? () => {
                props?.handleChangePageContent(
                  props?.currentPageId,
                  props?.currentPageKey,
                  null,
                  props?.currentIndex,
                  capitalizeWordsNoSpacesLowerFirst(props?.title)
                );
                if (props?.currentBoxId.includes("text-")) {
                  props?.applyStyle(props?.command);
                }
              }
            : () =>
                props?.handleChangePageContent(
                  props?.currentPageId,
                  props?.currentPageKey,
                  null,
                  props?.currentProductId,
                  "additionData",
                  null,
                  null,
                  `${
                    props?.currentBoxId.split("-")[0]
                  }${capitalizeWordsNoSpaces(props?.title)}`
                )
        }
      >
        {props?.title === "Show" ? (
          <FontAwesomeIcon
            icon={
              props?.currentBoxId.includes("image-") ||
              props?.currentBoxId.includes("shape-") ||
              props?.currentBoxId.includes("text-")
                ? props?.page[props?.currentPageKey]?.find(
                    (item, index) => index === props?.currentIndex
                  )?.[capitalizeWordsNoSpacesLowerFirst(props?.title)]
                  ? faEye
                  : faEyeSlash
                : props?.page?.productsList?.find(
                    (product) => product.product_id === props?.currentProductId
                  )?.additionData[
                    `${
                      props?.currentBoxId.split("-")[0]
                    }${capitalizeWordsNoSpaces(props?.title)}`
                  ]
                ? faEye
                : faEyeSlash
            }
          />
        ) : props?.title === "Delete" ? (
          <FontAwesomeIcon icon={faTrash} />
        ) : (
          <>{props?.symbol}</>
        )}
      </button>
    </label>
  );
}

export default Button;
