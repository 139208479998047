import React, { useRef, useState, useEffect, useTransition } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API_BASE_URL_WITH_PORT } from "../config/url";
import { useTranslation } from "react-i18next";
import Catalog from "./catalog";
import Header from "./header";
import {
  faPaperPlane,
  faSortUp,
  faCalendar,
  faClock,
  faLocationPin,
} from "@fortawesome/free-solid-svg-icons";
import { fetchById } from "../api/events";
import { useParams } from "react-router-dom";

const useScrollAboutMark = (id) => {
  const aboutMarkRef = useRef(null);
  const aboutMarkExecuteScroll = () =>
    aboutMarkRef.current.scrollIntoView({ behavior: "smooth" });
  return [aboutMarkExecuteScroll, aboutMarkRef];
};

const useScrollAggregator = (id) => {
  const aggregatorRef = useRef(null);
  const aggregatorExecuteScroll = () =>
    aggregatorRef.current.scrollIntoView({ behavior: "smooth" });
  return [aggregatorExecuteScroll, aggregatorRef];
};

const useScrollContact = (id) => {
  const contactRef = useRef(null);
  const contactExecuteScroll = () =>
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  return [contactExecuteScroll, contactRef];
};

function Event() {
  const [aggregatorExecuteScroll, aggregatorRef] = useScrollAggregator();
  const [aboutMarkExecuteScroll, aboutMarkRef] = useScrollAboutMark();
  const [contactExecuteScroll, contactRef] = useScrollContact();

  const [productsCatalogList, setProductsCatalogList] = useState([]);
  const [currentType, setCurrentType] = useState("Factories");
  const [isPending, startTransition] = useTransition();
  const [openedBook, setOpenedBook] = useState(null);
  const [eventData, setEventData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [logos, setLogos] = useState([]);
  const { t, i18n } = useTranslation();
  const { slug } = useParams();

  useEffect(() => {
    fetchDataFromAPI();
  }, [currentType]);

  // Chunk array using reduce
  const chunkArray = (arr, chunkSize) => {
    return arr.reduce((acc, item, index) => {
      const chunkIndex = Math.floor(index / chunkSize);
      if (!acc[chunkIndex]) {
        acc[chunkIndex] = [];
      }
      acc[chunkIndex].push(item);
      return acc;
    }, []);
  };

  const fetchDataFromAPI = async () => {
    try {
      startTransition(async () => {
        setLoading(true);
        var getEventDataWithCatalogs = await fetchById(slug);
        var getEventData = getEventDataWithCatalogs;
        setLogos(JSON.parse(getEventData.logos));
        setEventData(getEventData);
        var catalogs = getEventDataWithCatalogs.eventscatalogs;
        catalogs = catalogs.map((catalog) => catalog.catalogs);
        const parsedCatalogs = catalogs.map((catalog) => ({
          ...catalog,
          coverImage:
            catalog?.coverImage &&
            (catalog?.coverImage?.includes("data:image")
              ? catalog?.coverImage
              : `${API_BASE_URL_WITH_PORT}/${catalog?.coverImage?.replace(
                  /\\/g,
                  "/"
                )}`),
          coverData: catalog?.coverData && JSON.parse(catalog?.coverData),
          profileData: catalog?.profileData && JSON.parse(catalog?.profileData),
          catalogSize: catalog?.catalogSize && JSON.parse(catalog?.catalogSize),
          backCoverData:
            catalog?.backCoverData && JSON.parse(catalog?.backCoverData),
          coverShapesList: JSON.parse(catalog?.coverShapesList),
          profileShapesList: JSON.parse(catalog?.profileShapesList),
        }));
        let all = [];
        // if (slug === "innovation-tank-hub") {
        // all = chunkArray(parsedCatalogs, 5);
        // } else {
        all = chunkArray(parsedCatalogs, 4);
        // }
        setProductsCatalogList(all);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const openBook = (book) => {
    setOpenedBook(book);
  };

  const closeBook = () => {
    setOpenedBook(null);
  };

  return (
    <>
      {eventData && (
        <div className="main">
          <Header
            aboutMarkExecuteScroll={aboutMarkExecuteScroll}
            aggregatorExecuteScroll={aggregatorExecuteScroll}
            contactExecuteScroll={contactExecuteScroll}
          />

          <div
            className="event-header"
            style={{
              backgroundImage: `url('/assets/images/${eventData.slug}/${eventData.headerImage}')`,
            }}
          >
            <div className="event-title-dates">
              <h1 className="event-title">
                {i18n.language === "ar" ? eventData.arTitle : eventData.enTitle}
              </h1>
              <h6 className="mt-4">
                <span className="fs-6">
                  <FontAwesomeIcon icon={faCalendar} />
                </span>{" "}
                {i18n.language === "ar" ? eventData.arDate : eventData.enDate}
              </h6>
              {eventData.arTime ||
                (eventData.enTime && (
                  <h6 className="">
                    <span className="fs-6">
                      <FontAwesomeIcon icon={faClock} />
                    </span>{" "}
                    {i18n.language === "ar"
                      ? eventData.arTime
                      : eventData.enTime}
                  </h6>
                ))}
              <h6 className="">
                <span className="fs-6">
                  <FontAwesomeIcon icon={faLocationPin} />
                </span>{" "}
                {i18n.language === "ar"
                  ? eventData.arLocation
                  : eventData.enLocation}
              </h6>
            </div>
          </div>

          <div
            className="container my-5 event-header-description"
            ref={aboutMarkRef}
          >
            <div
              dangerouslySetInnerHTML={{
                __html:
                  i18n.language === "ar"
                    ? eventData.arDescription
                    : eventData.enDescription,
              }}
            />
            <div className="row text-center align-items-center my-5">
              {logos &&
                logos.map((logo, index) => (
                  <div
                    className={
                      eventData.slug == "innovation-tank-hub"
                        ? "col-md-4"
                        : "col-md-3"
                    }
                    key={index}
                  >
                    <img
                      src={`/assets/images/${eventData.slug}/${logo}`}
                      className="event-logo"
                      style={{ objectFit: "contain" }}
                    />
                  </div>
                ))}
            </div>
          </div>

          <hr className="featurette-divider" />

          <section
            className="marketing aggregator home event mb-5"
            ref={aggregatorRef}
            style={{
              height: "auto",
            }}
          >
            <div className="container">
              <div className="d-flex justify-content-between">
                <h2 className="section-title">{t("aggregator")}</h2>
              </div>

              {isLoading ? (
                <h6>{t("loading")} ...</h6>
              ) : (
                <>
                  {productsCatalogList.map((products, i) => {
                    return (
                      products.length > 0 && (
                        <div className="shelf-books" key={i}>
                          <div className="catalgos">
                            <div className="overlay-bg"></div>
                            <div
                              className={`row g-0 ${
                                // eventData.slug == "innovation-tank-hub"
                                //   ? ""
                                //   : 
                                  "eventRow"
                              }`}
                            >
                              {products.map((productsCatalog, j) => {
                                return (
                                  <div
                                    className={
                                      // eventData.slug == "innovation-tank-hub"
                                      //   ? "col-md-2"
                                      //   :
                                      "col-md-3"
                                    }
                                    key={j}
                                  >
                                    <Catalog
                                      index={j}
                                      source="home"
                                      book={openedBook}
                                      openBook={openBook}
                                      closeBook={closeBook}
                                      slug={productsCatalog?.slug}
                                      catalogId={productsCatalog?.id}
                                      productsCatalog={productsCatalog}
                                      coverData={productsCatalog?.coverData}
                                      profileData={productsCatalog?.profileData}
                                      orientation={productsCatalog?.orientation}
                                      backCoverData={
                                        productsCatalog?.backCoverData
                                      }
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )
                    );
                  })}
                </>
              )}
            </div>
          </section>

          <footer className="w-100 py-4 flex-shrink-0 bg-header-dark">
            <div className="container py-4">
              <div className="row gy-4 gx-5">
                <div className="col-lg-4 col-md-6">
                  <h5 className="h1 text-white">
                    <img
                      src="/assets/images/sinaha-smart-catalog-white.png"
                      className="w-100 h-auto"
                      alt="Bootstrap Themes"
                      width="700"
                      height="500"
                      loading="lazy"
                    />
                  </h5>
                  <p className="small text-white mb-0">
                    &copy; 2024 {t("sinahaPlatform")}
                  </p>
                </div>
                <div className="col-lg-2 col-md-6">
                  <h5 className="text-white mb-3">{t("quickLinks")}</h5>
                  <ul className="list-unstyled text-white p-0">
                    <li className="mb-2">
                      <a
                        href="https://sinaha.ae/en/invest-in-uae"
                        target="_blank"
                      >
                        {t("investInUAE")}
                      </a>
                    </li>
                    <li className="mb-2">
                      <a
                        href="https://sinahaqt.sinaha.ae/forms/ticket?styled=1&with_logo=1"
                        target="_blank"
                      >
                        {t("support")}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-2 col-md-6">
                  <h5 className="text-white mb-3">{t("quickLinks")}</h5>
                  <ul className="list-unstyled text-white p-0">
                    <li className="mb-2">
                      <a
                        href="https://madeintheemirates.sinaha.ae"
                        target="_blank"
                      >
                        {t("madeInTheEmirates")}
                      </a>
                    </li>
                    <li className="mb-2">
                      <a href="https://sinaha.ae" target="_blank">
                        {t("sinahaPlatform")}
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  className="col-lg-4 col-md-6"
                  style={
                    i18n.language === "ar"
                      ? {
                          borderRight: "1px solid #fff",
                        }
                      : {
                          borderLeft: "1px solid #fff",
                        }
                  }
                >
                  <h5 className="text-white mb-3">{t("newsletter")}</h5>
                  <p className="small text-white">
                    {t("newsletterDescription")}
                  </p>
                  <a href="#" className="go-up">
                    <FontAwesomeIcon icon={faSortUp} />
                  </a>
                  <form action="#">
                    <div className="input-group mb-3">
                      <input
                        className={
                          (i18n.language === "ar" && " border-radius-ar-2 ") +
                          " form-control "
                        }
                        type="text"
                        placeholder={t("enterEmail")}
                        aria-label={t("enterEmail")}
                        aria-describedby="button-addon2"
                      />
                      <button
                        className={
                          (i18n.language === "ar" && "border-radius-ar ") +
                          " btn btn-success "
                        }
                        id="button-addon2"
                        type="button"
                      >
                        <FontAwesomeIcon icon={faPaperPlane} />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </footer>
        </div>
      )}
    </>
  );
}

export default Event;
