import api from "../config/api";

// Function to fetch all events
export const fetchAllEvents = async () => {
  try {
    const response = await api.get(`/events`);
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

// Function to fetch a specific catalog by ID
export const fetchById = async (slug) => {
  try {
    const response = await api.get(`/events/${slug}`);
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};
