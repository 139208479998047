import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomMultiSelectDropdown from "./customMultiSelectDropdown";
import { faImage, faPlus } from "@fortawesome/free-solid-svg-icons";

function PageConfig(props) {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [optionSelected, setSelected] = useState([]);
  const additionData = {
    productDescriptionFontName: '"Times New Roman", Times, serif',
    productDescriptionSize: { width: 400, height: 50 },
    productDescriptionPosition: { x: 20, y: 250 },
    productDescriptionFontColor: "#000000",
    productDescriptionTextAlign: "left",
    productDescriptionUnderline: false,
    productDescriptionItalic: false,
    productDescriptionFontSize: 14,
    productDescriptionBold: false,
    productDescriptionShow: true,
    productDescriptionOpacity: 1,
    productDescriptionRotate: 0,
    productDescriptionZIndex: 1,

    productTitleFontName: '"Times New Roman", Times, serif',
    productTitleSize: { width: 200, height: 30 },
    productTitlePosition: { x: 20, y: 180 },
    productTitleFontColor: "#000000",
    productTitleTextAlign: "left",
    productTitleUnderline: false,
    productTitleItalic: false,
    productTitleFontSize: 14,
    productTitleBold: false,
    productTitleShow: true,
    productTitleOpacity: 1,
    productTitleRotate: 0,
    productTitleZIndex: 1,

    productQrCodeSize: { width: 100, height: 100 },
    productQrCodePosition: { x: 20, y: 20 },
    productQrCodeBgColor: "#000000",
    productQrCodeShow: true,
    productQrCodeOpacity: 1,
    productQrCodeRotate: 0,
    productQrCodeZIndex: 1,
  };

  useEffect(() => {
    if (props.currentPage?.productsList) {
      const allHaveProductId = props.currentPage?.productsList.every(
        (product) => product.hasOwnProperty("product_id")
      );
      var selectedOptions;
      if (allHaveProductId) {
        selectedOptions = props.currentPage?.productsList.map((product) => ({
          ...product,
          label: product.en_name,
          value: product.product_id,
          additionData,
        }));
      } else {
        selectedOptions = props.currentPage?.productsList;
      }
      if (selectedOptions) {
        setSelected(selectedOptions);
      }
    } else {
      setSelected([]);
    }
  }, [props.currentPage]);

  useEffect(() => {
    if (props.products) {
      var customizedOptions = props.products.map((product) => ({
        ...product,
        label: product.en_name,
        value: product.product_id,
        additionData,
      }));
      if (customizedOptions) {
        setOptions(customizedOptions);
      }
    } else {
      setOptions([]);
    }
  }, []);

  const handleChange = (selected) => {
    setSelected(selected);
    props.handleAddRemoveProductFromToPageProductsList(
      props.currentPage?.id,
      selected
    );
  };

  return (
    <div className="d-flex justify-content-around align-items-center h-100 w-100 selected">
      <div className="item">
        <Form.Label>{t("select page color")}</Form.Label>
        <Form.Control
          type="color"
          id="mainColor"
          style={{
            width: "150px",
          }}
          value={props.currentPage?.pageBackgroundColor || "#eeeeee"}
          onChange={(e) =>
            props.handleChangePageContent(
              props.currentPage?.id,
              "pageBackgroundColor",
              e.target.value
            )
          }
        />
      </div>
      <div className="item">
        <Form.Label>{t("shapes list")}</Form.Label>
        <div className="d-flex">
          <div
            className="line"
            onClick={() => props.addNewPageShape(props.currentPage?.id, "line")}
          ></div>
          <div
            className="circle"
            onClick={() =>
              props.addNewPageShape(props.currentPage?.id, "circle")
            }
          ></div>
          <div
            className="square"
            onClick={() =>
              props.addNewPageShape(props.currentPage?.id, "square")
            }
          ></div>
          <div
            className="triangle"
            onClick={() =>
              props.addNewPageShape(props.currentPage?.id, "triangle")
            }
          ></div>
          <div
            className="text"
            onClick={() => props.addNewPageText(props.currentPage?.id)}
          >
            T
          </div>
        </div>
      </div>
      <div className="item">
        <Form.Label>{t("images")}</Form.Label>
        <div>
          <button
            onClick={() => props.addNewPageImage(props.currentPage?.id)}
            className="btn btn-success"
          >
            <FontAwesomeIcon icon={faPlus} />
            {"  "}
            <FontAwesomeIcon icon={faImage} />
          </button>
        </div>
      </div>
      {props.currentPage?.imagesList?.length > 0 && (
        <div className="item">
          <Form.Label>{t("Divid Images")}</Form.Label>
          <select
            className="form-control"
            defaultValue={props.currentPage?.imagesListGridSystem}
            onChange={(e) =>
              props.handleChangePageContent(
                props.currentPage?.id,
                "imagesListGridSystem",
                e.target.value
              )
            }
          >
            <option></option>
            <option value="row">{t("rows")}</option>
            <option value="column">{t("columns")}</option>
            <option value="customize">{t("customize")}</option>
          </select>
        </div>
      )}
      {props.currentPage?.productsList?.length > 0 && (
        <div className="item">
          <Form.Label>{t("Select type")}</Form.Label>
          <div>
            <Form.Check
              inline
              type="radio"
              name="pageType"
              value="non-customized"
              label={t("products list")}
              checked={props.currentPage?.pageType === "non-customized"}
              onChange={(e) =>
                props.handleChangePageContent(
                  props.currentPage?.id,
                  "pageType",
                  e.target.value
                )
              }
            />
            <Form.Check
              inline
              type="radio"
              name="pageType"
              value="customized"
              label={t("customized")}
              checked={props.currentPage?.pageType === "customized"}
              onChange={(e) =>
                props.handleChangePageContent(
                  props.currentPage?.id,
                  "pageType",
                  e.target.value
                )
              }
            />
          </div>
        </div>
      )}
      {props.currentPage?.productsList?.length > 0 &&
        props.currentPage?.pageType === "non-customized" && (
          <div className="item">
            <Form.Label>
              {t("rows")} × {t("columns")}
            </Form.Label>
            <select
              className="form-control"
              value={JSON.stringify(props.currentPage?.productsListGridSystem)}
              onChange={(e) =>
                props.handleProductsListGridSystemChange(
                  e,
                  props.currentPage?.id
                )
              }
            >
              <option value={JSON.stringify({ cols: "col-4", rows: 3 })}>
                3 × 3
              </option>
              <option value={JSON.stringify({ cols: "col-4", rows: 2 })}>
                3 × 2
              </option>
              <option value={JSON.stringify({ cols: "col-6", rows: 2 })}>
                2 × 2
              </option>
              <option value={JSON.stringify({ cols: "col-12", rows: 2 })}>
                1 × 2
              </option>
            </select>
          </div>
        )}
      <div>
        <Form.Label>{t("product")}</Form.Label>
        <CustomMultiSelectDropdown
          type="products"
          key="example_id"
          options={options}
          isSelectAll={true}
          value={optionSelected}
          onChange={handleChange}
          menuPlacement={"bottom"}
        />
      </div>
    </div>
  );
}

export default PageConfig;
