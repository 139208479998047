import Draggable from "react-draggable";
import ProductModal from "./productModal";
import { decodeHtml } from "./decodeHtml";
import RotatableDiv from "./RotatableDiv";
import Input from "../create/tools/input";
import Align from "../create/tools/align";
import Select from "../create/tools/select";
import Button from "../create/tools/button";
import TextEditor from "../create/textEditor";
import { useTranslation } from "react-i18next";
import { ResizableBox } from "react-resizable";
import { extractTextFromHtml } from "./extractText";
import { API_BASE_URL_WITH_PORT } from "../../config/url";
import React, { useState, useEffect, useRef } from "react";
import { calculateCatalogSize } from "../../config/_global";
import PageProductsListConfig from "./pageProductsListConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsUpDownLeftRight } from "@fortawesome/free-solid-svg-icons";

// Font Names
const fontNames = [
  { "": "" },
  { Georgia: '"Georgia", serif' },
  { Papyrus: "Papyrus, fantasy" },
  { Cairo: '"Cairo", sans-serif' },
  { Tahoma: "Tahoma, Geneva, sans-serif" },
  { Arial: "Arial, Helvetica, sans-serif" },
  { Verdana: "Verdana, Geneva, sans-serif" },
  { Impact: "Impact, Charcoal, sans-serif" },
  { Helvetica: "Helvetica, Arial, sans-serif" },
  { Courier: '"Courier New", Courier, monospace' },
  { Lucida: '"Lucida Console", Monaco, monospace' },
  { Consolas: 'Consolas, "Courier New", monospace' },
  { "Brush Script MT": '"Brush Script MT", cursive' },
  { Trebuchet: '"Trebuchet MS", Helvetica, sans-serif' },
  { "Times New Roman": '"Times New Roman", Times, serif' },
  { "Comic Sans MS": '"Comic Sans MS", cursive, sans-serif' },
  { Palatino: '"Palatino Linotype", "Book Antiqua", Palatino, serif' },
];

const ResizableAndDraggableBox = ({
  x,
  y,
  id,
  show,
  page,
  width,
  index,
  height,
  rotate,
  zIndex,
  opacity,
  content,
  pageKey,
  productId,
  setShowTools,
  currentBoxId,
  setCurrentBoxId,
  setCurrentIndex,
  setCurrentPageId,
  setCurrentPageKey,
  setCurrentProductId,
  handleChangePageContent,
}) => {
  const [axis, setAxis] = useState("both");

  const handleDrag = (e, data) => {
    if (e.shiftKey) {
      const deltaX = Math.abs(e.movementX);
      const deltaY = Math.abs(e.movementY);
      if (deltaX > deltaY) {
        setAxis("x");
      } else {
        setAxis("y");
      }
    } else {
      setAxis("both");
    }
  };

  const newID = id.includes("productDescription")
    ? "productDescription"
    : id.includes("productTitle")
    ? "productTitle"
    : id.includes("productQrCode")
    ? "productQrCode"
    : id;

  return (
    <Draggable
      axis={axis}
      bounds="parent"
      handle=".handle-button"
      position={{ x, y }}
      onDrag={handleDrag}
      onStop={(e, data) => {
        var localX = data.x;
        var localY = data.y;
        id.includes("image-") || id.includes("shape-") || id.includes("text-")
          ? handleChangePageContent(page.id, pageKey, null, index, "position", {
              x: localX,
              y: localY,
            })
          : handleChangePageContent(
              page.id,
              pageKey,
              null,
              productId,
              "additionData",
              null,
              null,
              `${newID}Position`,
              { x: localX, y: localY }
            );
      }}
    >
      <div
        style={{
          zIndex: zIndex,
          opacity: opacity,
          position: "absolute",
        }}
      >
        <div
          style={{ transform: `rotate(${rotate}deg)` }}
          onClick={() => {
            setShowTools(true);
            setCurrentBoxId(id);
            setCurrentIndex(index);
            setCurrentPageId(page.id);
            setCurrentPageKey(pageKey);
            setCurrentProductId(productId);
          }}
        >
          <div className="handle-button" title="Move">
            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
          </div>
          <ResizableBox
            width={width}
            height={height}
            resizeHandles={["s", "w", "e", "n", "nw", "ne", "se", "sw"]}
            onResize={(e, { size }) => {
              var localWidth = size?.width;
              var localHeight = size?.height;
              id.includes("image-") ||
              id.includes("shape-") ||
              id.includes("text-")
                ? handleChangePageContent(
                    page.id,
                    pageKey,
                    null,
                    index,
                    "size",
                    { width: localWidth, height: localHeight }
                  )
                : handleChangePageContent(
                    page.id,
                    pageKey,
                    null,
                    productId,
                    "additionData",
                    null,
                    null,
                    `${newID}Size`,
                    { width: localWidth, height: localHeight }
                  );
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "relative",
                opacity: show ? 1 : 0.1,
                border: currentBoxId === id ? "2px solid #f00" : "",
              }}
            >
              {!id.includes("text-") && (
                <RotatableDiv
                  id={id}
                  index={index}
                  rotate={rotate}
                  pageID={page.id}
                  pageKey={pageKey}
                  productId={productId}
                  handleRotate={handleChangePageContent}
                />
              )}
              {content}
            </div>
          </ResizableBox>
        </div>
      </div>
    </Draggable>
  );
};

function PageContent({
  page,
  mode,
  source,
  pageNumber,
  pagesCount,
  deletePageText,
  deletePageImage,
  deletePageShape,
  productsCatalog,
  setCurrentTopRender,
  handlePageImagesChange,
  numberOfProductsPerPage,
  handleChangePageContent,
}) {
  const { t, i18n } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [showTools, setShowTools] = useState(false);
  const [openedModal, setOpenedModal] = useState(null);
  const [catalogSize, setCatalogSize] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentBoxId, setCurrentBoxId] = useState(null);
  const [currentPageId, setCurrentPageId] = useState(null);
  const [currentPageKey, setCurrentPageKey] = useState(null);
  const [currentProductId, setCurrentProductId] = useState(null);
  const [colClass, setColClass] = useState({ cols: "col-4", rows: 3 });

  useEffect(() => {
    setShowTools(false);
  }, [pageNumber]);

  useEffect(() => {
    const props = {
      mode,
      source,
      productsCatalog,
    };
    setCatalogSize(calculateCatalogSize(props));
  }, [productsCatalog, mode]);

  useEffect(() => {
    if (page.productsListGridSystem) {
      setColClass(page.productsListGridSystem);
    }
  }, [page.productsListGridSystem]);

  const clearSelectedTools = () => {
    setShowTools(false);
    setCurrentIndex(null);
    setCurrentBoxId(null);
    setCurrentPageId(null);
    setCurrentPageKey(null);
    setCurrentProductId(null);
  };

  const handleProductModelShow = (product) => {
    if (mode === "rendered") {
      setOpenedModal(product);
    }
  };

  const handleClose = () => {
    setOpenedModal(null);
  };

  useEffect(() => {
    if (page.productsList) {
      setLoading(false);
    }
  }, [page]);

  const decodeHtmlEntities = (text) => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    return textArea.value;
  };

  const componentRef = useRef(null);

  const handleConversion = (inputString) => {
    const stringWithoutLastTwo = inputString?.slice(0, -2);
    const convertedNumber = Number(stringWithoutLastTwo);
    return convertedNumber;
  };

  const [selectionRange, setSelectionRange] = useState(null);

  const saveSelection = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      setSelectionRange(selection.getRangeAt(0));
    }
  };

  const restoreSelection = () => {
    const selection = window.getSelection();
    if (selectionRange) {
      selection.removeAllRanges();
      selection.addRange(selectionRange);
    }
  };

  const applyStyle = (command, value = null) => {
    restoreSelection();
    if (window.getSelection().toString()) {
      document.execCommand(command, false, value);
      if (command === "fontSize") {
        document.querySelectorAll('font[size="7"]').forEach((el) => {
          el.removeAttribute("size");
          el.style.fontSize = `${value}px`;
        });
      }
    }
  };

  return (
    <>
      {showTools && mode === "editable" && (
        <div className="resizableAndDraggableBoxNavBar">
          <Button
            page={page}
            title="Show"
            applyStyle={applyStyle}
            currentIndex={currentIndex}
            currentBoxId={currentBoxId}
            currentPageId={currentPageId}
            currentPageKey={currentPageKey}
            currentProductId={currentProductId}
            handlePageImagesChange={handlePageImagesChange}
            handleChangePageContent={handleChangePageContent}
          />
          <Input
            max={1}
            min={0.1}
            step={0.1}
            page={page}
            type="number"
            title="Opacity"
            applyStyle={applyStyle}
            currentIndex={currentIndex}
            currentBoxId={currentBoxId}
            currentPageId={currentPageId}
            currentPageKey={currentPageKey}
            currentProductId={currentProductId}
            handlePageImagesChange={handlePageImagesChange}
            handleChangePageContent={handleChangePageContent}
          />
          <Input
            min={0}
            max={100}
            page={page}
            type="number"
            title="Z Index"
            applyStyle={applyStyle}
            currentIndex={currentIndex}
            currentBoxId={currentBoxId}
            currentPageId={currentPageId}
            currentPageKey={currentPageKey}
            currentProductId={currentProductId}
            handlePageImagesChange={handlePageImagesChange}
            handleChangePageContent={handleChangePageContent}
          />
          {(currentBoxId.includes("productQrCode") ||
            currentBoxId.includes("productTitle") ||
            currentBoxId.includes("productDescription") ||
            currentBoxId.includes("shape-") ||
            currentBoxId.includes("text-")) && (
            <>
              <Input
                page={page}
                type="color"
                title="Bg Color"
                command="foreColor"
                applyStyle={applyStyle}
                currentIndex={currentIndex}
                currentBoxId={currentBoxId}
                currentPageId={currentPageId}
                currentPageKey={currentPageKey}
                currentProductId={currentProductId}
                handleChangePageContent={handleChangePageContent}
              />
              {currentBoxId.includes("shape-") && (
                <>
                  <Input
                    page={page}
                    type="color"
                    title="Border Color"
                    applyStyle={applyStyle}
                    currentIndex={currentIndex}
                    currentBoxId={currentBoxId}
                    currentPageId={currentPageId}
                    currentPageKey={currentPageKey}
                    currentProductId={currentProductId}
                    handleChangePageContent={handleChangePageContent}
                  />
                  <Input
                    page={page}
                    type="number"
                    title="Border Width"
                    applyStyle={applyStyle}
                    currentIndex={currentIndex}
                    currentBoxId={currentBoxId}
                    currentPageId={currentPageId}
                    currentPageKey={currentPageKey}
                    currentProductId={currentProductId}
                    handleChangePageContent={handleChangePageContent}
                  />
                  <Input
                    page={page}
                    type="number"
                    title="Border Radius"
                    applyStyle={applyStyle}
                    currentIndex={currentIndex}
                    currentBoxId={currentBoxId}
                    currentPageId={currentPageId}
                    currentPageKey={currentPageKey}
                    currentProductId={currentProductId}
                    handleChangePageContent={handleChangePageContent}
                  />
                  <Select
                    page={page}
                    items={[
                      { solid: "Solid" },
                      { dashed: "Dashed" },
                      { dotted: "Dotted" },
                    ]}
                    title="Border Style"
                    applyStyle={applyStyle}
                    currentIndex={currentIndex}
                    currentBoxId={currentBoxId}
                    currentPageId={currentPageId}
                    currentPageKey={currentPageKey}
                    currentProductId={currentProductId}
                    handleChangePageContent={handleChangePageContent}
                  />
                </>
              )}
              {(currentBoxId.includes("productTitle") ||
                currentBoxId.includes("productDescription") ||
                currentBoxId.includes("text-")) && (
                <>
                  <Input
                    min={0}
                    page={page}
                    type="number"
                    title="Font Size"
                    command="fontSize"
                    applyStyle={applyStyle}
                    currentIndex={currentIndex}
                    currentBoxId={currentBoxId}
                    currentPageId={currentPageId}
                    currentPageKey={currentPageKey}
                    currentProductId={currentProductId}
                    handleChangePageContent={handleChangePageContent}
                  />
                  <Select
                    page={page}
                    items={fontNames}
                    title="Font Name"
                    command="fontName"
                    applyStyle={applyStyle}
                    currentIndex={currentIndex}
                    currentBoxId={currentBoxId}
                    currentPageId={currentPageId}
                    currentPageKey={currentPageKey}
                    currentProductId={currentProductId}
                    handleChangePageContent={handleChangePageContent}
                  />
                  <Button
                    symbol="B"
                    page={page}
                    title="Bold"
                    command="bold"
                    applyStyle={applyStyle}
                    currentIndex={currentIndex}
                    currentBoxId={currentBoxId}
                    currentPageId={currentPageId}
                    currentPageKey={currentPageKey}
                    currentProductId={currentProductId}
                    handleChangePageContent={handleChangePageContent}
                  />
                  <Button
                    symbol="I"
                    page={page}
                    title="Italic"
                    command="italic"
                    applyStyle={applyStyle}
                    currentIndex={currentIndex}
                    currentBoxId={currentBoxId}
                    currentPageId={currentPageId}
                    currentPageKey={currentPageKey}
                    currentProductId={currentProductId}
                    handleChangePageContent={handleChangePageContent}
                  />
                  <Button
                    symbol="U"
                    page={page}
                    title="Underline"
                    command="underline"
                    applyStyle={applyStyle}
                    currentIndex={currentIndex}
                    currentBoxId={currentBoxId}
                    currentPageId={currentPageId}
                    currentPageKey={currentPageKey}
                    currentProductId={currentProductId}
                    handleChangePageContent={handleChangePageContent}
                  />
                  <Align
                    page={page}
                    title="Text Align"
                    applyStyle={applyStyle}
                    currentIndex={currentIndex}
                    currentBoxId={currentBoxId}
                    currentPageId={currentPageId}
                    currentPageKey={currentPageKey}
                    currentProductId={currentProductId}
                    handleChangePageContent={handleChangePageContent}
                  />
                </>
              )}
            </>
          )}
          {currentBoxId.includes("image-") && (
            <>
              <Input
                page={page}
                type="file"
                title="Browse"
                applyStyle={applyStyle}
                currentIndex={currentIndex}
                currentBoxId={currentBoxId}
                currentPageId={currentPageId}
                currentPageKey={currentPageKey}
                currentProductId={currentProductId}
                handlePageImagesChange={handlePageImagesChange}
              />
              <Select
                page={page}
                items={[
                  { none: "None" },
                  { fill: "Fill" },
                  { cover: "Cover" },
                  { contain: "Contain" },
                ]}
                title="Object Fit"
                applyStyle={applyStyle}
                currentIndex={currentIndex}
                currentBoxId={currentBoxId}
                currentPageId={currentPageId}
                currentPageKey={currentPageKey}
                currentProductId={currentProductId}
                handleChangePageContent={handleChangePageContent}
              />
              <Select
                page={page}
                items={[
                  { top: "Top" },
                  { left: "Left" },
                  { right: "Right" },
                  { center: "Center" },
                  { bottom: "Bottom" },
                ]}
                title="Object Position"
                applyStyle={applyStyle}
                currentIndex={currentIndex}
                currentBoxId={currentBoxId}
                currentPageId={currentPageId}
                currentPageKey={currentPageKey}
                currentProductId={currentProductId}
                handleChangePageContent={handleChangePageContent}
              />
            </>
          )}
          <Button
            page={page}
            title="Delete"
            currentIndex={currentIndex}
            currentBoxId={currentBoxId}
            currentPageId={currentPageId}
            currentPageKey={currentPageKey}
            deletePageText={deletePageText}
            deletePageImage={deletePageImage}
            deletePageShape={deletePageShape}
            currentProductId={currentProductId}
            clearSelectedTools={clearSelectedTools}
          />
        </div>
      )}
      {page && (
        <div
          className={`page-style ${pageNumber % 2 === 0 ? "even" : "odd"}`}
          data-page={pageNumber}
          style={{
            margin: "0 auto",
            backgroundColor: page.pageBackgroundColor,
            width: catalogSize ? catalogSize?.width : "100%",
            height: catalogSize ? catalogSize?.height : "100%",
          }}
          ref={componentRef}
        >
          <div
            className={`${pageNumber % 2 === 0 ? "even" : "odd"}-shadow`}
          ></div>

          {mode === "editable" && (
            <>
              <div className="editing-line-horizontal"></div>
              <div className="editing-line-vertical"></div>
            </>
          )}
          {page.pageType === "non-customized" ? (
            mode === "editable" ? (
              isLoading ? (
                <h6>{t("loading")} ...</h6>
              ) : (
                page.productsList.length > 0 && (
                  <PageProductsListConfig
                    page={page}
                    mode={mode}
                    pageKey="productsList"
                    products={page.productsList}
                    productsCatalog={productsCatalog}
                    handleChangePageContent={handleChangePageContent}
                    numberOfProductsPerPage={numberOfProductsPerPage}
                    productsListGridSystem={page.productsListGridSystem}
                  />
                )
              )
            ) : (
              page.productsList.length > 0 && (
                <div className="products-list">
                  <div className="row products-row">
                    {isLoading ? (
                      <h6>{t("loading")} ...</h6>
                    ) : (
                      page.productsList &&
                      page.productsList.length > 0 &&
                      page.productsList.map((product, i) => {
                        return (
                          <div
                            style={{
                              height: `calc((100% / ${colClass.rows}) - ${
                                mode === "smallView" ? 0 : 2
                              }px)`,
                              padding: mode === "smallView" ? "1px" : "4px",
                            }}
                            className={`${mode} ${colClass.cols}`}
                            onClick={() => handleProductModelShow(product)}
                            key={i}
                          >
                            <div
                              className="item-card cursor-pointer"
                              id={"product_" + product.product_id}
                            >
                              <div className={`item-image`}>
                                <img
                                  // src={"https://sinaha.ae/image/" + product.image}
                                  alt=""
                                  style={{
                                    backgroundImage: `url("https://sinaha.ae/image/${product.image}")`
                                  }}
                                />
                              </div>
                              <div className="item-details">
                                {i18n.language === "ar" ? (
                                  <p className="item-title">
                                    {decodeHtmlEntities(product.ar_name)}
                                  </p>
                                ) : (
                                  <p className="item-title">
                                    {decodeHtmlEntities(product.en_name)}
                                  </p>
                                )}

                                <div className="item-description">
                                  {i18n.language === "ar"
                                    ? extractTextFromHtml(
                                        decodeHtml(product.ar_description)
                                      )
                                    : extractTextFromHtml(
                                        decodeHtml(product.en_description)
                                      )}
                                </div>
                                <img
                                  className="qr-code"
                                  src={
                                    "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https%3A%2F%2Fsinaha.ae%2Findex.php%3Froute%3Dproduct%2Fproduct%26product_id%3D" +
                                    product.product_id
                                  }
                                  alt={product.en_name}
                                  title={product.en_name}
                                />
                                <div className="sin-product-verfiy-logos">
                                  {product.membership_level_list?.map(
                                    (level, key) => {
                                      return (
                                        <div
                                          key={key}
                                          className="sin-product-verfiy-logo"
                                        >
                                          <img
                                            src={
                                              "https://sinaha.ae/image/" +
                                              level
                                            }
                                            alt="0"
                                          />
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              )
            )
          ) : (
            <>
              {page.productsList &&
                page.productsList.length > 0 &&
                page.productsList.map((product, i) => {
                  return (
                    <React.Fragment key={i}>
                      {mode === "editable" ? (
                        <ResizableAndDraggableBox
                          page={page}
                          pageKey="productsList"
                          currentBoxId={currentBoxId}
                          setShowTools={setShowTools}
                          componentRef={componentRef}
                          productId={product.product_id}
                          setCurrentBoxId={setCurrentBoxId}
                          setCurrentIndex={setCurrentIndex}
                          setCurrentPageId={setCurrentPageId}
                          setCurrentPageKey={setCurrentPageKey}
                          setCurrentProductId={setCurrentProductId}
                          id={`productQrCode-${product.product_id}`}
                          show={product?.additionData?.productQrCodeShow}
                          handleChangePageContent={handleChangePageContent}
                          zIndex={product?.additionData?.productQrCodeZIndex}
                          x={product?.additionData?.productQrCodePosition?.x}
                          y={product?.additionData?.productQrCodePosition?.y}
                          rotate={product?.additionData?.productQrCodeRotate}
                          opacity={product?.additionData?.productQrCodeOpacity}
                          width={
                            product?.additionData?.productQrCodeSize?.width
                          }
                          height={
                            product?.additionData?.productQrCodeSize?.height
                          }
                          content={
                            <>
                              <img
                                className="qr-code"
                                src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https%3A%2F%2Fsinaha.ae%2Findex.php%3Froute%3Dproduct%2Fproduct%26product_id%3D${
                                  product.product_id
                                }&color=${product?.additionData?.productQrCodeBgColor?.substring(
                                  1
                                )}`}
                                alt={product.en_name}
                                title={product.en_name}
                              />
                              <br />
                              <span
                                style={{
                                  fontSize: "8px",
                                  display: "block",
                                  marginTop: "4px",
                                  lineHeight: "10px",
                                }}
                              >
                                {i18n.language === "ar"
                                  ? extractTextFromHtml(
                                      decodeHtml(product.ar_name)
                                    )
                                  : extractTextFromHtml(
                                      decodeHtml(product.en_name)
                                    )}
                              </span>
                            </>
                          }
                        />
                      ) : (
                        product?.additionData?.productQrCodeShow && (
                          <img
                            className={`qr-code ${product?.additionData?.productQrCodePosition?.x} ${product.product_id}`}
                            style={{
                              zIndex:
                                product?.additionData?.productQrCodeZIndex,
                              position: "absolute",
                              opacity:
                                product?.additionData?.productQrCodeOpacity,
                              transform: `rotate(${product?.additionData?.productQrCodeRotate}deg)`,
                              width: `${
                                product?.additionData?.productQrCodeSize?.width
                                  ? (product?.additionData?.productQrCodeSize
                                      ?.width /
                                      productsCatalog?.catalogSize?.width) *
                                    100
                                  : 20
                              }%`,
                              height: `${
                                product?.additionData?.productQrCodeSize?.height
                                  ? (product?.additionData?.productQrCodeSize
                                      ?.height /
                                      productsCatalog?.catalogSize?.height) *
                                    100
                                  : 20
                              }%`,
                              left: `${
                                product?.additionData?.productQrCodePosition?.x
                                  ? (product?.additionData
                                      ?.productQrCodePosition?.x /
                                      productsCatalog?.catalogSize?.width) *
                                    100
                                  : 20
                              }%`,
                              top: `${
                                product?.additionData?.productQrCodePosition?.y
                                  ? (product?.additionData
                                      ?.productQrCodePosition?.y /
                                      productsCatalog?.catalogSize?.height) *
                                    100
                                  : 20
                              }%`,
                            }}
                            src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https%3A%2F%2Fsinaha.ae%2Findex.php%3Froute%3Dproduct%2Fproduct%26product_id%3D${
                              product.product_id
                            }&color=${product?.additionData?.productQrCodeBgColor?.substring(
                              1
                            )}`}
                            alt={product.en_name}
                            title={product.en_name}
                          />
                        )
                      )}
                      {mode === "editable" ? (
                        <>
                          <ResizableAndDraggableBox
                            content={
                              <div
                                style={{
                                  fontSize: `${product?.additionData?.productTitleFontSize}px`,
                                  fontFamily:
                                    product?.additionData?.productTitleFontName,
                                  color:
                                    product?.additionData
                                      ?.productTitleFontColor,
                                  fontWeight:
                                    product?.additionData?.productTitleBold &&
                                    "bold",
                                  fontStyle:
                                    product?.additionData?.productTitleItalic &&
                                    "italic",
                                  textDecoration:
                                    product?.additionData
                                      ?.productTitleUnderline && "underline",
                                  textAlign:
                                    product?.additionData
                                      ?.productTitleTextAlign,
                                }}
                              >
                                {i18n.language === "ar"
                                  ? extractTextFromHtml(
                                      decodeHtml(product.ar_name)
                                    )
                                  : extractTextFromHtml(
                                      decodeHtml(product.en_name)
                                    )}
                              </div>
                            }
                            page={page}
                            pageKey="productsList"
                            currentBoxId={currentBoxId}
                            setShowTools={setShowTools}
                            componentRef={componentRef}
                            productId={product.product_id}
                            setCurrentIndex={setCurrentIndex}
                            setCurrentBoxId={setCurrentBoxId}
                            setCurrentPageId={setCurrentPageId}
                            setCurrentPageKey={setCurrentPageKey}
                            id={`productTitle-${product.product_id}`}
                            setCurrentProductId={setCurrentProductId}
                            show={product?.additionData?.productTitleShow}
                            handleChangePageContent={handleChangePageContent}
                            x={product?.additionData?.productTitlePosition?.x}
                            y={product?.additionData?.productTitlePosition?.y}
                            rotate={product?.additionData?.productTitleRotate}
                            zIndex={product?.additionData?.productTitleZIndex}
                            opacity={product?.additionData?.productTitleOpacity}
                            width={
                              product?.additionData?.productTitleSize?.width
                            }
                            height={
                              product?.additionData?.productTitleSize?.height
                            }
                          />
                          <ResizableAndDraggableBox
                            content={
                              <div
                                style={{
                                  fontSize: `${product?.additionData?.productDescriptionFontSize}px`,
                                  fontFamily:
                                    product?.additionData
                                      ?.productDescriptionFontName,
                                  color:
                                    product?.additionData
                                      ?.productDescriptionFontColor,
                                  fontWeight:
                                    product?.additionData
                                      ?.productDescriptionBold && "bold",
                                  fontStyle:
                                    product?.additionData
                                      ?.productDescriptionItalic && "italic",
                                  textDecoration:
                                    product?.additionData
                                      ?.productDescriptionUnderline &&
                                    "underline",
                                  textAlign:
                                    product?.additionData
                                      ?.productDescriptionTextAlign,
                                }}
                              >
                                {i18n.language === "ar"
                                  ? extractTextFromHtml(
                                      decodeHtml(product.ar_description)
                                    )
                                  : extractTextFromHtml(
                                      decodeHtml(product.en_description)
                                    )}
                              </div>
                            }
                            page={page}
                            pageKey="productsList"
                            currentBoxId={currentBoxId}
                            setShowTools={setShowTools}
                            componentRef={componentRef}
                            productId={product.product_id}
                            setCurrentIndex={setCurrentIndex}
                            setCurrentBoxId={setCurrentBoxId}
                            setCurrentPageId={setCurrentPageId}
                            setCurrentPageKey={setCurrentPageKey}
                            setCurrentProductId={setCurrentProductId}
                            id={`productDescription-${product.product_id}`}
                            handleChangePageContent={handleChangePageContent}
                            show={product?.additionData?.productDescriptionShow}
                            x={
                              product?.additionData?.productDescriptionPosition
                                ?.x
                            }
                            y={
                              product?.additionData?.productDescriptionPosition
                                ?.y
                            }
                            rotate={
                              product?.additionData?.productDescriptionRotate
                            }
                            opacity={
                              product?.additionData?.productDescriptionOpacity
                            }
                            width={
                              product?.additionData?.productDescriptionSize
                                ?.width
                            }
                            height={
                              product?.additionData?.productDescriptionSize
                                ?.height
                            }
                            zIndex={
                              product?.additionData?.productDescriptionZIndex
                            }
                          />
                        </>
                      ) : (
                        <>
                          {product?.additionData?.productTitleShow && (
                            <div
                              className={
                                product?.additionData?.productTitlePosition?.x
                              }
                              style={{
                                zIndex:
                                  product?.additionData?.productTitleZIndex,
                                position: "absolute",
                                opacity:
                                  product?.additionData?.productTitleOpacity,
                                transform: `rotate(${product?.additionData?.productTitleRotate}deg)`,
                                width: `${
                                  product?.additionData?.productTitleSize?.width
                                    ? (product?.additionData?.productTitleSize
                                        ?.width /
                                        productsCatalog?.catalogSize?.width) *
                                      100
                                    : 20
                                }%`,
                                height: `${
                                  product?.additionData?.productTitleSize
                                    ?.height
                                    ? (product?.additionData?.productTitleSize
                                        ?.height /
                                        productsCatalog?.catalogSize?.height) *
                                      100
                                    : 20
                                }%`,
                                left: `${
                                  product?.additionData?.productTitlePosition?.x
                                    ? (product?.additionData
                                        ?.productTitlePosition?.x /
                                        productsCatalog?.catalogSize?.width) *
                                      100
                                    : 20
                                }%`,
                                top: `${
                                  product?.additionData?.productTitlePosition?.y
                                    ? (product?.additionData
                                        ?.productTitlePosition?.y /
                                        productsCatalog?.catalogSize?.height) *
                                      100
                                    : 20
                                }%`,
                                fontSize: `${
                                  mode === "smallView"
                                    ? parseInt(
                                        product?.additionData
                                          ?.productTitleFontSize
                                      ) / 5
                                    : product?.additionData
                                        ?.productTitleFontSize
                                }px`,
                                fontFamily:
                                  product?.additionData?.productTitleFontName,
                                color:
                                  product?.additionData?.productTitleFontColor,
                                fontWeight:
                                  product?.additionData?.productTitleBold &&
                                  "bold",
                                fontStyle:
                                  product?.additionData?.productTitleItalic &&
                                  "italic",
                                textDecoration:
                                  product?.additionData
                                    ?.productTitleUnderline && "underline",
                                textAlign:
                                  product?.additionData?.productTitleTextAlign,
                              }}
                            >
                              {i18n.language === "ar"
                                ? extractTextFromHtml(
                                    decodeHtml(product.ar_name)
                                  )
                                : extractTextFromHtml(
                                    decodeHtml(product.en_name)
                                  )}
                            </div>
                          )}

                          {product?.additionData?.productDescriptionShow && (
                            <div
                              style={{
                                zIndex:
                                  product?.additionData
                                    ?.productDescriptionZIndex,
                                position: "absolute",
                                opacity:
                                  product?.additionData
                                    ?.productDescriptionOpacity,
                                transform: `rotate(${product?.additionData?.productDescriptionRotate}deg)`,
                                width: `${
                                  product?.additionData?.productDescriptionSize
                                    ?.width
                                    ? (product?.additionData
                                        ?.productDescriptionSize?.width /
                                        productsCatalog?.catalogSize?.width) *
                                      100
                                    : 20
                                }%`,
                                height: `${
                                  product?.additionData?.productDescriptionSize
                                    ?.height
                                    ? (product?.additionData
                                        ?.productDescriptionSize?.height /
                                        productsCatalog?.catalogSize?.height) *
                                      100
                                    : 20
                                }%`,
                                left: `${
                                  product?.additionData
                                    ?.productDescriptionPosition?.x
                                    ? (product?.additionData
                                        ?.productDescriptionPosition?.x /
                                        productsCatalog?.catalogSize?.width) *
                                      100
                                    : 20
                                }%`,
                                top: `${
                                  product?.additionData
                                    ?.productDescriptionPosition?.y
                                    ? (product?.additionData
                                        ?.productDescriptionPosition?.y /
                                        productsCatalog?.catalogSize?.height) *
                                      100
                                    : 20
                                }%`,
                                fontSize: `${
                                  mode === "smallView"
                                    ? product?.additionData
                                        ?.productDescriptionFontSize / 5
                                    : product?.additionData
                                        ?.productDescriptionFontSize
                                }px`,
                                fontFamily:
                                  product?.additionData
                                    ?.productDescriptionFontName,
                                color:
                                  product?.additionData
                                    ?.productDescriptionFontColor,
                                fontWeight:
                                  product?.additionData
                                    ?.productDescriptionBold && "bold",
                                fontStyle:
                                  product?.additionData
                                    ?.productDescriptionItalic && "italic",
                                textDecoration:
                                  product?.additionData
                                    ?.productDescriptionUnderline &&
                                  "underline",
                                textAlign:
                                  product?.additionData
                                    ?.productDescriptionTextAlign,
                              }}
                            >
                              {i18n.language === "ar"
                                ? extractTextFromHtml(
                                    decodeHtml(product.ar_description)
                                  )
                                : extractTextFromHtml(
                                    decodeHtml(product.en_description)
                                  )}
                            </div>
                          )}
                        </>
                      )}
                    </React.Fragment>
                  );
                })}
            </>
          )}

          {page?.imagesList &&
            (page?.imagesListGridSystem === "customize" ? (
              <>
                {mode === "editable"
                  ? page?.imagesList.map((image, index) => (
                      <ResizableAndDraggableBox
                        page={page}
                        index={index}
                        productId={null}
                        show={image?.show}
                        pageKey="imagesList"
                        id={`image-${index}`}
                        key={`image-${index}`}
                        zIndex={image?.zIndex}
                        rotate={image?.rotate}
                        x={image?.position?.x}
                        y={image?.position?.y}
                        opacity={image?.opacity}
                        componentRef={componentRef}
                        currentBoxId={currentBoxId}
                        setShowTools={setShowTools}
                        setCurrentBoxId={setCurrentBoxId}
                        setCurrentIndex={setCurrentIndex}
                        deletePageImage={deletePageImage}
                        setCurrentPageId={setCurrentPageId}
                        setCurrentPageKey={setCurrentPageKey}
                        setCurrentProductId={setCurrentProductId}
                        handleChangePageContent={handleChangePageContent}
                        content={
                          <>
                            {image?.imagePreviewUrl && (
                              <img
                                alt=""
                                key={index}
                                src={
                                  image?.imagePreviewUrl?.includes("data:image")
                                    ? image?.imagePreviewUrl
                                    : `${API_BASE_URL_WITH_PORT}/${image?.imagePreviewUrl}`
                                }
                                className="w-100 h-100"
                                style={{
                                  objectFit: image?.objectFit,
                                  objectPosition: image?.objectPosition,
                                }}
                              />
                            )}
                          </>
                        }
                        width={image?.size ? image?.size?.width : 100}
                        height={image?.size ? image?.size?.height : 100}
                      />
                    ))
                  : page?.imagesList.map(
                      (image, index) =>
                        image?.show && (
                          <img
                            alt={mode}
                            key={index}
                            src={
                              image?.imagePreviewUrl?.includes("data:image")
                                ? image?.imagePreviewUrl
                                : `${API_BASE_URL_WITH_PORT}/${image?.imagePreviewUrl}`
                            }
                            style={{
                              position: "absolute",
                              objectFit: image?.objectFit,
                              objectPosition: image?.objectPosition,
                              zIndex: page?.imagesList[index]?.zIndex,
                              opacity: image?.opacity,
                              width: `${
                                image
                                  ? (image?.size?.width /
                                      productsCatalog?.catalogSize?.width) *
                                    100
                                  : 100
                              }%`,
                              height: `${
                                image
                                  ? (image?.size?.height /
                                      productsCatalog?.catalogSize?.height) *
                                    100
                                  : 100
                              }%`,
                              left: `${
                                image
                                  ? (image.position?.x /
                                      productsCatalog?.catalogSize?.width) *
                                    100
                                  : 20
                              }%`,
                              top: `${
                                image
                                  ? (image.position?.y /
                                      productsCatalog?.catalogSize?.height) *
                                    100
                                  : 20
                              }%`,
                              transform: `rotate(${image.rotate}deg)`,
                            }}
                          />
                        )
                    )}
              </>
            ) : (
              page?.imagesList.length > 0 && (
                <div
                  className="imagesListRowsColumns"
                  style={
                    page?.imagesListGridSystem === "column"
                      ? {
                          display:
                            page?.imagesList?.length === 1 ? "block" : "grid",
                          gridTemplateColumns: `repeat(${page?.imagesList?.length}, 1fr)`,
                        }
                      : {
                          display:
                            page?.imagesList?.length === 1 ? "block" : "grid",
                          gridTemplateRows: `repeat(${page?.imagesList?.length}, 1fr)`,
                        }
                  }
                >
                  {mode === "editable"
                    ? page?.imagesList.map((image, index) => (
                        <img
                          key={index}
                          onClick={() => {
                            setShowTools(true);
                            setCurrentIndex(index);
                            setCurrentPageId(page.id);
                            setCurrentProductId(index);
                            setCurrentPageKey("imagesList");
                            setCurrentBoxId(`image-${index}`);
                          }}
                          alt=""
                          src={
                            image?.imagePreviewUrl?.includes("data:image")
                              ? image?.imagePreviewUrl
                              : `${API_BASE_URL_WITH_PORT}/${image?.imagePreviewUrl}`
                          }
                          style={{
                            objectFit: image?.objectFit,
                            objectPosition: image?.objectPosition,
                            opacity: image?.show ? image?.opacity : 0.1,
                            width:
                              page?.imagesListGridSystem === "column" &&
                              page?.imagesList?.length > 1
                                ? `${
                                    handleConversion(catalogSize?.width) /
                                      page?.imagesList.length -
                                    5
                                  }px`
                                : "100%",
                            height:
                              page?.imagesListGridSystem === "row" &&
                              page?.imagesList?.length > 1
                                ? `${
                                    handleConversion(catalogSize?.height) /
                                      page?.imagesList.length -
                                    5
                                  }px`
                                : "100%",
                            border:
                              currentBoxId?.includes("image-") &&
                              index === currentIndex &&
                              "2px solid #f00",
                          }}
                        />
                      ))
                    : page?.imagesList.map((image, index) => (
                        <img
                          key={index}
                          alt=""
                          src={
                            image?.imagePreviewUrl?.includes("data:image")
                              ? image?.imagePreviewUrl
                              : `${API_BASE_URL_WITH_PORT}/${image?.imagePreviewUrl}`
                          }
                          style={{
                            opacity: image?.opacity,
                            objectFit: image?.objectFit,
                            objectPosition: image?.objectPosition,
                            visibility: image?.show ? "visible" : "hidden",
                            width:
                              page?.imagesListGridSystem === "column" &&
                              page?.imagesList?.length > 1
                                ? `${
                                    handleConversion(catalogSize?.width) /
                                      page?.imagesList.length -
                                    5
                                  }px`
                                : "100%",
                            height:
                              page?.imagesListGridSystem === "row" &&
                              page?.imagesList?.length > 1
                                ? `${
                                    handleConversion(catalogSize?.height) /
                                      page?.imagesList.length -
                                    5
                                  }px`
                                : "100%",
                          }}
                        />
                      ))}
                </div>
              )
            ))}

          {page?.shapesList?.length > 0 &&
            (mode === "editable"
              ? page?.shapesList?.map((shape, index) => (
                  <ResizableAndDraggableBox
                    page={page}
                    index={index}
                    productId={null}
                    show={shape?.show}
                    pageKey="shapesList"
                    id={`shape-${index}`}
                    key={`shape-${index}`}
                    shapeType={shape?.type}
                    rotate={shape?.rotate}
                    x={shape?.position?.x}
                    y={shape?.position?.y}
                    zIndex={shape?.zIndex}
                    opacity={shape?.opacity}
                    componentRef={componentRef}
                    currentBoxId={currentBoxId}
                    setShowTools={setShowTools}
                    setCurrentBoxId={setCurrentBoxId}
                    setCurrentIndex={setCurrentIndex}
                    deletePageShape={deletePageShape}
                    setCurrentPageId={setCurrentPageId}
                    setCurrentPageKey={setCurrentPageKey}
                    setCurrentTopRender={setCurrentTopRender}
                    setCurrentProductId={setCurrentProductId}
                    handleChangePageContent={handleChangePageContent}
                    content={
                      <div
                        className={`${shape?.type} ${
                          shape?.type !== "line" && "w-100"
                        }  h-100 m-0`}
                        style={
                          shape?.type === "line"
                            ? {
                                position: "absolute",
                                top: shape?.y,
                                left: shape?.x,
                                borderLeftColor: shape?.borderColor,
                                borderLeftStyle: shape?.borderStyle,
                                borderLeftWidth: `${shape?.borderWidth}px`,
                              }
                            : {
                                position: "absolute",
                                top: shape?.y,
                                left: shape?.x,
                                backgroundColor: shape?.bgColor,
                                borderStyle: shape?.borderStyle,
                                borderColor: shape?.borderColor,
                                borderWidth: `${shape?.borderWidth}px`,
                                borderRadius:
                                  shape?.type === "square" &&
                                  `${shape?.borderRadius}px`,
                              }
                        }
                      ></div>
                    }
                    width={shape?.size ? shape?.size?.width : 100}
                    height={shape?.size ? shape?.size?.height : 100}
                  />
                ))
              : page?.shapesList?.map((shape, index) => (
                  <>
                    <div
                      key={index}
                      className={`${shape?.type} m-0`}
                      style={
                        shape?.type === "line"
                          ? {
                              position: "absolute",
                              opacity: shape?.opacity,
                              zIndex: `${shape?.zIndex}`,
                              display: !shape?.show && "none",
                              borderLeftColor: shape?.borderColor,
                              borderLeftStyle: shape?.borderStyle,
                              transform: `rotate(${shape?.rotate}deg)`,
                              left: `${
                                shape
                                  ? (shape?.position?.x /
                                      productsCatalog?.catalogSize?.width) *
                                    100
                                  : 20
                              }%`,
                              top: `${
                                shape
                                  ? (shape?.position?.y /
                                      productsCatalog?.catalogSize?.height) *
                                    100
                                  : 20
                              }%`,
                              height: `${
                                shape
                                  ? (shape?.size?.height /
                                      productsCatalog?.catalogSize?.height) *
                                    100
                                  : 100
                              }%`,
                              borderLeftWidth: `${
                                mode === "smallView"
                                  ? shape?.borderWidth / 4
                                  : shape?.borderWidth
                              }px`,
                            }
                          : {
                              position: "absolute",
                              opacity: shape?.opacity,
                              zIndex: `${shape?.zIndex}`,
                              backgroundColor: shape?.bgColor,
                              display: !shape?.show && "none",
                              borderStyle: shape?.borderStyle,
                              borderColor: shape?.borderColor,
                              transform: `rotate(${shape?.rotate}deg)`,
                              left: `${
                                shape
                                  ? (shape?.position?.x /
                                      productsCatalog?.catalogSize?.width) *
                                    100
                                  : 20
                              }%`,
                              width: `${
                                shape
                                  ? (shape?.size?.width /
                                      productsCatalog?.catalogSize?.width) *
                                    100
                                  : 100
                              }%`,
                              top: `${
                                shape
                                  ? (shape?.position?.y /
                                      productsCatalog?.catalogSize?.height) *
                                    100
                                  : 20
                              }%`,
                              height: `${
                                shape
                                  ? (shape?.size?.height /
                                      productsCatalog?.catalogSize?.height) *
                                    100
                                  : 100
                              }%`,
                              borderWidth: `${
                                mode === "smallView"
                                  ? shape?.borderWidth / 4
                                  : shape?.borderWidth
                              }px`,
                              borderRadius:
                                shape?.type === "square" &&
                                `${
                                  mode === "smallView"
                                    ? shape?.borderRadius / 4
                                    : shape?.borderRadius
                                }px`,
                            }
                      }
                    ></div>
                  </>
                )))}

          {page.textsList &&
            (mode === "editable"
              ? page.textsList.map((text, index) => (
                  <ResizableAndDraggableBox
                    page={page}
                    index={index}
                    productId={null}
                    show={text?.show}
                    pageKey="textsList"
                    id={`text-${index}`}
                    key={`text-${index}`}
                    rotate={text?.rotate}
                    x={text?.position?.x}
                    y={text?.position?.y}
                    zIndex={text?.zIndex}
                    opacity={text?.opacity}
                    width={text?.size?.width}
                    height={text?.size?.height}
                    componentRef={componentRef}
                    currentBoxId={currentBoxId}
                    setShowTools={setShowTools}
                    deletePageText={deletePageText}
                    setCurrentBoxId={setCurrentBoxId}
                    setCurrentIndex={setCurrentIndex}
                    setCurrentPageId={setCurrentPageId}
                    setCurrentPageKey={setCurrentPageKey}
                    setCurrentProductId={setCurrentProductId}
                    handleChangePageContent={handleChangePageContent}
                    content={
                      <TextEditor
                        currentIndex={currentIndex}
                        defaultValue={text?.content}
                        currentPageId={currentPageId}
                        saveSelection={saveSelection}
                        handleChangePageContent={handleChangePageContent}
                      />
                    }
                  />
                ))
              : page.textsList.map(
                  (text, index) =>
                    text?.show && (
                      <div
                        className={mode}
                        key={index}
                        style={{
                          position: "absolute",
                          opacity: text?.opacity,
                          zIndex: `${text?.zIndex}`,
                          transform: `rotate(${text?.rotate}deg)`,
                          left: `${
                            text
                              ? (text?.position?.x /
                                  productsCatalog?.catalogSize?.width) *
                                100
                              : 20
                          }%`,
                          width: `${
                            text
                              ? (text?.size?.width /
                                  productsCatalog?.catalogSize?.width) *
                                100
                              : 100
                          }%`,
                          top: `${
                            text
                              ? (text?.position?.y /
                                  productsCatalog?.catalogSize?.height) *
                                100
                              : 20
                          }%`,
                          height: `${
                            text
                              ? (text?.size?.height /
                                  productsCatalog?.catalogSize?.height) *
                                100
                              : 100
                          }%`,
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{ __html: text?.content }}
                        />
                      </div>
                    )
                ))}
          <div
            className="footer"
            style={{ backgroundColor: productsCatalog?.mainColor }}
          >
            {t("page")} {pageNumber} {t("of")} {pagesCount}
          </div>
          <ProductModal
            show={!!openedModal}
            handleClose={handleClose}
            product={openedModal}
          />
        </div>
      )}
    </>
  );
}

export default PageContent;
