import React, { useRef, useState, useEffect, useTransition } from "react";
import { faCopy, faSearch } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL, API_BASE_URL_WITH_PORT } from "../config/url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import { fetchCategories } from "../api/categories";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { fetchAll } from "../api/catalogs";
import Form from "react-bootstrap/Form";
import Pagination from "./pagination";
import Catalog from "./catalog";
import Header from "./header";

const useScrollAboutMark = (id) => {
  const aboutMarkRef = useRef(null);
  const aboutMarkExecuteScroll = () =>
    aboutMarkRef.current.scrollIntoView({ behavior: "smooth" });
  return [aboutMarkExecuteScroll, aboutMarkRef];
};

const useScrollAggregator = (id) => {
  const aggregatorRef = useRef(null);
  const aggregatorExecuteScroll = () =>
    aggregatorRef.current.scrollIntoView({ behavior: "smooth" });
  return [aggregatorExecuteScroll, aggregatorRef];
};

const useScrollContact = (id) => {
  const contactRef = useRef(null);
  const contactExecuteScroll = () =>
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  return [contactExecuteScroll, contactRef];
};

const AllCatalog = () => {
  const { type, category, keyword } = useParams();
  const searchRef = useRef(null);
  const [aggregatorExecuteScroll, aggregatorRef] = useScrollAggregator();
  const [aboutMarkExecuteScroll, aboutMarkRef] = useScrollAboutMark();
  // const [totalLandscapePages, setTotalLandscapePages] = useState(1);
  const [currentCategory, setCurrentCategory] = useState(category);
  // const [totalPortraitPages, setTotalPortraitPages] = useState(1);
  const [contactExecuteScroll, contactRef] = useScrollContact();
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const [categoriesList, setCategoriesList] = useState([]);
  const [landscapeBooks, setLandscapeBooks] = useState([]);
  const [portraitBooks, setPortraitBooks] = useState([]);
  const [isPending, startTransition] = useTransition();
  const [currentType, setCurrentType] = useState(type);
  const [openedBook, setOpenedBook] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const scrollContainerRef = useRef(null);
  const { t, i18n } = useTranslation();

  const productsCatalogPerPage = 5;

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft = 0;
    }
    if (type) {
      setCurrentType(type);
    }
    if (category) {
      setCurrentCategory(category);
    }
    if (keyword) {
      setSearchKeyword(keyword);
    }
  }, []);

  useEffect(() => {
    fetchDataFromAPI();
  }, [
    currentPage,
    currentType,
    searchKeyword,
    currentCategory,
    productsCatalogPerPage,
  ]);

  const fetchDataFromAPI = async () => {
    try {
      startTransition(async () => {
        setLoading(true);
        var apiCategory =
          currentCategory === "" || currentCategory === "All"
            ? null
            : currentCategory;
        var apiSearchKeyword =
          searchKeyword === "" || searchKeyword === "empty"
            ? null
            : searchKeyword;
        var landscapeCatalog = await fetchAll(
          "Landscape",
          5,
          currentPage,
          currentType,
          apiCategory,
          "allCatalog",
          apiSearchKeyword
        );

        var portraitCatalog = await fetchAll(
          "Portrait",
          5,
          currentPage,
          currentType,
          apiCategory,
          "allCatalog",
          apiSearchKeyword
        );

        const parsedPortraitCatalog = portraitCatalog?.data.map((catalog) => ({
          ...catalog,
          coverImage:
            catalog?.coverImage &&
            (catalog?.coverImage?.includes("data:image")
              ? catalog?.coverImage
              : `${API_BASE_URL_WITH_PORT}/${catalog?.coverImage?.replace(
                  /\\/g,
                  "/"
                )}`),
          coverData: catalog?.coverData && JSON.parse(catalog?.coverData),
          catalogSize: catalog?.catalogSize && JSON.parse(catalog?.catalogSize),
          profileData: catalog?.profileData && JSON.parse(catalog?.profileData),
          backCoverData:
            catalog?.backCoverData && JSON.parse(catalog?.backCoverData),
          coverShapesList: JSON.parse(catalog?.coverShapesList),
          profileShapesList: JSON.parse(catalog?.profileShapesList),
        }));
        const parsedLandscapeCatalog = landscapeCatalog?.data.map(
          (catalog) => ({
            ...catalog,
            coverImage:
              catalog?.coverImage &&
              (catalog?.coverImage?.includes("data:image")
                ? catalog?.coverImage
                : `${API_BASE_URL_WITH_PORT}/${catalog?.coverImage?.replace(
                    /\\/g,
                    "/"
                  )}`),
            coverData: catalog?.coverData && JSON.parse(catalog?.coverData),
            catalogSize:
              catalog?.catalogSize && JSON.parse(catalog?.catalogSize),
            profileData:
              catalog?.profileData && JSON.parse(catalog?.profileData),
            backCoverData:
              catalog?.backCoverData && JSON.parse(catalog?.backCoverData),
            coverShapesList: JSON.parse(catalog?.coverShapesList),
            profileShapesList: JSON.parse(catalog?.profileShapesList),
          })
        );
        setPortraitBooks(parsedPortraitCatalog);
        setLandscapeBooks(parsedLandscapeCatalog);
        // setTotalPortraitPages(portraitCatalog?.pagination?.totalItems);
        // setTotalLandscapePages(landscapeCatalog?.pagination?.totalItems);
        setTotalPages(
          Math.max(
            portraitCatalog?.pagination?.totalPages,
            landscapeCatalog?.pagination?.totalPages
          )
        );
        var categories = await fetchCategories();
        setCategoriesList(categories);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const openBook = (book) => {
    setOpenedBook(book);
  };

  const closeBook = () => {
    setOpenedBook(null);
  };
  
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      search();
    }
  };

  const search = () => {
    var searchInput = searchRef.current.value;
    if (searchInput.trim().length === 0) {
      searchInput = null;
    }
    setSearchKeyword(searchInput);
    setCurrentPage(1);
  };

  const handleCopy = () => {
    const url = `${API_BASE_URL}/allCatalog/${currentType}/${currentCategory}/${searchKeyword}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success(t("Copied to clipboard"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <>
      <Header
        aboutMarkExecuteScroll={aboutMarkExecuteScroll}
        aggregatorExecuteScroll={aggregatorExecuteScroll}
        contactExecuteScroll={contactExecuteScroll}
      />
      <div
        className="marketing aggregator allCatalog"
        style={{ margin: "5% 0", height: "1200px" }}
      >
        <div className="container">
          <div className="bordered-box">
            <span
              className="label"
              style={{ left: i18n.language === "ar" ? "unset" : "10px" }}
            >
              {t("Filter")}
            </span>
            <div className="row">
              <div
                className={
                  currentType === "Categories" ? "col-md-11" : "col-md-3"
                }
              >
                <Form.Group controlId="formBasicSelect">
                  <Form.Label>{t("Catalog Type")}</Form.Label>
                  <Form.Select
                    value={currentType}
                    aria-label={currentType}
                    onChange={(e) => {
                      setCurrentType(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="Factories">{t("Factories")}</option>
                    <option value="Categories">{t("Categories")}</option>
                  </Form.Select>
                </Form.Group>
              </div>
              {currentType === "Factories" && (
                <div className="col-md-4">
                  <Form.Group controlId="formBasicSelect">
                    <Form.Label>{t("Catalog Category")}</Form.Label>
                    <Form.Select
                      value={currentCategory}
                      aria-label={currentCategory}
                      onChange={(e) => setCurrentCategory(e.target.value)}
                    >
                      <option value={null}>{t("All")}</option>
                      {categoriesList.length > 0 &&
                        categoriesList.map((category, j) => {
                          return (
                            <option value={category.category_id} key={j}>
                              {i18n.language === "ar"
                                ? category.ar_name.replace(/&amp;/g, "&")
                                : category.en_name.replace(/&amp;/g, "&")}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </Form.Group>
                </div>
              )}
              {currentType === "Factories" && (
                <div className="col-md-4">
                  <Form.Label>{t("search factory name")}</Form.Label>
                  <div className="d-flex justify-content-end">
                    <Form.Control
                      defaultValue={
                        searchKeyword === "empty" ? "" : searchKeyword
                      }
                      type="search"
                      placeholder={t("search")}
                      className="me-2 search-input"
                      aria-label="Search"
                      ref={searchRef}
                      onKeyPress={handleKeyPress}
                    />
                    <Button
                      className="btn-search"
                      variant="info"
                      onClick={() => search()}
                      type="button"
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </div>
                </div>
              )}
              <div className="col-md-1">
                <Form.Label>{t("copy filter")}</Form.Label>
                <Form.Group>
                  <Button
                    className="btn-share"
                    variant="success"
                    onClick={handleCopy}
                    type="button"
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </Button>
                  <ToastContainer />
                </Form.Group>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="row">
              {[...Array.from(Array(6).keys())].map((num, i) => (
                <div className="col-2" key={i}>
                  <div className="skeleton"></div>
                </div>
              ))}
            </div>
          ) : (
            <>
              {portraitBooks.length > 0 && (
                <div className="shelf-books">
                  <div className="catalgos">
                    <div className="overlay-bg"></div>
                    <div className="row g-0">
                      {portraitBooks.map((productCatalog, j) => {
                        return (
                          <div className="col-md-2 h-100" key={j}>
                            <Catalog
                              index={j}
                              book={openedBook}
                              source="allCatalog"
                              openBook={openBook}
                              closeBook={closeBook}
                              slug={productCatalog.slug}
                              catalogId={productCatalog.id}
                              productsCatalog={productCatalog}
                              coverData={productCatalog.coverData}
                              profileData={productCatalog.profileData}
                              orientation={productCatalog.orientation}
                              backCoverData={productCatalog.backCoverData}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
              {landscapeBooks.length > 0 && (
                <div className="shelf-books">
                  <div className="catalgos">
                    <div className="overlay-bg"></div>
                    <div className="row g-0">
                      {landscapeBooks.map((productCatalog, j) => {
                        return (
                          <div className="col-md-2 h-100" key={j}>
                            <Catalog
                              index={j}
                              book={openedBook}
                              source="allCatalog"
                              openBook={openBook}
                              closeBook={closeBook}
                              slug={productCatalog.slug}
                              catalogId={productCatalog.id}
                              productsCatalog={productCatalog}
                              coverData={productCatalog.coverData}
                              profileData={productCatalog.profileData}
                              orientation={productCatalog.orientation}
                              backCoverData={productCatalog.backCoverData}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          <Pagination totalPages={totalPages} setCurrentPage={setCurrentPage} currentPage={currentPage} />

          {/* <nav>
            <ul
              className="pagination pagination-sm mt-3 position-absolute start-0 end-0 justify-content-center"
              ref={scrollContainerRef}
            >
              {currentPage > 1 && (
                <>
                  <li onClick={handleFirstPage}>
                      <span className="page-link"> First</span>
                  </li>
                  <li onClick={handlePreviousPage}>
                    <span className="page-link"> Previous</span>
                  </li>
                </>
              )}

              {Array.from({
                length: Math.min(
                  visiblePages.end - visiblePages.start + 1,
                  totalPages
                ),
              }).map((_, idx) => {
                const page = visiblePages.start + idx;
                return (
                  <li
                  onClick={() => handlePageClick(page)}
           key={page}
           className={currentPage === page ? "active" : ""}
           aria-current="page"
                  >
                      <span className="page-link">{page}</span>
                  </li>
                );
              })}

              {currentPage < totalPages && (
                <>
                  <li onClick={handleNextPage}>
                      <span className="page-link">Next</span>
                  </li>
                  <li onClick={handleLastPage}>
                      <span className="page-link">Last</span>
                  </li>
                </>
              )}
            </ul>
          </nav> */}

          {/* <PaginationComponent
        totalItems={Math.max(totalPortraitPages, totalLandscapePages)}
        itemsPerPage={10}
        handlePageChange={handlePageChange}
      /> */}
          {/* <nav>
            <ul
              className="pagination pagination-sm mt-3 position-absolute start-0 end-0"
              ref={scrollContainerRef}
            >
              {renderPageNumbers()}
            </ul>
          </nav> */}
        </div>
      </div>
    </>
  );
};

export default AllCatalog;
