import React from "react";
import "./templates.css";
import { useTranslation } from "react-i18next";
const Template2 = ({ productsCatalog, mode }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="catelog-cover">
      <div
        className="catalog-cover-border"
        style={{ border: `1px solid ${productsCatalog?.mainColor}` }}
      ></div>
      <div
        className="copyright-text"
        style={{
          color: productsCatalog?.mainColor,
        }}
      >
        @2024 {t("copyright")}
      </div>
      <img
        src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://sinaha.ae/en/seller-profile/${
          productsCatalog?.screenname
        }&color=${productsCatalog?.mainColor.substring(1)}`}
        className="cover-qr-code"
        alt="QR Code"
        title="QR Code"
      />
      <div className={`${productsCatalog?.designTemplate} company-name`}>
        {productsCatalog?.categoryId ? (
          i18n.language === "ar" && productsCatalog?.arCategoryName ? (
            <h4
              dangerouslySetInnerHTML={{
                __html: productsCatalog?.arCategoryName,
              }}
            />
          ) : (
            <h4
              dangerouslySetInnerHTML={{
                __html: productsCatalog?.enCategoryName,
              }}
            />
          )
        ) : i18n.language === "ar" && productsCatalog?.arCustomerName ? (
          <h4
            dangerouslySetInnerHTML={{
              __html: productsCatalog?.arCustomerName,
            }}
          />
        ) : (
          <h4
            dangerouslySetInnerHTML={{
              __html: productsCatalog?.enCustomerName,
            }}
          />
        )}
      </div>
      {productsCatalog?.categoryId ? (
        <img
          src={"https://sinaha.ae/image/" + productsCatalog?.categoryIcon}
          className="company-logo"
          title="customerLogo"
          alt="customerLogo"
        />
      ) : (
        <img
          src={"https://sinaha.ae/image/" + productsCatalog?.customerLogo}
          className="company-logo"
          title="customerLogo"
          alt="customerLogo"
        />
      )}
      <div className="title">
        <h2>{t("products")}</h2>
        <h4>{t("smartCatalog")}</h4>
      </div>
      <div className="curved-corner-topright"> </div>
      <figure className="cover-background">
        <img
          className="cropped-image"
          src={productsCatalog?.coverImage}
          alt="Cover Image"
          title="Cover Image"
        />
      </figure>
      <div
        className="circle-cover"
        style={{
          background: `linear-gradient(to top, ${productsCatalog?.mainColor}, ${productsCatalog?.mainColor}cc)`,
          borderTop: `${mode === "smallView" ? "10px" : "30px"} solid ${
            productsCatalog?.mainColor
          }`,
          outline: `1px solid ${productsCatalog?.mainColor}`,
        }}
      ></div>
      <div
        className="circle-cover-2"
        style={{
          borderTop: `${mode === "smallView" ? "15px" : "50px"} solid  ${
            productsCatalog?.mainColor
          }91`,
        }}
      ></div>
      <div className="year">
        <p>2024</p>
      </div>
    </div>
  );
};

export default Template2;
