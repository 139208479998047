// LoadingOverlay.js
import React from 'react';

const LoadingOverlay = () => {
  return (
    <div style={overlayStyle}>
      <div style={spinnerStyle}>Exporting PDF...</div>
    </div>
  );
};

// Styles for overlay and spinner
const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 9999,
};

const spinnerStyle = {
  color: '#fff',
  fontSize: '1.5em',
};

export default LoadingOverlay;