import { useTranslation } from "react-i18next";
import React from "react";
function Page({ pageNumber, productsCatalog }) {
  const { t } = useTranslation();
  return (
    <div className={`page-style ${pageNumber % 2 === 0 ? "even" : "odd"}`}>
    </div>
  );
}

export default Page;
