import "./templates.css";
import styled from "styled-components";
import "react-resizable/css/styles.css";
import Draggable from "react-draggable";
import RotatableDiv from "../RotatableDiv";
import { useTranslation } from "react-i18next";
import { ResizableBox } from "react-resizable";
import { mobileFontSize } from "../../../config/_global";
import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faArrowsUpDownLeftRight,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { imageProxy } from "../../../api/catalogs";

const fontNames = [
  { "": "" },
  { Cairo: '"Cairo", sans-serif' },
  { Georgia: '"Georgia", serif' },
  { Papyrus: "Papyrus, fantasy" },
  { Tahoma: "Tahoma, Geneva, sans-serif" },
  { Arial: "Arial, Helvetica, sans-serif" },
  { Verdana: "Verdana, Geneva, sans-serif" },
  { Impact: "Impact, Charcoal, sans-serif" },
  { Helvetica: "Helvetica, Arial, sans-serif" },
  { Courier: '"Courier New", Courier, monospace' },
  { Lucida: '"Lucida Console", Monaco, monospace' },
  { Consolas: 'Consolas, "Courier New", monospace' },
  { "Brush Script MT": '"Brush Script MT", cursive' },
  { Trebuchet: '"Trebuchet MS", Helvetica, sans-serif' },
  { "Times New Roman": '"Times New Roman", Times, serif' },
  { "Comic Sans MS": '"Comic Sans MS", cursive, sans-serif' },
  { Palatino: '"Palatino Linotype", "Book Antiqua", Palatino, serif' },
];

const ResizableAndDraggableBox = ({
  x,
  y,
  id,
  box,
  index,
  width,
  onDrag,
  height,
  zIndex,
  content,
  onResize,
  currentBoxId,
  setShowTools,
  setCurrentBoxId,
  handleCoverData,
  setCurrentIndex,
  handleChangeShapesList,
}) => {
  const [axis, setAxis] = useState("both");
  // Handle drag event
  const handleDrag = (e) => {
    if (e.shiftKey) {
      const deltaX = Math.abs(e.movementX);
      const deltaY = Math.abs(e.movementY);
      if (deltaX > deltaY) {
        setAxis("x");
      } else {
        setAxis("y");
      }
    } else {
      setAxis("both");
    }
  };
  return (
    <Draggable
      axis={axis}
      bounds="parent"
      position={{ x, y }}
      onDrag={handleDrag}
      handle=".handle-button"
      onStop={(e, data) =>
        id.includes("cShape")
          ? handleChangeShapesList(
              index,
              "position",
              {
                x: data.x,
                y: data.y,
              },
              "cover"
            )
          : onDrag(id, data.x, data.y)
      }
    >
      <div style={{ position: "absolute", zIndex: zIndex || 0 }}>
        <div
          style={{
            opacity: box.opacity,
            transform: `rotate(${box.rotate}deg)`,
          }}
          onClick={() => {
            setShowTools(true);
            setCurrentBoxId(id);
            setCurrentIndex(index);
          }}
        >
          <div className="handle-button">
            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
          </div>
          <ResizableBox
            resizeHandles={["s", "w", "e", "n", "nw", "ne", "se", "sw"]}
            width={width}
            height={height}
            onResize={(e, { size }) =>
              id.includes("cShape")
                ? handleChangeShapesList(
                    index,
                    "size",
                    {
                      width: size?.width,
                      height: size?.height,
                    },
                    "cover"
                  )
                : onResize(id, size?.width, size?.height)
            }
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                border: currentBoxId === id ? "2px solid #f00" : "",
              }}
            >
              <RotatableDiv
                id={id}
                index={index}
                rotate={box.rotate}
                handleRotate={
                  id.includes("cShape")
                    ? handleChangeShapesList
                    : handleCoverData
                }
              />
              {content}
            </div>
          </ResizableBox>
        </div>
      </div>
    </Draggable>
  );
};

// const CanvasImage = ({ url }) => {
//   const canvasRef = useRef(null);

//   useEffect(() => {
//     const canvas = canvasRef.current;
//     const context = canvas.getContext('2d');
//     const img = new Image();
//     img.crossOrigin = 'anonymous';
//     img.src = url;

//     img.onload = () => {
//       context.drawImage(img, 0, 0);
//     };
//   }, [url]);

//   return <canvas ref={canvasRef} width="500" height="500" />;
// }

const Customize = ({
  mode,
  coverData,
  // bookCoverRef,
  setCoverData,
  productsCatalog,
  handleCoverData,
  deleteFromShapesList,
  handleChangeShapesList,
}) => {
  const { t, i18n } = useTranslation();
  const [boxes, setBoxes] = useState([]);
  const [histories, setHistories] = useState([]);
  const [showTools, setShowTools] = useState(false);
  const [currentBoxId, setCurrentBoxId] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(false);
  useEffect(() => {
    if (coverData) {
      const localIsMobile = window.innerWidth <= 768;
      const Box = styled.div`
        &::before,
        &::after {
          border: 1px solid
            ${coverData ? coverData?.coverYear.color : "#000000"} !important;
        }
      `;
      const initialCoverData = [
        {
          id: "coverLogo",
          size: coverData?.coverLogo?.size,
          color: coverData?.coverLogo?.color,
          rotate: coverData?.coverLogo?.rotate,
          opacity: coverData?.coverLogo?.opacity,
          position: coverData?.coverLogo?.position,
          content: (
            <div className="logo">
              {productsCatalog?.categoryId ? (
                <img
                  src={`https://sinaha.ae/image/${productsCatalog?.categoryIcon}`}
                  className="w-100"
                  title="customerLogo"
                  alt="customerLogo"
                />
              ) : (
                <img
                  src={`https://sinaha.ae/image/${productsCatalog?.customerLogo}`}
                  className="w-100"
                  alt="Customer Logo"
                  title="Customer Logo"
                />
              )}
            </div>
          ),
        },
        {
          id: "coverQrCode",
          size: coverData?.coverQrCode?.size,
          color: coverData?.coverQrCode?.color,
          rotate: coverData?.coverQrCode?.rotate,
          opacity: coverData?.coverQrCode?.opacity,
          position: coverData?.coverQrCode?.position,
          content: (
            <img
              src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://sinaha.ae/en/seller-profile/${
                productsCatalog?.screenname
              }&color=${
                coverData
                  ? coverData?.coverQrCode?.color.substring(1)
                  : "000000"
              }`}
              className="w-100"
              alt="QR Code"
              title="QR Code"
            />
          ),
        },
        {
          id: "coverName",
          bold: coverData?.coverName?.bold,
          size: coverData?.coverName?.size,
          color: coverData?.coverName?.color,
          rotate: coverData?.coverName?.rotate,
          italic: coverData?.coverName?.italic,
          opacity: coverData?.coverName?.opacity,
          fontSize: coverData?.coverName?.fontSize,
          position: coverData?.coverName?.position,
          textAlign: coverData?.coverName?.textAlign,
          underline: coverData?.coverName?.underline,
          fontName: '"Times New Roman", Times, serif',
          content: (
            <div
              className={"company-name"}
              style={{
                color: `${coverData ? coverData?.coverName?.color : "#000000"}`,
              }}
            >
              {productsCatalog?.categoryId ? (
                i18n.language === "ar" && productsCatalog?.arCategoryName ? (
                  <h4
                    style={{
                      fontFamily: `${
                        coverData
                          ? coverData?.coverName?.fontName
                          : '"Cairo", sans-serif'
                      }`,
                      fontSize: `${
                        localIsMobile
                          ? mobileFontSize(coverData?.coverName?.fontSize)
                          : mode === "smallView"
                          ? coverData
                            ? coverData?.coverName?.fontSize / 6 + "px"
                            : "3px"
                          : coverData
                          ? coverData?.coverName?.fontSize + "px"
                          : "14px"
                      }`,
                      fontWeight: coverData?.coverName?.bold && "bold",
                      fontStyle: coverData?.coverName?.italic && "italic",
                      textAlign: coverData?.coverName?.textAlign || "left",
                      textDecoration:
                        coverData?.coverName?.underline && "underline",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: productsCatalog?.arCategoryName,
                    }}
                  />
                ) : (
                  <h4
                    style={{
                      fontFamily: `${
                        coverData
                          ? coverData?.coverName?.fontName
                          : '"Cairo", sans-serif'
                      }`,
                      fontSize: `${
                        localIsMobile
                          ? mobileFontSize(coverData?.coverName?.fontSize)
                          : mode === "smallView"
                          ? coverData
                            ? coverData?.coverName?.fontSize / 6 + "px"
                            : "3px"
                          : coverData
                          ? coverData?.coverName?.fontSize + "px"
                          : "14px"
                      }`,
                      fontWeight: coverData?.coverName?.bold && "bold",
                      fontStyle: coverData?.coverName?.italic && "italic",
                      textAlign: coverData?.coverName?.textAlign || "left",
                      textDecoration:
                        coverData?.coverName?.underline && "underline",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: productsCatalog?.enCategoryName,
                    }}
                  />
                )
              ) : i18n.language === "ar" && productsCatalog?.arCustomerName ? (
                <h4
                  style={{
                    fontFamily: `${
                      coverData
                        ? coverData?.coverName?.fontName
                        : '"Cairo", sans-serif'
                    }`,
                    fontSize: `${
                      localIsMobile
                        ? mobileFontSize(coverData?.coverName?.fontSize)
                        : mode === "smallView"
                        ? coverData
                          ? coverData?.coverName?.fontSize / 6 + "px"
                          : "3px"
                        : coverData
                        ? coverData?.coverName?.fontSize + "px"
                        : "14px"
                    }`,
                    fontWeight: coverData?.coverName?.bold && "bold",
                    fontStyle: coverData?.coverName?.italic && "italic",
                    textAlign: coverData?.coverName?.textAlign || "left",
                    textDecoration:
                      coverData?.coverName?.underline && "underline",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: productsCatalog?.arCustomerName,
                  }}
                />
              ) : (
                <h4
                  style={{
                    fontFamily: `${
                      coverData
                        ? coverData?.coverName?.fontName
                        : '"Cairo", sans-serif'
                    }`,
                    fontSize: `${
                      localIsMobile
                        ? mobileFontSize(coverData?.coverName?.fontSize)
                        : mode === "smallView"
                        ? coverData
                          ? coverData?.coverName?.fontSize / 6 + "px"
                          : "3px"
                        : coverData
                        ? coverData?.coverName?.fontSize + "px"
                        : "14px"
                    }`,
                    fontWeight: coverData?.coverName?.bold && "bold",
                    fontStyle: coverData?.coverName?.italic && "italic",
                    textAlign: coverData?.coverName?.textAlign || "left",
                    textDecoration:
                      coverData?.coverName?.underline && "underline",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: productsCatalog?.enCustomerName,
                  }}
                />
              )}
            </div>
          ),
        },
        {
          id: "coverTitle",
          bold: coverData?.coverTitle?.bold,
          size: coverData?.coverTitle?.size,
          color: coverData?.coverTitle?.color,
          rotate: coverData?.coverTitle?.rotate,
          italic: coverData?.coverTitle?.italic,
          opacity: coverData?.coverTitle?.opacity,
          fontSize: coverData?.coverTitle?.fontSize,
          position: coverData?.coverTitle?.position,
          textAlign: coverData?.coverTitle?.textAlign,
          underline: coverData?.coverTitle?.underline,
          fontName: '"Times New Roman", Times, serif',
          content: (
            <div
              className="title"
              style={{
                color: `${
                  coverData ? coverData?.coverTitle?.color : "#000000"
                }`,
              }}
            >
              <h2
                style={{
                  fontFamily: `${
                    coverData
                      ? coverData?.coverTitle?.fontName
                      : '"Cairo", sans-serif'
                  }`,
                  marginBottom: "0px",
                  fontSize: `${
                    localIsMobile
                      ? mobileFontSize(coverData?.coverTitle?.fontSize)
                      : mode === "smallView"
                      ? coverData
                        ? coverData?.coverTitle?.fontSize / 6 + "px"
                        : "3px"
                      : coverData
                      ? coverData?.coverTitle?.fontSize + "px"
                      : "14px"
                  }`,
                  fontWeight: coverData?.coverTitle?.bold && "bold",
                  fontStyle: coverData?.coverTitle?.italic && "italic",
                  textAlign: coverData?.coverTitle?.textAlign || "left",
                  textDecoration:
                    coverData?.coverTitle?.underline && "underline",
                }}
              >
                {t("products")}
              </h2>
              <h4
                style={{
                  fontFamily: `${
                    coverData
                      ? coverData?.coverTitle?.fontName
                      : '"Cairo", sans-serif'
                  }`,
                  fontSize: `${
                    localIsMobile
                      ? mobileFontSize(coverData?.coverTitle?.fontSize)
                      : mode === "smallView"
                      ? coverData
                        ? coverData?.coverTitle?.fontSize / 8 + "px"
                        : "2px"
                      : coverData
                      ? coverData?.coverTitle?.fontSize / 2 + "px"
                      : "6px"
                  }`,
                  fontWeight: coverData?.coverTitle?.bold && "bold",
                  fontStyle: coverData?.coverTitle?.italic && "italic",
                  textAlign: coverData?.coverTitle?.textAlign || "left",
                  textDecoration:
                    coverData?.coverTitle?.underline && "underline",
                }}
              >
                {t("smartCatalog")}
              </h4>
            </div>
          ),
        },
        {
          id: "coverCopyRight",
          bold: coverData?.coverCopyRight.bold,
          size: coverData?.coverCopyRight.size,
          color: coverData?.coverCopyRight.color,
          rotate: coverData?.coverCopyRight.rotate,
          italic: coverData?.coverCopyRight.italic,
          opacity: coverData?.coverCopyRight.opacity,
          fontSize: coverData?.coverCopyRight.fontSize,
          position: coverData?.coverCopyRight.position,
          textAlign: coverData?.coverCopyRight.textAlign,
          underline: coverData?.coverCopyRight.underline,
          fontName: '"Times New Roman", Times, serif',
          content: (
            <div
              className="copyright-text"
              style={{
                color: `${
                  coverData ? coverData?.coverCopyRight.color : "#000000"
                }`,
                fontFamily: `${
                  coverData
                    ? coverData?.coverCopyRight.fontName
                    : '"Cairo", sans-serif'
                }`,
                fontSize: `${
                  localIsMobile
                    ? mobileFontSize(coverData?.coverCopyRight.fontSize)
                    : mode === "smallView"
                    ? coverData
                      ? coverData?.coverCopyRight.fontSize / 6 + "px"
                      : "3px"
                    : coverData
                    ? coverData?.coverCopyRight.fontSize + "px"
                    : "14px"
                }`,
                fontWeight: coverData?.coverCopyRight.bold && "bold",
                fontStyle: coverData?.coverCopyRight.italic && "italic",
                textAlign: coverData?.coverCopyRight.textAlign || "left",
                textDecoration:
                  coverData?.coverCopyRight.underline && "underline",
              }}
            >
              @2024 {t("copyright")}
            </div>
          ),
        },
        {
          id: "coverYear",
          bold: coverData?.coverYear.bold,
          size: coverData?.coverYear.size,
          color: coverData?.coverYear.color,
          rotate: coverData?.coverYear.rotate,
          italic: coverData?.coverYear.italic,
          opacity: coverData?.coverYear.opacity,
          fontSize: coverData?.coverYear.fontSize,
          position: coverData?.coverYear.position,
          textAlign: coverData?.coverYear.textAlign,
          underline: coverData?.coverYear.underline,
          fontName: '"Times New Roman", Times, serif',
          content: (
            <Box
              className="year"
              style={{
                color: `${coverData ? coverData?.coverYear.color : "#000000"}`,
              }}
            >
              <p
                style={{
                  fontFamily: `${
                    coverData
                      ? coverData?.coverYear.fontName
                      : '"Cairo", sans-serif'
                  }`,
                  fontSize: `${
                    localIsMobile
                      ? mobileFontSize(coverData?.coverYear.fontSize)
                      : mode === "smallView"
                      ? coverData
                        ? coverData?.coverYear.fontSize / 5 + "px"
                        : "3px"
                      : coverData
                      ? coverData?.coverYear.fontSize + "px"
                      : "14px"
                  }`,
                  fontWeight: coverData?.coverYear.bold && "bold",
                  fontStyle: coverData?.coverYear.italic && "italic",
                  textDecoration: coverData?.coverYear.underline && "underline",
                }}
              >
                2024
              </p>
            </Box>
          ),
        },
      ];
      setBoxes(initialCoverData);
    }
  }, [coverData]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === "z") {
        handleUndo();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [histories]);

  const handleUndo = () => {
    if (histories && histories.length > 0) {
      const lastHistory = histories[histories.length - 1];
      setCoverData(lastHistory);
      setHistories(histories.slice(0, -1));
    }
  };

  const saveToHistory = (newHistory) => {
    setHistories((prevHistories) => [...prevHistories, newHistory]);
  };

  const handleResize = (id, width, height) => {
    saveToHistory(coverData);
    setCoverData((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        size: { width: parseInt(width), height: parseInt(height) },
      },
    }));
  };

  const handleDrag = (id, x, y) => {
    saveToHistory(coverData);
    setCoverData((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        position: { x: parseInt(x), y: parseInt(y) },
      },
    }));
  };

  const localHandleCoverData = (id, e, key) => {
    saveToHistory(coverData);
    handleCoverData(id, e, key);
  };

  return (
    <>
      {showTools && (
        <div className="resizableAndDraggableBoxNavBar">
          {currentBoxId !== "coverLogo" && (
            <label className="tools-item">
              <span>Color</span>
              <input
                type="color"
                className="font-color-input"
                value={
                  currentBoxId.includes("cShape")
                    ? productsCatalog?.coverShapesList.find(
                        (shape, index) => index === currentIndex
                      )?.bgColor
                    : coverData[currentBoxId]?.bgColor
                }
                onChange={(e) =>
                  currentBoxId.includes("cShape")
                    ? handleChangeShapesList(
                        currentIndex,
                        "color",
                        e.target.value,
                        "cover"
                      )
                    : handleCoverData(currentBoxId, e, "color")
                }
              />
            </label>
          )}

          <label className="tools-item">
            <span>Opacity</span>
            <input
              max={1}
              min={0.1}
              step={0.1}
              type="number"
              title="Opacity"
              className="opacity-input"
              value={
                currentBoxId.includes("cShape")
                  ? productsCatalog?.coverShapesList.find(
                      (shape, index) => index === currentIndex
                    )?.opacity
                  : coverData[currentBoxId].opacity
              }
              onChange={(e) =>
                currentBoxId.includes("cShape")
                  ? handleChangeShapesList(
                      currentIndex,
                      "opacity",
                      e.target.value,
                      "cover"
                    )
                  : handleCoverData(currentBoxId, e, "opacity")
              }
            />
          </label>

          {currentBoxId !== "coverLogo" &&
            currentBoxId !== "coverQrCode" &&
            !currentBoxId.includes("cShape") && (
              <>
                <label className="tools-item">
                  <span>Font</span>
                  <select
                    value={coverData[currentBoxId].fontName}
                    className="font-name-select"
                    defaultValue={coverData[currentBoxId].fontName}
                    onChange={(e) =>
                      handleCoverData(currentBoxId, e, "fontName")
                    }
                  >
                    {fontNames &&
                      fontNames.map((item) =>
                        Object.entries(item).map(([key, value]) => (
                          <option
                            key={key}
                            value={value}
                            style={{ fontFamily: value }}
                          >
                            {key}
                          </option>
                        ))
                      )}
                  </select>
                </label>
                <label className="tools-item">
                  <span>Font Size</span>
                  <input
                    min={0}
                    type="number"
                    className="font-size-input"
                    value={coverData[currentBoxId].fontSize}
                    defaultValue={coverData[currentBoxId].fontSize}
                    onChange={(e) =>
                      handleCoverData(currentBoxId, e, "fontSize")
                    }
                  />
                </label>
                <label className="tools-item">
                  <span>Bold</span>
                  <button
                    onClick={(e) => handleCoverData(currentBoxId, e, "bold")}
                    className={`bold-button ${
                      coverData[currentBoxId].bold ? "active" : ""
                    }`}
                  >
                    B
                  </button>
                </label>
                <label className="tools-item">
                  <span>Italic</span>
                  <button
                    onClick={(e) => handleCoverData(currentBoxId, e, "italic")}
                    className={`italic-button ${
                      coverData[currentBoxId].italic ? "active" : ""
                    }`}
                  >
                    I
                  </button>
                </label>
                <label className="tools-item">
                  <span>Underline</span>
                  <button
                    onClick={(e) =>
                      handleCoverData(currentBoxId, e, "underline")
                    }
                    className={`underline-button ${
                      coverData[currentBoxId].underline ? "active" : ""
                    }`}
                  >
                    U
                  </button>
                </label>
                <label className="tools-item">
                  <span>Text Align</span>
                  <div className="d-flex justify-content-between">
                    <div
                      className={`text-align ${
                        coverData[currentBoxId].textAlign === "left"
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        handleCoverData(currentBoxId, "left", "textAlign")
                      }
                    >
                      <FontAwesomeIcon icon={faAlignLeft} />
                    </div>
                    <div
                      className={`text-align ${
                        coverData[currentBoxId].textAlign === "center"
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        handleCoverData(currentBoxId, "center", "textAlign")
                      }
                    >
                      <FontAwesomeIcon icon={faAlignCenter} />
                    </div>
                    <div
                      className={`text-align ${
                        coverData[currentBoxId].textAlign === "right"
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        handleCoverData(currentBoxId, "right", "textAlign")
                      }
                    >
                      <FontAwesomeIcon icon={faAlignRight} />
                    </div>
                  </div>
                </label>
              </>
            )}

          {currentBoxId.includes("cShape") && (
            <>
              <label className="tools-item">
                <span>Border Width</span>
                <input
                  type="number"
                  className="border-width-input"
                  value={
                    productsCatalog?.coverShapesList.find(
                      (shape, index) => index === currentIndex
                    )?.borderWidth
                  }
                  onChange={(e) =>
                    handleChangeShapesList(
                      currentIndex,
                      "borderWidth",
                      e.target.value,
                      "cover"
                    )
                  }
                  title="Border Width"
                />
              </label>
              <label className="tools-item">
                <span>Border Color</span>
                <input
                  type="color"
                  className="border-color-input"
                  value={
                    productsCatalog?.coverShapesList.find(
                      (shape, index) => index === currentIndex
                    )?.borderColor
                  }
                  onChange={(e) =>
                    handleChangeShapesList(
                      currentIndex,
                      "borderColor",
                      e.target.value,
                      "cover"
                    )
                  }
                  title="Border Color"
                />
              </label>
              {productsCatalog?.coverShapesList.find(
                (shape, index) => index === currentIndex
              )?.type === "square" && (
                <label className="tools-item">
                  <span>Border Radius</span>
                  <input
                    type="number"
                    title="Border Radius"
                    value={
                      productsCatalog?.coverShapesList.find(
                        (shape, index) => index === currentIndex
                      )?.borderRadius
                    }
                    className="border-radius-input"
                    onChange={(e) =>
                      handleChangeShapesList(
                        currentIndex,
                        "borderRadius",
                        e.target.value,
                        "cover"
                      )
                    }
                  />
                </label>
              )}
              <label className="tools-item">
                <span>Border Style</span>
                <select
                  className="border-style-select"
                  value={
                    productsCatalog?.coverShapesList.find(
                      (shape, index) => index === currentIndex
                    )?.borderStyle
                  }
                  onChange={(e) =>
                    handleChangeShapesList(
                      currentIndex,
                      "borderStyle",
                      e.target.value,
                      "cover"
                    )
                  }
                  title="Border Style"
                >
                  <option></option>
                  <option value="solid">Solid</option>
                  <option value="dashed">Dashed</option>
                  <option value="dotted">Dotted</option>
                </select>
              </label>
              <label className="tools-item">
                <span>Front Index</span>
                <input
                  min={0}
                  type="number"
                  className="zIndex-input"
                  value={
                    productsCatalog?.coverShapesList.find(
                      (shape, index) => index === currentIndex
                    )?.zIndex
                  }
                  onChange={(e) =>
                    handleChangeShapesList(
                      currentIndex,
                      "zIndex",
                      e.target.value,
                      "cover"
                    )
                  }
                  title="Index"
                />
              </label>
              <label className="tools-item">
                <span>Delete</span>
                <button
                  className="delete-button"
                  onClick={() => deleteFromShapesList(currentIndex, "cover")}
                  title="Delete"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </label>
            </>
          )}
        </div>
      )}
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        {mode === "editable" && (
          <>
            <div className="editing-line-horizontal"></div>
            <div className="editing-line-vertical"></div>
          </>
        )}
        <div
          // ref={bookCoverRef}
          // id={`catalog-cover-${productsCatalog?.id}`}
          className="catelog-cover customized-catalog"
          style={{
            background: `linear-gradient(rgb(255 255 255 / ${productsCatalog?.coverImageOpacity}%), rgb(255 255 255 / ${productsCatalog?.coverImageOpacity}%)), url('${productsCatalog?.coverImage}') no-repeat ${productsCatalog?.coverImagePosition} / ${productsCatalog?.coverImageSize}`,
            border: `1px solid ${productsCatalog?.mainColor}`,
            backgroundColor: productsCatalog?.mainColor,
          }}
        >
          {boxes &&
            boxes.map((box) =>
              mode === "editable" ? (
                <ResizableAndDraggableBox
                  box={box}
                  id={box.id}
                  key={box.id}
                  onDrag={handleDrag}
                  content={box.content}
                  onResize={handleResize}
                  currentBoxId={currentBoxId}
                  setShowTools={setShowTools}
                  setCurrentBoxId={setCurrentBoxId}
                  setCurrentIndex={setCurrentIndex}
                  x={coverData[box.id]?.position?.x}
                  y={coverData[box.id]?.position?.y}
                  opacity={coverData[box.id]?.opacity}
                  width={coverData[box.id]?.size?.width}
                  handleCoverData={localHandleCoverData}
                  height={coverData[box.id]?.size?.height}
                />
              ) : (
                <div
                  key={box.id}
                  style={{
                    position: "absolute",
                    opacity: coverData[box.id]?.opacity,
                    transform: `rotate(${coverData[box.id]?.rotate}deg)`,
                    height: `${
                      (coverData[box.id]?.size?.height /
                        productsCatalog?.catalogSize?.height) *
                      100
                    }%`,
                    width: `${
                      (coverData[box.id]?.size?.width /
                        productsCatalog?.catalogSize?.width) *
                      100
                    }%`,
                    top: `${
                      (coverData[box.id]?.position?.y /
                        productsCatalog?.catalogSize?.height) *
                      100
                    }%`,
                    left: `${
                      (coverData[box.id]?.position?.x /
                        productsCatalog?.catalogSize?.width) *
                      100
                    }%`,
                  }}
                >
                  {box.content}
                </div>
              )
            )}

          {productsCatalog?.coverShapesList?.length > 0 &&
            (mode === "editable"
              ? productsCatalog?.coverShapesList?.map((shape, index) => (
                  <ResizableAndDraggableBox
                    box={shape}
                    index={index}
                    show={shape?.show}
                    pageKey="shapesList"
                    rotate={shape?.rotate}
                    x={shape?.position?.x}
                    y={shape?.position?.y}
                    zIndex={shape?.zIndex}
                    shapeType={shape?.type}
                    opacity={shape?.opacity}
                    id={`cShape${index}`}
                    key={`cShape${index}`}
                    currentBoxId={currentBoxId}
                    setShowTools={setShowTools}
                    setCurrentBoxId={setCurrentBoxId}
                    setCurrentIndex={setCurrentIndex}
                    handleChangeShapesList={handleChangeShapesList}
                    content={
                      <div
                        className={`${shape?.type} ${
                          shape?.type !== "line" && "w-100"
                        }  h-100 m-0`}
                        style={
                          shape?.type === "line"
                            ? {
                                position: "absolute",
                                top: shape?.y,
                                left: shape?.x,
                                borderLeftColor: shape?.borderColor,
                                borderLeftStyle: shape?.borderStyle,
                                transform: `rotate(${shape?.rotate}deg)`,
                                borderLeftWidth: `${shape?.borderWidth}px`,
                              }
                            : {
                                position: "absolute",
                                top: shape?.y,
                                left: shape?.x,
                                backgroundColor: shape?.bgColor,
                                borderStyle: shape?.borderStyle,
                                borderColor: shape?.borderColor,
                                borderWidth: `${shape?.borderWidth}px`,
                                transform: `rotate(${shape?.rotate}deg)`,
                                borderRadius:
                                  shape?.type === "square" &&
                                  `${shape?.borderRadius}px`,
                              }
                        }
                      ></div>
                    }
                    width={shape?.size ? shape?.size?.width : 100}
                    height={shape?.size ? shape?.size?.height : 100}
                  />
                ))
              : productsCatalog?.coverShapesList?.map((shape, index) => (
                  <div
                    key={index}
                    className={`${shape?.type} m-0`}
                    style={
                      shape?.type === "line"
                        ? {
                            position: "absolute",
                            opacity: shape?.opacity,
                            zIndex: `${shape?.zIndex}`,
                            display: !shape?.show && "none",
                            borderLeftColor: shape?.borderColor,
                            borderLeftStyle: shape?.borderStyle,
                            transform: `rotate(${shape?.rotate}deg)`,
                            left: `${
                              shape
                                ? (shape?.position?.x /
                                    productsCatalog?.catalogSize?.width) *
                                  100
                                : 20
                            }%`,
                            top: `${
                              shape
                                ? (shape?.position?.y /
                                    productsCatalog?.catalogSize?.height) *
                                  100
                                : 20
                            }%`,
                            height: `${
                              shape
                                ? (shape?.size?.height /
                                    productsCatalog?.catalogSize?.height) *
                                  100
                                : 100
                            }%`,
                            borderLeftWidth: `${
                              mode === "smallView"
                                ? shape?.borderWidth / 4
                                : shape?.borderWidth
                            }px`,
                          }
                        : {
                            position: "absolute",
                            zIndex: shape?.zIndex,
                            opacity: shape?.opacity,
                            backgroundColor: shape?.bgColor,
                            display: !shape?.show && "none",
                            borderStyle: shape?.borderStyle,
                            borderColor: shape?.borderColor,
                            transform: `rotate(${shape?.rotate}deg)`,
                            left: `${
                              shape
                                ? (shape?.position?.x /
                                    productsCatalog?.catalogSize?.width) *
                                  100
                                : 20
                            }%`,
                            width: `${
                              shape
                                ? (shape?.size?.width /
                                    productsCatalog?.catalogSize?.width) *
                                  100
                                : 100
                            }%`,
                            top: `${
                              shape
                                ? (shape?.position?.y /
                                    productsCatalog?.catalogSize?.height) *
                                  100
                                : 20
                            }%`,
                            height: `${
                              shape
                                ? (shape?.size?.height /
                                    productsCatalog?.catalogSize?.height) *
                                  100
                                : 100
                            }%`,
                            borderWidth: `${
                              mode === "smallView"
                                ? shape?.borderWidth / 4
                                : shape?.borderWidth
                            }px`,
                            borderRadius:
                              shape?.type === "square" &&
                              `${
                                mode === "smallView"
                                  ? shape?.borderRadius / 4
                                  : shape?.borderRadius
                              }px`,
                          }
                    }
                  ></div>
                )))}
        </div>
      </div>
    </>
  );
};

export default Customize;
