import React from "react";
import {
  capitalizeWordsNoSpaces,
  capitalizeWordsNoSpacesLowerFirst,
  convertToKebabCase,
} from "../../../config/_global";
import { API_BASE_URL_WITH_PORT } from "../../../config/url";

function Input(props) {
  const newTitle =
    props?.type === "color" &&
    (props?.currentBoxId.includes("productTitle") ||
      props?.currentBoxId.includes("productDescription") ||
      props?.currentBoxId.includes("text-"))
      ? "Font Color"
      : props?.title;

  return (
    <label className="tools-item">
      <span>{newTitle}</span>
      <input
        min={props?.min}
        max={props?.max}
        type={props?.type}
        step={props?.step}
        title={capitalizeWordsNoSpacesLowerFirst(newTitle)}
        className={`${convertToKebabCase(newTitle)}-input`}
        value={
          props?.currentBoxId.includes("text-")
            ? null
            : props?.currentBoxId.includes("image-") ||
              props?.currentBoxId.includes("shape-")
            ? props?.page[props?.currentPageKey]?.find(
                (item, index) => index === props?.currentIndex
              )?.[capitalizeWordsNoSpacesLowerFirst(newTitle)]
            : props?.page?.productsList?.find(
                (product) => product.product_id === props?.currentProductId
              )?.additionData[
                `${props?.currentBoxId.split("-")[0]}${capitalizeWordsNoSpaces(
                  newTitle
                )}`
              ]
        }
        onChange={
          props?.currentBoxId.includes("image-") ||
          props?.currentBoxId.includes("shape-") ||
          props?.currentBoxId.includes("text-")
            ? (e) => {
                if (
                  props?.currentBoxId.includes("image-") &&
                  props?.title === "Browse"
                ) {
                  props?.handlePageImagesChange(
                    props?.page?.id,
                    e,
                    props?.currentIndex
                  );
                } else {
                  props?.handleChangePageContent(
                    props?.currentPageId,
                    props?.currentPageKey,
                    null,
                    props?.currentIndex,
                    capitalizeWordsNoSpacesLowerFirst(newTitle),
                    e.target.value
                  );
                  if (props?.currentBoxId.includes("text-")) {
                    props?.applyStyle(props?.command, e.target.value);
                  }
                }
              }
            : (e) => {
                props?.handleChangePageContent(
                  props?.currentPageId,
                  props?.currentPageKey,
                  null,
                  props?.currentProductId,
                  "additionData",
                  null,
                  null,
                  `${
                    props?.currentBoxId.split("-")[0]
                  }${capitalizeWordsNoSpaces(newTitle)}`,
                  e.target.value
                );
              }
        }
      />
      {props?.currentBoxId.includes("image-") &&
        props?.title === "Browse" &&
        props?.page?.imagesList?.find(
          (image, index) => index === props?.currentIndex
        )?.imagePreviewUrl && (
          <img
            alt=""
            src={
              props?.page?.imagesList
                ?.find((image, index) => index === props?.currentIndex)
                ?.imagePreviewUrl?.includes("data:image")
                ? props?.page?.imagesList?.find(
                    (image, index) => index === props?.currentIndex
                  )?.imagePreviewUrl
                : `${API_BASE_URL_WITH_PORT}/${
                    props?.page?.imagesList?.find(
                      (image, index) => index === props?.currentIndex
                    )?.imagePreviewUrl
                  }`
            }
            style={{
              display: "block",
              margin: "0.5rem 0",
            }}
            className="w-100"
          />
        )}
    </label>
  );
}

export default Input;
