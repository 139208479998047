import api from "../config/api";

// Function to fetch all products
export const fetchProducts = async (id, type) => {
  try {
    const response = await api.get(`/products/withType/${type}/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};
