import React from "react";
import {
  capitalizeWordsNoSpaces,
  capitalizeWordsNoSpacesLowerFirst,
  convertToKebabCase,
} from "../../../config/_global";

function Select(props) {
  return (
    <label className="tools-item">
      <span>{props?.title}</span>
      <select
        className={`${convertToKebabCase(props?.title)}-select`}
        value={
          props?.currentBoxId.includes("image-") ||
          props?.currentBoxId.includes("shape-") ||
          props?.currentBoxId.includes("text-")
            ? props?.page[props?.currentPageKey]?.find(
                (item, index) => index === props?.currentIndex
              )?.[capitalizeWordsNoSpacesLowerFirst(props?.title)]
            : props?.page?.productsList?.find(
                (product) => product.product_id === props?.currentProductId
              )?.additionData[
                `${props?.currentBoxId.split("-")[0]}${capitalizeWordsNoSpaces(
                  props?.title
                )}`
              ]
        }
        onChange={
          props?.currentBoxId.includes("image-") ||
          props?.currentBoxId.includes("shape-") ||
          props?.currentBoxId.includes("text-")
            ? (e) => {
                // if (props?.currentBoxId.includes("image-")) {
                //   props?.handlePageImagesChange(
                //     props?.page?.id,
                //     e,
                //     props?.currentIndex
                //   );
                // } else {
                  props?.handleChangePageContent(
                    props?.currentPageId,
                    props?.currentPageKey,
                    null,
                    props?.currentIndex,
                    capitalizeWordsNoSpacesLowerFirst(props?.title),
                    e.target.value
                  );

                  if (props?.currentBoxId.includes("text-")) {
                    props?.applyStyle(props?.command, e.target.value);
                  }
                // }
              }
            : (e) => {
                props?.handleChangePageContent(
                  props?.currentPageId,
                  props?.currentPageKey,
                  null,
                  props?.currentProductId,
                  "additionData",
                  null,
                  null,
                  `${props?.currentBoxId.split("-")[0]}${capitalizeWordsNoSpaces(
                    props?.title
                  )}`,
                  e.target.value
                );
              }
        }
        title={capitalizeWordsNoSpacesLowerFirst(props?.title)}
      >
        {props?.items &&
          props?.items.map((item) =>
            Object.entries(item).map(([key, value]) => (
              <option
                key={key}
                value={value}
                style={{ fontFamily: props?.title === "Font Name" && value }}
              >
                {props?.title === "Font Name" ? key : value}
              </option>
            ))
          )}
      </select>
    </label>
  );
}

export default Select;
